/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';
import { useEffect, useRef, FunctionComponent } from 'react';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Modal } from '@abyss/web/ui/Modal';
import { getConfig } from 'scripts/util/constants/config';

// eslint-disable-next-line max-len
import { OptumCCGWidgetInitializer as UntypedOptumCCGWidgetInitializer } from '@optum-ccg/convenient-checkout-ui/dist/widget/ccg-widget.min';

import type cjsWidget from '@optum-ccg/convenient-checkout-ui/dist/cjs/widget-wrapper/OptumCCGWidgetInitializer';
import {
  ErrorCallbackArgs,
  MessageCallbackArgs,
  SuccessCallbackArgs,
} from '@optum-ccg/convenient-checkout-ui/dist/cjs/capabilities/checkout-v2/types';
import { resetCheckoutSession } from 'scripts/state/checkoutSession';
import { useDispatch } from 'react-redux';
import { PayAnywayDispatch } from 'scripts/state';
import { useMuultipayCheckoutSession } from './add-card-multipay.hooks';
import { IMultipayDefaultProps } from 'scripts/interface/multipay.interfaces';
import { useTranslation } from 'react-i18next';
import { styled } from '@abyss/web/tools/styled';

const OptumCCGWidgetInitializer = UntypedOptumCCGWidgetInitializer as typeof cjsWidget;

const AddCardStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ccg-container {
    padding-bottom: 20px;
  }

  #nameOnCard,
  #nickname,
  #nameOnAccount,
  #routingNumber,
  #accountNumber {
    outline: 1px solid #929496 !important;
  }
`;

interface IMultipayForm {
  data: IMultipayDefaultProps;
  isAddCardWidgetMultipay: boolean;
  setIsAddCardWidgetMultipay(isAddCardWidgetMultipay: boolean): void;
  setPaymentMethodAdditionError(paymentMethodAdditionError: ErrorCallbackArgs): void;
}

const AddCardWidgetMultipay: FunctionComponent<IMultipayForm> = props => {
  let ccgWidget: ReturnType<typeof cjsWidget>;
  const dispatch = useDispatch<PayAnywayDispatch>();
  const widgetContainer = useRef(null);
  const { data, setIsAddCardWidgetMultipay, isAddCardWidgetMultipay, setPaymentMethodAdditionError } = props;
  const { t } = useTranslation();
  const { setIsSessionCheckoutError, setResponseMessage, setIsPaymentMethodAdded } = data.initialData;
  const session = useMuultipayCheckoutSession(data);
  const env = getConfig().RALLYPAY_WEB_ENVIRONMENT_NAME.toLowerCase();
  const ccgEnv = env === 'production' ? 'prod' : 'stage';

  const resetWidget = (): void => {
    setIsAddCardWidgetMultipay(false);
    dispatch(resetCheckoutSession());
  };

  const handleSuccess = (event: SuccessCallbackArgs): void => {
    if (event.data.paymentMethod?.warning?.code === 'DUPLICATE_ENTRY') {
      setIsPaymentMethodAdded(true);
      setResponseMessage({
        type: 'info',
        code: t('DUPLICATE_ENTRY'),
        title: t('DUPLICATE_ENTRY_TITLE'),
        message: t('DUPLICATE_ENTRY_MESSAGE') || '',
      });
      resetWidget();
    } else {
      if (event.code === 'PAYMENT_METHOD_CREATED') {
        const { cardBrand, last4 } = event.data.paymentMethod?.card || {};
        const methodDisplayName = cardBrand
          ? cardBrand?.charAt(0) + cardBrand?.slice(1).toLocaleLowerCase() + ' *' + last4
          : t('PAYMENT_METHOD_ADDED_BANK');
        setIsPaymentMethodAdded(true);
        setResponseMessage({
          type: 'success',
          code: 'PAYMENT_METHOD_ADDED',
          title: t('PAYMENT_METHOD_ADDED', { paymentMethod: methodDisplayName }),
        });
        resetWidget();
      }
    }
  };

  const handleEvent = (result: MessageCallbackArgs): void => {
    if (result.code === 'WIDGET_CLOSED') {
      resetWidget();
    }
  };

  const handleError = (event: ErrorCallbackArgs): void => {
    // SESSION_CANCELED is returned when the user closes the CCG widget without entering a new
    // payment method, or if the CCG widget is opened using the sessionId of a cancelled session
    resetWidget();
    if (event.code !== 'SESSION_CANCELED') {
      setIsSessionCheckoutError(true);
      setPaymentMethodAdditionError(event);
    }
  };

  useEffect(() => {
    if (!session.sessionId || !widgetContainer.current || session.isLoading) {
      if (session.error) {
        resetWidget();
        setIsSessionCheckoutError(true);
      }
      return;
    }

    ccgWidget = OptumCCGWidgetInitializer({
      rootElement: widgetContainer.current,
      checkoutSessionId: session.sessionId,
      appEnv: ccgEnv,
      containerConfig: {
        type: 'inline',
      },
      onSuccess: handleSuccess,
      onEvent: handleEvent,
      onError: handleError,
    });
    ccgWidget.render();
  }, [session]);

  return (
    isAddCardWidgetMultipay && (
      <React.Fragment>
        <StyledModal isOpen={isAddCardWidgetMultipay} onClose={() => resetWidget()}>
          <Modal.Section>
            <div css={AddCardStyles}>
              <LoadingSpinner ariaLoadingLabel="Downloading" isLoading={session.isLoading} />
              <div className="ccg-container" ref={widgetContainer} />
            </div>
          </Modal.Section>
        </StyledModal>
      </React.Fragment>
    )
  );
};

const StyledModal = styled(Modal, {
  '&.abyss-modal-content-container': {
    height: '110%',
    width: '100%',
  },

  '&.abyss-modal-body': {
    maxHeight: 'calc(-50px + 100vh)',
  },

  '&.abyss-modal-close-button': {
    zIndex: '1',
  },
});

export default AddCardWidgetMultipay;
