import React, { FunctionComponent, useContext } from 'react';
import { Card } from '@abyss/web/ui/Card';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Box } from '@abyss/web/ui/Box';
import { Text } from '@abyss/web/ui/Text';
import { Label } from '@abyss/web/ui/Label';
import { styled } from '@abyss/web/tools/styled';
import { CcgLogo } from '../ccg-logo/ccg-logo';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { usePaymentMethods } from 'scripts/hooks/use-payment-methods/use-payment-methods';
import { AppContext } from 'scripts/contexts/app-context/app-context';
import { CardBrand, PaymentCardStatus } from '@rally/rallypay-commons';
import { useTranslation } from 'react-i18next';
import { handleClickTracking } from 'scripts/util/analytics/handleClickTracking';

export interface IPaymentMethodCardProps {
  cardBrand: CardBrand;
  lastFourCard: string;
  expiryYear: number;
  paymentMethodId: string;
  expiryMonth: number;
  cardStatus: PaymentCardStatus;
  bankName?: string;
  lastFourBank?: string;
  nickname: string;
  clearSelectedPaymentMethod: (id: string) => void;
}

export const PaymentMethodCard: FunctionComponent<IPaymentMethodCardProps> = props => {
  const {
    cardBrand,
    lastFourCard,
    expiryYear,
    expiryMonth,
    paymentMethodId,
    cardStatus,
    bankName,
    lastFourBank,
    nickname,
    clearSelectedPaymentMethod,
  } = props;
  const { t } = useTranslation();
  const { initialData } = useContext(AppContext);

  const { deletePaymentMethods } = usePaymentMethods();

  const cardExpired = cardStatus === PaymentCardStatus.EXPIRED;
  const bottomLabel = `Exp: ${(expiryMonth < 10 ? '0' + expiryMonth : expiryMonth) + '/' + (expiryYear % 100)}`;

  const menuItems = [
    {
      title: t('REMOVE'),
      onClick: () => {
        handleClickTracking(t('REMOVE'), t('SUBMIT_PAYMENT'));
        clearSelectedPaymentMethod(paymentMethodId);
        deletePaymentMethods({
          rallyId: initialData?.rallyId,
          paymentMethodId: paymentMethodId,
        });
      },
    },
  ];

  const paymentMethodName = (): string => {
    if (cardBrand) {
      return `${cardBrand?.toLocaleLowerCase()} *${lastFourCard}`;
    }
    if (bankName) {
      return `${bankName?.toLocaleLowerCase()} *${lastFourBank}`;
    }
    if (nickname) {
      return `${nickname?.toLocaleLowerCase()} *${lastFourBank || lastFourCard}`;
    }
    return '';
  };

  const handleMenuOpen = (): void => {
    handleClickTracking(t('MANAGE'), t('SUBMIT_PAYMENT'));
  };

  return (
    <StyledCard>
      <StyledRadio value={paymentMethodId} />
      {lastFourBank ? (
        <StyledIconBox>
          <IconMaterial icon="account_balance" size="$md" color="$black" />
        </StyledIconBox>
      ) : (
        <CcgLogo data-testid="card-logo" cardLogo={cardBrand} />
      )}
      <Box padding={'$xs'} color="$white">
        {cardBrand && (
          <>
            <StyledLabel data-testid="card-brand">{paymentMethodName()}</StyledLabel>
            {cardExpired ? (
              <>
                <StyledIcon icon="error_outline" size="$sm" />
                <RedStyledText size="sm">{bottomLabel}</RedStyledText>
              </>
            ) : (
              <StyledExpirationText size="sm">{bottomLabel}</StyledExpirationText>
            )}
          </>
        )}
        {lastFourBank && <StyledBankLabel data-testid="bank-label">{paymentMethodName()}</StyledBankLabel>}
      </Box>
      <div onClick={handleMenuOpen}>
        <StyledDropdownMenu
          label="Table Actions"
          hideLabel
          after={<IconMaterial icon="more_vert" size="$sm" />}
          menuItems={menuItems}
        />
      </div>
    </StyledCard>
  );
};

export const StyledCard = styled(Card, {
  display: 'flex',
  padding: '8px 0 0 8px',
  width: '320px',
  '&.abyss-card-root': {
    marginTop: '12px',
  },
});

export const StyledRadio = styled(RadioGroup.Radio, {
  '&.abyss-radio-root': {
    width: '28px',
  },
});

export const StyledLabel = styled(Label, {
  paddingBottom: '4px',
  textTransform: 'capitalize',
});

export const StyledExpirationText = styled(Text, {
  float: 'left',
  display: 'inline-block',
  paddingTop: '1px',
  height: '10px',
  marginBottom: '10px',
});

export const RedStyledText = styled(StyledExpirationText, {
  '&.abyss-text-root': {
    color: 'red',
  },
});

export const StyledIconBox = styled(Box, {
  '&.abyss-box-root': {
    height: '20px',
    width: '60px',
    backgroundColor: 'white',
    padding: '0px 10px',
  },
});

export const StyledBankLabel = styled(Label, {
  paddingTop: '0px',
  textTransform: 'capitalize',
});

const StyledDropdownMenu = styled(DropdownMenu, {
  paddingTop: 0,
});

export const StyledIcon = styled(IconMaterial, {
  float: 'left',
  display: 'inline-block',
  marginRight: '5px',
  '&.abyss-icon': {
    fill: 'red',
  },
});
