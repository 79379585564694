import { useReducer } from 'react';
import {
  submitCardPayment,
  submitCardPaymentFailure,
  submitCardPaymentSuccess,
} from 'scripts/actions/card-payment-action';
import cardPaymentReducer, { initialState, ICardPaymentFormState } from 'scripts/reducers/card-payment-reducer';
import { ICreatePaymentData } from 'scripts/api/convenient-checkout/convenient-checkout.interfaces';
import { ConvenientCheckoutService } from 'scripts/api/convenient-checkout/convenient-checkout.service';
import { CreatePaymentStatus, PaymentFormErrorType } from '@rally/rallypay-commons';
import { AxiosError } from 'axios';
import { getPaymentFormErrorCode } from 'scripts/util/payment-form/payment-form';

type onSubmitType = (cardPaymentData: ICreatePaymentData) => Promise<void>;
type axiosErrorResponseData = { status: number | PaymentFormErrorType };

export default function useCardPaymentSubmitter(): [ICardPaymentFormState, onSubmitType] {
  const [{ data, attempt, loading, error, errorResponse }, dispatch] = useReducer(cardPaymentReducer, initialState);
  const onSubmitCardPayment: onSubmitType = async (paymentData: ICreatePaymentData) => {
    dispatch(submitCardPayment());
    try {
      const response = await ConvenientCheckoutService.submitCardPayment(paymentData);
      const { status } = response?.data || '';
      if (status === CreatePaymentStatus.COMPLETED || status === CreatePaymentStatus.PROCESSING) {
        dispatch(submitCardPaymentSuccess(response.data));
      }
    } catch (err) {
      const axError = err as AxiosError;
      const axErrorResponse = (axError?.response?.data as axiosErrorResponseData)?.status;
      dispatch(submitCardPaymentFailure(getPaymentFormErrorCode(axErrorResponse)));
    }
  };

  return [{ attempt, data, loading, error, errorResponse }, onSubmitCardPayment];
}
