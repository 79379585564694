import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@abyss/web/tools/styled';
import { Checkbox } from '@abyss/web/ui/Checkbox';

export interface IACHAgreement {
  isACHAgreementAccepted: boolean;
  showACHAgreementError: boolean;
  onToggleCheckbox: () => void;
}

export const ACHAgreement: FunctionComponent<IACHAgreement> = ({
  isACHAgreementAccepted,
  showACHAgreementError,
  onToggleCheckbox,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <AgreementLabel>{t('ACH-AGREEMENT-LABEL')}</AgreementLabel>
      <AgreementContainer>
        <AgreementContent>{t('ACH-AGREEMENT')}</AgreementContent>
        <AgreementContent>{t('ACH-AGREEMENT1')}</AgreementContent>
      </AgreementContainer>
      <CheckboxContainer isErrorExist={showACHAgreementError}>
        <StyledCheckbox
          data-testid={'ach-agreement-checkbox'}
          label={t('ACH-CHECKBOX-LABEL') as string | undefined}
          isChecked={isACHAgreementAccepted}
          size={'sm'}
          onChange={onToggleCheckbox}
          errorMessage={showACHAgreementError && t('SUBMIT-WITHOUT-AGREEMENT-ERROR-MESSAGE')}
        />
      </CheckboxContainer>
    </Container>
  );
};

const Container = styled('div', {
  margin: '$lg 0 0',
});

const AgreementLabel = styled('div', {
  marginTop: '$md',
  fonzSize: '$md',
  fontWeight: '$bold',
});

const AgreementContainer = styled('div', {
  borderRadius: '4px',
  border: '1px solid $gray7',
  marginTop: '$xs',
  marginBottom: '$xs',
  padding: '$sm $md',
  width: 'auto',
  height: 'auto',
  maxHeight: '144px',
  overflow: 'auto',
});

const AgreementContent = styled('div', {
  color: '$gray8',
  fontSize: '$xs',
  lineHeight: '16px',
  padding: '$sm 0',
  fontWeight: '$medium',
});

const CheckboxContainer = styled('div', {
  variants: {
    isErrorExist: {
      true: {
        padding: '$md 0 $sm 0',
      },

      false: {
        padding: '$md 0 $xs 0',
      },
    },
  },
});

const StyledCheckbox = styled(Checkbox, {
  '&.abyss-checkbox-label': {
    paddingTop: '$xs',
    fontSize: '$md',
    fontWeight: '$medium',
    color: '$gray7',
  },

  '.abyss-checkbox-flex-container': {
    marginBottom: '$md',
  },
});
