import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import URLSearchParams from 'url-search-params';

export const getLocale = (): string => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('locale') ? (urlParams.get('locale') as string) : '';
};

const options = {
  addPath: './i18n/{{lng}}-US/{{ns}}',
  loadPath: `${process.env.WIDGET_PUBLIC_PATH}i18n/{{lng}}-US/{{ns}}.json`,
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    backend: options,
    fallbackLng: 'en',
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    lng: getLocale().slice(0, 2),
    returnNull: false,
  });

export default i18n;
