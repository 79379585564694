import { css } from '@emotion/react';
import { Style } from 'scripts/styles/themes/themes.interfaces';

const defaultErrorScreenStyles: Style = () => css`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 32px;
`;

export default defaultErrorScreenStyles;
