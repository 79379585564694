import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCheckoutSession } from 'scripts/api/pay-anyway/checkoutSession';
import { IPayAnywayState } from '.';

export interface ICheckoutSessionState {
  sessionId?: string;
  hostedURL?: string;
  isLoading: boolean;
  error?: string;
}

const initialState: ICheckoutSessionState = {
  isLoading: false,
};

export const fetchCheckoutSession = createAsyncThunk(
  'checkoutSession/fetch',
  async ({
    rallyId,
    authToken,
    encryptedProfileInfo,
  }: {
    rallyId: string;
    authToken: string;
    encryptedProfileInfo: string;
  }) => {
    const response = await getCheckoutSession(rallyId, { authToken, encryptedProfileInfo });
    return response.data;
  },
);

export const checkoutSessionSlice = createSlice({
  name: 'checkoutSession',
  initialState,
  reducers: {
    resetCheckoutSession: () => initialState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchCheckoutSession.pending, state => {
        state.isLoading = true;
        state.error = undefined;
        state.sessionId = undefined;
        state.hostedURL = undefined;
      })
      .addCase(fetchCheckoutSession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = undefined;
        state.sessionId = action.payload.sessionId;
        state.hostedURL = action.payload.hostedURL;
      })
      .addCase(fetchCheckoutSession.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message ?? 'Failed to fetch checkout session';
        state.sessionId = undefined;
        state.hostedURL = undefined;
      });
  },
});

export const resetCheckoutSession = checkoutSessionSlice.actions.resetCheckoutSession;

export function selectCheckoutSession(state: IPayAnywayState): ICheckoutSessionState {
  return state.checkoutSession;
}
