import { Button } from '@abyss/web/ui/Button';
import { MouseEventHandler } from 'react';
import withClickTracking from 'scripts/util/analytics/withClickTracking';

export interface IButtonProps {
  ariaLoadingLabel?: string;
  href?: string;
  children: React.ReactNode;
  isAnimated?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  orientation?: 'horizontal' | 'vertical';
  size?: number | string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  onClick?: MouseEventHandler<HTMLButtonElement> | (() => void) | undefined;
  variant?: 'solid' | 'outline' | 'tertiary' | 'destructive' | 'alternate' | 'ghost';
  forwardedRef?: React.Ref<HTMLButtonElement>;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement> | (() => void) | undefined;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement> | (() => void) | undefined;
}

export const AbyssButtonWithClickTracking = withClickTracking<IButtonProps, HTMLElement>(Button);
