/** @jsx jsx */
import { jsx } from '@emotion/react';
import { MoneyUtils, PaymentFormErrorType } from '@rally/rallypay-commons';
import AlertIcon from 'images/icons/icon-alert.svg';
import { ChangeEvent, FocusEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationScreen from 'scripts/components/confirmation-screen/confirmation-screen';
import PaymentForm from 'scripts/components/payment-form/payment-form';
import { AppContext } from 'scripts/contexts/app-context/app-context';
import fullFormStyles from 'scripts/styles/full-form';
import { usePageTracking } from 'scripts/util/analytics/usePageTracking';
import { getClaimNumberTranslationKey } from 'scripts/util/claim/claim';
import { getFeatureFlags } from 'scripts/util/constants/feature-flags';
import { INSUFFICIENT_BALANCE_TYPES, SYSTEM_ERRORS, WARNING_TYPES } from 'scripts/util/payment-form/payment-form';
import CardConfirmation from '../confirmation-screen/card-confirmation';
import ACHConfirmation from '../confirmation-screen/ach-confirmation';
import Anchor, { LinkTarget } from '../shared/anchor/anchor';
import { HSA_METHOD } from 'scripts/interface/multipay.interfaces';

const { formatCurrency, getAmountStringValue, isAmountValid } = MoneyUtils;

const FullForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    formError,
    initialData,
    setFormError,
    setSplitPaymentAmount,
    successResponse,
    cardPaymentResponse,
    achPaymentResponse,
  } = useContext(AppContext);
  const { claimsInfoPacket, otherData, onlyShowCreditCard } = initialData;
  const claimInfo = claimsInfoPacket?.displayInfo?.claimInfo;
  const [amountPaying, setAmountPaying] = useState(claimInfo ? formatCurrency(claimInfo.youMayOweAmount) : '');
  const featureFlags = getFeatureFlags();
  const showSplitPayment = featureFlags.RALLYPAY_FEATURES_SPLIT_PAYMENT;
  const [radioValue, setRadioValue] = useState(onlyShowCreditCard ? '' : HSA_METHOD);

  usePageTracking('submit-payment');

  useEffect(() => {
    setSplitPaymentAmount(claimInfo ? formatCurrency(claimInfo.youMayOweAmount) : '');
  }, []);

  if (cardPaymentResponse && radioValue !== HSA_METHOD) {
    return <CardConfirmation paymentMethodId={radioValue} />;
  }
  if (achPaymentResponse && radioValue !== HSA_METHOD) {
    return <ACHConfirmation paymentMethodId={radioValue} />;
  }

  if (successResponse && radioValue === HSA_METHOD) {
    return <ConfirmationScreen />;
  }

  if (!claimInfo) {
    return null;
  }

  const { claimId, claimType, youMayOweAmount } = claimInfo;

  const isWarningMessage = formError && WARNING_TYPES.indexOf(formError.type) !== -1;
  const isValidationError = formError && SYSTEM_ERRORS.indexOf(formError.type) === -1;
  const isSystemError = formError && SYSTEM_ERRORS.indexOf(formError.type) !== -1;
  const isInsufficientBalanceError = formError && INSUFFICIENT_BALANCE_TYPES.indexOf(formError.type) !== -1;
  const isPaymentTakingTooLong = formError && formError.type === PaymentFormErrorType.PAYMENT_TAKING_TOO_LONG;
  const errorTitleKey = formError && !isPaymentTakingTooLong ? `${formError.type}_TITLE` : null;
  const errorMessageKey = formError ? `${formError.type}_MESSAGE` : null;
  const showOptumBankSSO =
    featureFlags.RALLYPAY_FEATURES_OPTUM_BANK_SSO && isInsufficientBalanceError && otherData?.optumBankSSOUrl;
  const newWindowAccessibilityLabel = t('OPENS_IN_A_NEW_TAB');

  const handleAmountPayingChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    const decimalPointCount = value.split('.').length - 1;
    if (decimalPointCount < 2) {
      setAmountPaying(value);
    }
    if (formError) {
      setFormError();
    }
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    if (isAmountValid(value)) {
      const formattedValue = formatCurrency({ value: getAmountStringValue(value), iso4217: 'USD' });
      setAmountPaying(formattedValue);
      setSplitPaymentAmount(formattedValue);
    } else {
      setSplitPaymentAmount(value);
    }
  };

  return (
    <div className="form-container" data-testid="rp-full-form" css={fullFormStyles}>
      <div className="global-error-message-container" aria-live="assertive">
        {formError && (
          <div
            id={`${formError.type}-full-form`}
            className={`global-error-message${isWarningMessage ? ' global-error-message-warning' : ''}`}
            data-testid={`${formError.type}-full-form`}
          >
            <i className="icon-alert" aria-hidden="true">
              <AlertIcon />
            </i>
            <div>
              {errorTitleKey && <strong>{t(errorTitleKey)}</strong>}
              {errorMessageKey && t(errorMessageKey)}
              {showOptumBankSSO && (
                <p className="optum-sso-link" data-testid="optum-sso-link">
                  <Anchor
                    href={otherData?.optumBankSSOUrl}
                    target={LinkTarget.Blank}
                    newWindowAccessibilityLabel={newWindowAccessibilityLabel}
                    variant="external-inline"
                  >
                    <span>{t('ADD_FUNDS_TO_HSA')}</span>
                  </Anchor>
                </p>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="column">
          <label htmlFor="amount-due">{t('AMOUNT_DUE_LABEL')}</label>
        </div>
        <div className="column right">
          <p id="amount-due">{formatCurrency(youMayOweAmount)}</p>
        </div>
      </div>
      {showSplitPayment && (
        <div className="row">
          <div className="column">
            <label htmlFor="amount-paying">{t('AMOUNT_PAYING_LABEL')}</label>
          </div>
          <div className="column right">
            <input
              id="amount-paying"
              aria-invalid={isValidationError}
              aria-describedby={formError && isValidationError ? `${formError.type}-full-form` : ''}
              data-testid="amount-paying"
              className={`pay-amount${isValidationError ? ' error' : ''}`}
              value={amountPaying}
              onBlur={handleBlur}
              onChange={handleAmountPayingChange}
              readOnly={isSystemError}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="column">
          <label>{t('CLAIM_NUMBER_LABEL')}</label>
        </div>
        <div className="column right">
          <p>{t(getClaimNumberTranslationKey(claimType), { claimId })}</p>
        </div>
      </div>
      <div className="row">
        <div className="column pay-with">
          <label>{t('PAY_WITH_LABEL')}</label>
        </div>
        <div className="column right">
          <PaymentForm radioValue={radioValue} setRadioValue={setRadioValue} />
        </div>
      </div>
    </div>
  );
};

export default FullForm;
