import React, { FunctionComponent } from 'react';
import { Card } from '@abyss/web/ui/Card';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Box } from '@abyss/web/ui/Box';
import { Text } from '@abyss/web/ui/Text';
import { styled } from '@abyss/web/tools/styled';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useTranslation } from 'react-i18next';
import { Flex } from '@abyss/web/ui/Flex';
import { Button } from '@abyss/web/ui/Button';
import { Badge } from '@abyss/web/ui/Badge';
import { Modal } from '@abyss/web/ui/Modal';
import { Layout } from '@abyss/web/ui/Layout';
import { CardBrand, PaymentCardStatus } from '@rally/rallypay-commons';
import { Logo } from '../../../ccg-logo/ccg-logo';
import { usePaymentMethods } from 'scripts/hooks/use-payment-methods/use-payment-methods';
import { MediaQuery } from '@abyss/web/ui/MediaQuery';

// TODO: These should probably not all be undefined, just fixing them for the build
export interface IPaymentMethodCardProps {
  cardBrand: CardBrand | undefined;
  lastFourCard: string | undefined;
  expiryYear: number | undefined;
  paymentMethodId: string | undefined;
  expiryMonth: number | undefined;
  cardStatus: PaymentCardStatus | undefined;
  bankName?: string;
  lastFourBank?: string;
  nickname: string | undefined;
  default?: boolean;
  showRadiobtn?: boolean;
  rallyId: string;
  onIconClick?: () => void;
}

export const PaymentMethodCard: FunctionComponent<IPaymentMethodCardProps> = props => {
  const {
    cardBrand,
    lastFourCard,
    expiryYear,
    expiryMonth,
    paymentMethodId,
    cardStatus,
    bankName,
    lastFourBank,
    nickname,
    showRadiobtn = true,
    rallyId,
    onIconClick,
    default: defaultPayment,
  } = props;
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = React.useState(false);
  const { t } = useTranslation();
  const cardExpired = cardStatus === PaymentCardStatus.EXPIRED;
  // TODO: Temp, should have something better than unknown or make sure month/year are defined
  const expDate =
    expiryMonth && expiryYear
      ? `Exp: ${(expiryMonth < 10 ? '0' + expiryMonth : expiryMonth) + '/' + (expiryYear % 100)}`
      : 'UKNOWN';

  const { deletePaymentMethods } = usePaymentMethods();

  const menuItems = [
    {
      title: t('REMOVE_PAYMENT_METHOD'),
      onClick: () => {
        setIsConfirmDeleteModalOpen(true);
      },
    },
  ];

  const handleExpiredCardIconClick = (): void => {
    if (cardExpired) {
      onIconClick && onIconClick();
    }
  };

  const paymentMethodName = (): string => {
    if (bankName) {
      return `${bankName?.toLocaleLowerCase()} *${lastFourBank}`;
    }
    if (cardBrand) {
      return `${cardBrand?.toLocaleLowerCase()} *${lastFourCard}`;
    }
    if (nickname) {
      return `${nickname?.toLocaleLowerCase()} *${lastFourBank || lastFourCard}`;
    }
    return '';
  };

  const handleMenuOpen = (): void => {
    // @todo add click tracking functionality
  };

  const removePaymentMethod = (): void => {
    setIsConfirmDeleteModalOpen(false);
    if (paymentMethodId) {
      deletePaymentMethods({
        rallyId,
        paymentMethodId,
      });
    }
  };

  return (
    <>
      <StyledCard>
        {showRadiobtn && !cardExpired && <StyledRadio value={paymentMethodId as string} />}
        {lastFourBank && (
          <StyledIconBox>
            <IconMaterial icon="account_balance" size="$md" color="$black" />
          </StyledIconBox>
        )}
        {cardBrand && !lastFourBank && (
          <CcgLogoWrapper onClick={() => handleExpiredCardIconClick()}>
            <Logo accountSystemCode={cardBrand} />
          </CcgLogoWrapper>
        )}
        <StyledPaymentMethod direction="column">
          {lastFourBank && (
            <Text size="$sm" transform="capitalize" data-testid="bank-label" fontWeight="bold">
              {paymentMethodName()}
            </Text>
          )}
          {lastFourBank && defaultPayment && <Badge variant="info">{t('DEFAULT')}</Badge>}
          {cardBrand && !lastFourBank && (
            <>
              <Flex.Content>
                <StyledCardBrand size="$sm" transform="capitalize" data-testid="card-brand" fontWeight="bold">
                  {paymentMethodName()}
                </StyledCardBrand>
              </Flex.Content>
              <StyledFlex alignItems="center">
                <StyledDesc size="$sm">
                  {cardExpired ? (
                    <>
                      <RedStyledText size="sm">{expDate}</RedStyledText>
                      <Badge variant="error" data-testid="expired-badge">
                        {t('EXPIRED')}
                      </Badge>
                    </>
                  ) : (
                    <StyledExpirationText size="sm">{expDate}</StyledExpirationText>
                  )}
                </StyledDesc>
                {!cardExpired && defaultPayment && <Badge variant="info">{t('DEFAULT')}</Badge>}
              </StyledFlex>
            </>
          )}
        </StyledPaymentMethod>
        {showRadiobtn && (
          <MenuContainer onClick={handleMenuOpen}>
            <MediaQuery smallerThan="$lg">
              <DropdownMenu
                label="Table Actions"
                hideLabel
                after={<IconMaterial icon="more_vert" size="$md" />}
                menuItems={menuItems}
              />
            </MediaQuery>
            <MediaQuery largerThan="$lg">
              <DropdownMenu label="Options" after={<IconMaterial icon="keyboard_arrow_down" />} menuItems={menuItems} />
            </MediaQuery>
          </MenuContainer>
        )}
      </StyledCard>
      <Modal
        title={t('ARE_YOU_SURE_YOU_WANT_TO_REMOVE')}
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => setIsConfirmDeleteModalOpen(false)}
      >
        <Modal.Section>
          <StyledLayoutGroup alignLayout="right">
            <Button variant="outline" onClick={() => setIsConfirmDeleteModalOpen(false)}>
              {t('CANCEL_LABEL')}
            </Button>
            <Button onClick={removePaymentMethod}>{t('REMOVE_METHOD')}</Button>
          </StyledLayoutGroup>
        </Modal.Section>
      </Modal>
    </>
  );
};

const StyledCardBrand = styled(Text, {
  verticalAlign: 'top',
  lineHeight: '18px',
});

const MenuContainer = styled('div', {
  padding: '$xs',
  cursor: 'pointer',
});

const StyledPaymentMethod = styled(Flex, {
  width: '100%',
  gap: '$xs',
});

export const StyledRadio = styled(RadioGroup.Radio, {
  '&.abyss-radio-label': {
    display: 'none',
  },
});

export const CcgLogoWrapper = styled('div', {
  padding: '$xs',
  margin: '0px $xs',
});

export const StyledCard = styled(Card, {
  display: 'flex',
  padding: '$md',
  '&.abyss-card-root': {
    marginBottom: '$md',
    marginTop: '0',
    backgroundColor: '$white',
    boxShadow: 'none',
  },
});

export const StyledCardNumber = styled(Text, {
  textTransform: 'capitalize',
});

export const StyledExpirationText = styled(Text, {
  display: 'block',
  fontWeight: 'normal',
});

export const RedStyledText = styled(StyledExpirationText, {
  fontWeight: 'normal',
  '&.abyss-text-root': {
    color: '$error1',
  },
});

export const StyledIconBox = styled(Box, {
  '&.abyss-box-root': {
    height: 'auto',
    width: 'auto',
    backgroundColor: 'white',
    padding: '0px $sm',
    margin: '0px $xs',
  },
});

const StyledFlex = styled(Flex, {
  gap: '$sm',
});

const StyledDesc = styled(Text, {
  fontWeight: 'normal',
});

const StyledLayoutGroup = styled(Layout.Group, {
  '&.abyss-layout-group': {
    '@screen < $sm': {
      flexWrap: 'wrap',
      justifyContent: 'center',
      rowGap: '$sm',
    },
  },
});
