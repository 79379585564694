import { Theme } from '@emotion/react';
import { ThemeName, ArcadeWebTheme } from '@rally/rallypay-commons';
import defaultTheme from './default';
import arcadeDesktop from './arcade-desktop';
import arcadePhone from './arcade-phone';
import arcadeTablet from './arcade-tablet';
import { IThemes } from './themes.interfaces';
import * as designSystemRally from '@rally/ui-themes/dist/web/rally/tokens.es6';
import * as designSystemUhc from '@rally/ui-themes/dist/web/uhc/tokens.es6';

export function applyWebTheme(existingTheme: Theme, arcadeWebTheme: ArcadeWebTheme): Theme {
  const themeTokens = arcadeWebTheme === ArcadeWebTheme.Advantage ? designSystemRally : designSystemUhc;
  return {
    ...existingTheme,
    colors: {
      ...existingTheme.colors,
      primary: themeTokens.RDS_COLOR_PRIMARY,
    },
    global: {
      ...existingTheme.global,
      fonts: {
        ...existingTheme.global.fonts,
        fontFamily: themeTokens.RDS_FONT_FAMILY,
      },
    },
  };
}

const themes: IThemes = {
  [ThemeName.ArcadeDesktop]: arcadeDesktop,
  [ThemeName.ArcadePhone]: arcadePhone,
  [ThemeName.ArcadeTablet]: arcadeTablet,
  [ThemeName.Default]: defaultTheme,
};

export default themes;
