import { ThemeName, ArcadeWebTheme } from '@rally/rallypay-commons';
import themes, { applyWebTheme } from 'scripts/styles/themes/themes';
import { Theme } from '@emotion/react';

export const getTheme = (): Theme => {
  const urlParams = new URLSearchParams(window.location.search);
  const themeName = urlParams.get('themeName') as ThemeName;
  const arcadeWebTheme = (urlParams.get('arcadeWebTheme') as ArcadeWebTheme) || 'unitedhealth';
  const themeToUse = themes[themeName] || themes[ThemeName.Default];
  return applyWebTheme(themeToUse, arcadeWebTheme);
};
