import React, { useEffect } from 'react';
import { styled } from '@abyss/web/tools/styled';
import { AbyssThemed } from 'scripts/ui/abyss-themed/abyss-themed';
import { Trans, useTranslation } from 'react-i18next';
import CheckIcon from 'images/icons/icon-circle-check-2.svg';
import { Text } from '@abyss/web/ui/Text';
import { Layout } from '@abyss/web/ui/Layout';
import { ICurrencyAmount, MessageType } from '@rally/rallypay-commons';
import Anchor from '../shared/anchor/anchor';
import { format } from 'date-fns';
import { AbyssButtonWithClickTracking } from '../shared/abyss-button/abyss-button';

export interface IConfirmationView {
  paymentAmount: string | number | ICurrencyAmount;
  methodDisplayName: string;
  providerName: string | undefined;
  confirmationNumber: string | undefined;
  isCardPayment: boolean;
  timeStamp: string;
  onDone: (() => void) | undefined;
}

export const ConfirmationView: React.FunctionComponent<IConfirmationView> = ({
  paymentAmount,
  methodDisplayName,
  providerName,
  confirmationNumber,
  isCardPayment,
  timeStamp,
  onDone,
}) => {
  useEffect(() => {
    window?.parent.postMessage(
      { type: MessageType.SetTitle, payload: { title: t('PAYMENT_CONFIRMATION_TITILE') } },
      '*',
    );
  }, []);

  const { t } = useTranslation();
  const paymentDate = timeStamp ? format(new Date(timeStamp), 'MMMM do, yyyy') : undefined;
  return (
    <AbyssThemed>
      <Container data-testid="confirmation-page">
        <PaymentContentContainer>
          <IconContainer aria-hidden="true">
            <CheckIcon />
          </IconContainer>
          <ThanksTitle>{t('PAYMENT_THANKS', { paymentAmount })}</ThanksTitle>
          <Layout.Stack>
            {paymentDate && <Text size="$lg">{t('PAYMENT_DATE', { paymentDate })}</Text>}
            <Text size="$lg">{t('PAYMENT_METHOD', { methodDisplayName })}</Text>
            <Text size="$lg">{t('CLAIM_PAID', { providerName })}</Text>
            {!confirmationNumber && !isCardPayment && (
              <Text size="$lg">{t('SUCCESSFUL_PAYMENT_MESSAGE_CONFIRMATION_PENDING')}</Text>
            )}
            {confirmationNumber && (
              <Text size="$lg">{t('SUCCESSFUL_PAYMENT_MESSAGE_CONFIRMATION', { confirmationNumber })}</Text>
            )}
          </Layout.Stack>
          <PaymentQueryMessage data-testid="additional-info">
            {isCardPayment ? (
              <Text size="$lg">{t('SUCCESSFUL_PAYMENT_MESSAGE_ADDITIONAL_INFO_CC')}</Text>
            ) : (
              <Trans i18nKey="SUCCESSFUL_PAYMENT_MESSAGE_ADDITIONAL_INFO">
                <Anchor href="tel:1-800-791-9361" variant="inline">
                  1-800-791-9361
                </Anchor>
              </Trans>
            )}
          </PaymentQueryMessage>
        </PaymentContentContainer>
        <DoneButton
          data-testid="close-window"
          onClick={onDone}
          trackingName={'done'}
          trackingSection={'payment confirmation'}
        >
          {t('DONE')}
        </DoneButton>
      </Container>
    </AbyssThemed>
  );
};

const Container = styled('div', {
  backgroundColour: 'white',
  width: '100%',
  height: '100%',
});

const PaymentContentContainer = styled('div', {
  margin: '$xl 0',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const ThanksTitle = styled(Text, {
  fontWeight: '$bold',
  margin: '$md 0 12px 0',
  fontSize: '$xl !important',
  textAlign: 'center',
  lineHeight: '24px !important',
});

const DoneButton = styled(AbyssButtonWithClickTracking, {
  '&.abyss-button-root': {
    margin: '0 auto',
    display: 'block',
    width: '320px',
    '@screen < $md': {
      width: '100%',
    },
  },
});

const PaymentQueryMessage = styled('div', {
  padding: '12px $lg',
  alignSelf: 'stretch',
  backgroundColor: '$gray3',
  marginTop: '12px',
  lineHeight: '24px',
  fontWeight: '$medium',
  color: '$gray8',
  textAlign: 'center',
  a: {
    fontWeight: '$bold',
  },
});

const IconContainer = styled('i', {
  height: 86,
  fill: '$white',
  width: 76,
  padding: '5px 0',
  display: 'inline-block',
  '.circle': {
    fill: '#52A26C',
  },
  '.check': {
    fill: '$white',
  },
});
