import { ICreatePayment } from '@rally/rallypay-commons';

export enum CardPaymentActionTypes {
  SUBMIT_CARD_PAYMENT = 'SUBMIT_CARD_PAYMENT',
  SUBMIT_CARD_PAYMENT_SUCCESS = 'SUBMIT_CARD_PAYMENT_SUCCESS',
  SUBMIT_CARD_PAYMENT_FAILURE = 'SUBMIT_CARD_PAYMENT_FAILURE',
}

export interface ISubmitCardPayment {
  type: CardPaymentActionTypes.SUBMIT_CARD_PAYMENT;
}

export interface ISubmitCardPaymentSuccess {
  payload: ICreatePayment;
  type: CardPaymentActionTypes.SUBMIT_CARD_PAYMENT_SUCCESS;
}

export interface ISubmitCardPaymentFailure {
  payload: string | { statusCode: number };
  type: CardPaymentActionTypes.SUBMIT_CARD_PAYMENT_FAILURE;
}

export function submitCardPayment(): ISubmitCardPayment {
  return {
    type: CardPaymentActionTypes.SUBMIT_CARD_PAYMENT,
  };
}

export function submitCardPaymentSuccess(payload: ICreatePayment): ISubmitCardPaymentSuccess {
  return {
    payload,
    type: CardPaymentActionTypes.SUBMIT_CARD_PAYMENT_SUCCESS,
  };
}

export function submitCardPaymentFailure(payload: string | { statusCode: number }): ISubmitCardPaymentFailure {
  return {
    payload,
    type: CardPaymentActionTypes.SUBMIT_CARD_PAYMENT_FAILURE,
  };
}

export type CardPaymentAction = ISubmitCardPayment | ISubmitCardPaymentSuccess | ISubmitCardPaymentFailure;
