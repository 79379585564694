import { ICreatePayment } from '@rally/rallypay-commons';
import { ACHPaymentActionTypes, ACHPaymentAction } from 'scripts/actions/ach-payment-action';

export interface IACHPaymentFormState {
  data?: ICreatePayment;
  loading: boolean;
  error: boolean;
  errorResponse?: string | { statusCode: number };
  attempt: number;
}

export const initialState: IACHPaymentFormState = {
  data: undefined,
  loading: false,
  error: false,
  errorResponse: '',
  attempt: 0,
};

const achPaymentReducer = (state: IACHPaymentFormState, action: ACHPaymentAction): IACHPaymentFormState => {
  switch (action.type) {
    case ACHPaymentActionTypes.SUBMIT_ACH_PAYMENT: {
      return { ...state, data: undefined, attempt: state.attempt + 1, loading: true, error: false };
    }
    case ACHPaymentActionTypes.SUBMIT_ACH_PAYMENT_SUCCESS: {
      return { ...state, data: action.payload, loading: false, error: false };
    }
    case ACHPaymentActionTypes.SUBMIT_ACH_PAYMENT_FAILURE: {
      return { ...state, loading: false, error: true, data: undefined, errorResponse: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default achPaymentReducer;
