import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@abyss/web/tools/styled';
import { Text } from '@abyss/web/ui/Text';
import { Link } from '@abyss/web/ui/Link';

export const TermsAndConditions: FunctionComponent = () => {
  const { t } = useTranslation();
  const TERMS_AND_CONDITIONS_URL = 'https://wallet.healthsafepay.com/payment-terms-and-conditions';
  const PRIVACY_POLICY_URL = 'https://www.optum.com/privacy-policy.html';

  return (
    <>
      <TextWrapper>
        <span>{t('TERMS_AND_CONDITIONS_PAYMENT_METHOD_ACKNOWLEDGE')}</span>
        <Link size="$link.font-size.xs" href={TERMS_AND_CONDITIONS_URL}>
          {t('PAYMENT_SERVICE_TERM_CONDITIONS')}
        </Link>
        <span>{t('TERMS_AND_CONDITIONS_PAYMENT_METHOD_ACKNOWLEDGE_SUBTEXT')}</span>
        <Link size="$link.font-size.xs" href={PRIVACY_POLICY_URL}>
          {t('PRIVACY_POLICY')}
        </Link>
      </TextWrapper>

      <Text size="xs" fontWeight="bold">
        {t('TERMS_AND_CONDITIONS')}
      </Text>
      <StyledP>
        <Text size="xs">{t('TERMS_AND_CONDITIONS_ALLOW_UHC_INSURANCE_COMPANY')}</Text>
        <br />
        <Text size="xs">{t('TERMS_AND_CONDITIONS_AUTHORIZE_TO_BANK')}</Text>
        <br />
        <Text size="xs">{t('TERMS_AND_CONDITIONS_SUBMISSION_ACKNOWLEDGE')}</Text>
      </StyledP>
    </>
  );
};

const StyledP = styled('p', {
  fontSize: '$xs',
  fontWeight: 'normal',
});

const TextWrapper = styled('div', {
  fontSize: '$xs',
  fontWeight: 'normal',
  margin: '$sm 0 $md 0',
});
