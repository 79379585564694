import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { IMultipayDefaultProps } from 'scripts/interface/multipay.interfaces';
import { PayAnywayDispatch } from 'scripts/state';
import { fetchCheckoutSession, ICheckoutSessionState, selectCheckoutSession } from 'scripts/state/checkoutSession';

export function useMuultipayCheckoutSession(props: IMultipayDefaultProps): ICheckoutSessionState {
  const dispatch = useDispatch<PayAnywayDispatch>();

  const { rallyId, authToken, encryptedProfileInfo } = props.initialData;
  const session = useSelector(selectCheckoutSession);

  // Fetch a new session on load
  useEffect(() => {
    if (!authToken || !encryptedProfileInfo) return;
    dispatch(fetchCheckoutSession({ rallyId, authToken, encryptedProfileInfo }));
  }, []);

  return session;
}
