import {
  ICreatePayment,
  IInitialData,
  IPaymentFormError,
  IPaymentsHSA,
  IReceiveTokenResponse,
} from '@rally/rallypay-commons';

export enum TokenType {
  NEW_RALLPAYDATA_TOKEN = 'NEW_RALLPAYDATA_TOKEN',
  REFRESH_RALLPAYDATA_TOKEN = 'REFRESH_RALLPAYDATA_TOKEN',
}

export interface IReceiveTokenResponseExtended extends IReceiveTokenResponse {
  tokenType: TokenType;
}

export interface IAppContext {
  initialData: IInitialData;
  formError?: IPaymentFormError;
  receiveTokenResponse?: IReceiveTokenResponseExtended;
  setFormError: (error?: IPaymentFormError) => void;
  setSplitPaymentAmount: (amount: string) => void;
  setSuccessResponse?: (response?: IPaymentsHSA) => void;
  splitPaymentAmount: string;
  successResponse?: IPaymentsHSA;
  isAddCardScreenOpen?: boolean;
  setIsAddCardScreenOpen: (isOpen: boolean) => void;
  cardPaymentResponse?: ICreatePayment;
  setCardPaymentResponse?: (response: ICreatePayment) => void;
  showAddCardScreenError?: boolean;
  setShowAddCardScreenError: (showError: boolean) => void;
  achPaymentResponse?: ICreatePayment;
  setACHPaymentResponse?: (response: ICreatePayment) => void;
}
