import { ClickTrackingEventType, MessageType } from '@rally/rallypay-commons';

export function handleClickTracking(trackingName: string, trackingSection: string, href?: string): void {
  if (!window.parent) return;

  const clickEvent: ClickTrackingEventType = {
    type: MessageType.ClickTracking,
    payload: {
      url: href,
      name: trackingName,
      section: trackingSection,
    },
  };
  window.parent.postMessage(clickEvent, '*');
}
