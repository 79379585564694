import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';

axios.defaults.xsrfHeaderName = 'Rallypay-XSRF-Token';
axios.defaults.xsrfCookieName = 'Rallypay-XSRF-Token';
axios.defaults.withCredentials = true;

const HttpClient = {
  get<T>(url: string): AxiosPromise<T> {
    return axios.get(url);
  },

  post<T>(url: string, data?: {}, options?: AxiosRequestConfig): AxiosPromise<T> {
    return axios.post(url, data, options);
  },

  delete<T>(url: string, data?: {}): AxiosPromise<T> {
    return axios.delete(url, data);
  },
};

export const contentTypeTextAxiosOptions: AxiosRequestConfig = {
  headers: { 'Content-Type': 'text/plain' },
};

export const contentTypeJsonAxiosOptions: AxiosRequestConfig = {
  headers: { 'Content-Type': 'application/json' },
};

export default HttpClient;
