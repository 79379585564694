import {
  HandleMessage,
  IInitialData,
  IPaymentFormError,
  IPaymentsHSA,
  InboundMessageEventData,
  MessageType,
  ICreatePayment,
} from '@rally/rallypay-commons';
import React, { useCallback, useState } from 'react';
import { IAppContext, IReceiveTokenResponseExtended } from './app-context.interfaces';
import useMessageHandler from 'scripts/hooks/use-message-handler/use-message-handler';

export const appContextInitialState: IAppContext = {
  initialData: {} as IInitialData,
  setFormError: () => undefined,
  setSplitPaymentAmount: () => undefined,
  splitPaymentAmount: '',
  isAddCardScreenOpen: false,
  setIsAddCardScreenOpen: () => undefined,
  showAddCardScreenError: false,
  setShowAddCardScreenError: () => undefined,
};

export const AppContext = React.createContext<IAppContext>(appContextInitialState);

export interface IAppProvider {
  children: React.ReactNode;
}

export const AppProvider: React.FunctionComponent<IAppProvider> = ({ children }: { children?: React.ReactNode }) => {
  const [successResponse, setSuccessResponse] = useState<IPaymentsHSA | undefined>(undefined);
  const [splitPaymentAmount, setSplitPaymentAmount] = useState<string>('');
  const [formError, setFormError] = useState<IPaymentFormError | undefined>(undefined);
  const [receiveTokenResponse, setReceiveTokenResponse] = useState<IReceiveTokenResponseExtended | undefined>(
    undefined,
  );
  const [initialData, setInitialData] = useState<IInitialData>({} as IInitialData);
  const [isAddCardScreenOpen, setIsAddCardScreenOpen] = useState<boolean>();
  const [cardPaymentResponse, setCardPaymentResponse] = useState<ICreatePayment>();
  const [showAddCardScreenError, setShowAddCardScreenError] = useState<boolean>();
  const [achPaymentResponse, setACHPaymentResponse] = useState<ICreatePayment>();

  /**
   * Handle messages coming from the host app
   */
  const handleInboundMessage: HandleMessage<InboundMessageEventData> = useCallback(
    ({ type, payload }) => {
      switch (type) {
        case MessageType.InitialData:
          if (Object.keys(initialData).length === 0) {
            setInitialData(payload as IInitialData);
          }
          return;
        case MessageType.ReceiveToken:
          setReceiveTokenResponse(payload as IReceiveTokenResponseExtended);
          return;
      }
    },
    [initialData],
  );
  useMessageHandler(handleInboundMessage);

  return (
    <AppContext.Provider
      value={{
        initialData,
        formError,
        receiveTokenResponse,
        setFormError,
        setSplitPaymentAmount,
        setSuccessResponse,
        splitPaymentAmount,
        successResponse,
        isAddCardScreenOpen,
        setIsAddCardScreenOpen,
        cardPaymentResponse,
        setCardPaymentResponse,
        achPaymentResponse,
        setACHPaymentResponse,
        showAddCardScreenError,
        setShowAddCardScreenError,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
