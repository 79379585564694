import { Theme } from '@emotion/react';
import * as designSystemRally from '@rally/ui-themes/dist/web/rally/tokens.es6';

const confirmationScreen = {
  iconContainer: {
    margin: `0 0 ${designSystemRally.RDS_SPACING_SIZE_24}`,
  },
  h2: {
    fontSize: designSystemRally.RDS_SPACING_SIZE_32,
    margin: `0 0 ${designSystemRally.RDS_SPACING_SIZE_32}`,
  },
  p: {
    fontSize: designSystemRally.RDS_FONT_SIZE_20,
    margin: `0 0 ${designSystemRally.RDS_SPACING_SIZE_16}`,
  },
  providerName: {
    fontSize: designSystemRally.RDS_SPACING_SIZE_24,
  },
};

const confirmationScreenMobile = {
  iconContainer: {
    margin: `0 0 ${designSystemRally.RDS_SPACING_SIZE_16}`,
  },
  h2: {
    fontSize: designSystemRally.RDS_SPACING_SIZE_16,
    margin: `0 0 ${designSystemRally.RDS_SPACING_SIZE_16}`,
    fontWeight: designSystemRally.RDS_FONT_WEIGHT_SEMIBOLD,
  },
  p: {
    fontSize: designSystemRally.RDS_FONT_SIZE_16,
    margin: `0 0 ${designSystemRally.RDS_SPACING_SIZE_16}`,
  },
  providerName: {
    fontSize: designSystemRally.RDS_FONT_SIZE_16,
  },
};

const defaultTheme: Theme = {
  button: {
    width: 'auto',
  },
  colors: {
    black: designSystemRally.RDS_COLOR_BLACK,
    green: designSystemRally.RDS_COLOR_SYSTEM_JADE,
    gray: designSystemRally.RDS_COLOR_NEUTRAL_80,
    grayDark: designSystemRally.RDS_COLOR_NEUTRAL_50,
    grayLight: designSystemRally.RDS_COLOR_NEUTRAL_90,
    red: designSystemRally.RDS_COLOR_SYSTEM_GRENADIER,
    redDark: designSystemRally.RDS_COLOR_SYSTEM_GRENADIER_DARK,
    redLight: designSystemRally.RDS_COLOR_SYSTEM_GRENADIER_LIGHT,
    white: designSystemRally.RDS_COLOR_WHITE,
    yellowLight: designSystemRally.RDS_COLOR_SYSTEM_DANDELION,
    yellowLighter: designSystemRally.RDS_COLOR_SYSTEM_DANDELION_LIGHT,
    uhcBlue: '#196ecf',
  },
  confirmationScreen,
  error: {
    fontSize: designSystemRally.RDS_FONT_SIZE_16,
    textAlign: 'center',
    padding: `${designSystemRally.RDS_SPACING_SIZE_8} ${designSystemRally.RDS_SPACING_SIZE_8} 0 0`,
  },
  global: {
    color: designSystemRally.RDS_COLOR_BLACK,
    fonts: {
      fontFamily: designSystemRally.RDS_FONT_FAMILY,
      fontSize: designSystemRally.RDS_FONT_SIZE_16,
    },
    lineHeight: designSystemRally.RDS_FONT_LINE_HEIGHT_16,
  },
  isMobile: false,
  label: {
    color: designSystemRally.RDS_COLOR_BLACK,
    fontSize: designSystemRally.RDS_FONT_SIZE_16,
    fontWeight: designSystemRally.RDS_FONT_WEIGHT_SEMIBOLD,
    lineHeight: designSystemRally.RDS_FONT_LINE_HEIGHT_20,
  },
  logo: {
    height: '40px',
    width: '60px',
  },
  message: {
    padding: `${designSystemRally.RDS_SPACING_SIZE_8} ${designSystemRally.RDS_SPACING_SIZE_8} 0 0`,
  },
  p: {
    fontSize: designSystemRally.RDS_FONT_SIZE_16,
    fontWeight: designSystemRally.RDS_FONT_WEIGHT_SEMIBOLD,
  },
  typeAndBalance: {
    lineHeight: 1,
  },
  row: {
    height: designSystemRally.RDS_SPACING_SIZE_40,
    margin: `0 ${designSystemRally.RDS_SPACING_SIZE_8} ${designSystemRally.RDS_SPACING_SIZE_16} 0`,
  },
  standalone: {
    confirmationScreen,
    mobile: {
      confirmationScreen: confirmationScreenMobile,
    },
  },
  useAnotherAccount: {
    fontSize: designSystemRally.RDS_FONT_SIZE_16,
    textAlign: 'center',
    fontWeight: designSystemRally.RDS_FONT_WEIGHT_SEMIBOLD,
  },
};

export default defaultTheme;
