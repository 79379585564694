import { css } from '@emotion/react';
import { Style } from 'scripts/styles/themes/themes.interfaces';

const agreementBoxStyles: Style = () => css`
  .agreement-box-container {
    border-radius: 4px;
    overflow-y: scroll;
    max-height: 147px;
    border: 1px solid black;
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 8px;
  }
  .bold-segment {
    font-weight: bold;
    margin-bottom: 16px;
  }

  .segment {
    margin-bottom: 16px;
  }

  .point-text {
    &:before {
      content: '\u2022';
      float: left;
      margin-left: -15px;
    }
    margin-left: 18px;
  }
`;

export default agreementBoxStyles;
