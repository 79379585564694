import React, { FunctionComponent } from 'react';
import { Card } from '@abyss/web/ui/Card';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Text } from '@abyss/web/ui/Text';
import { Flex } from '@abyss/web/ui/Flex';
import { styled } from '@abyss/web/tools/styled';
import { useTranslation } from 'react-i18next';
import { Badge } from '@abyss/web/ui/Badge';
import { formatCurrency, getAmount } from 'scripts/util/multipay/multipay-util';
import { ICurrencyAmount } from '@rally/rallypay-commons';
import { Logo } from '../../../ccg-logo/ccg-logo';
import { CcgLogoWrapper } from './payment-method-card';

import {
  IAccountInfoItem,
  AccountSystemCode,
  AccountType,
  ILedgerAccount,
  HSA_METHOD,
} from 'scripts/interface/multipay.interfaces';

interface IHsaPaymentMethodCard {
  account: ILedgerAccount;
  accountSystemCode: AccountSystemCode | undefined;
  accountType: AccountType | undefined;
  availableBalance: ICurrencyAmount | undefined;
  selectedAccount: IAccountInfoItem | null;
  default: boolean;
  insufficientHsaBalance: boolean;
  showRadio: boolean;
  minimumAmountAllowedForOnlinePayment: number;
  onHSACardClick: () => void;
  isDefaultCardPresent: boolean;
}

export const HsaPaymentMethodCard: FunctionComponent<IHsaPaymentMethodCard> = props => {
  const {
    availableBalance,
    insufficientHsaBalance,
    accountSystemCode,
    default: isHsaDefault,
    showRadio,
    minimumAmountAllowedForOnlinePayment,
    onHSACardClick,
  } = props;
  const isDisabled = getAmount(availableBalance) < minimumAmountAllowedForOnlinePayment;
  const { t } = useTranslation();

  return (
    <StyledCard
      css={isDisabled || insufficientHsaBalance ? { background: '$gray3 !important' } : {}}
      collapseOnClick={onHSACardClick}
    >
      {showRadio && (
        <StyledRadio
          value={HSA_METHOD}
          css={isDisabled || insufficientHsaBalance ? { background: '$gray3 !important' } : {}}
          isDisabled={isDisabled || insufficientHsaBalance}
        />
      )}
      <CcgLogoWrapper>
        <Logo accountSystemCode={accountSystemCode} />
      </CcgLogoWrapper>
      <StyledPaymentMethod direction="column">
        <Flex.Content>
          <StyledCardBrand size="$sm" transform="capitalize" data-testid="bank-label" fontWeight="bold">
            {t('SAVINGS_ACCOUNT')}
          </StyledCardBrand>
        </Flex.Content>
        <StyledFlex alignItems="center">
          <StyledDesc size="$sm">
            {t('BALANCE_LABEL')}: {formatCurrency(availableBalance)}
          </StyledDesc>
          {isHsaDefault ? (
            <Badge variant={isDisabled || insufficientHsaBalance ? 'warning' : 'info'}>
              {isDisabled || insufficientHsaBalance ? 'Insufficient funds' : t('DEFAULT')}
            </Badge>
          ) : isDisabled || insufficientHsaBalance ? (
            <Badge variant="warning">{'Insufficient funds'}</Badge>
          ) : (
            ''
          )}
        </StyledFlex>
      </StyledPaymentMethod>
    </StyledCard>
  );
};

const StyledDesc = styled(Text, {
  fontWeight: 'normal',
});

const StyledCard = styled(Card, {
  display: 'flex',
  padding: '$md',
  '&.abyss-card-root': {
    marginBottom: '$md',
    marginTop: '0',
    backgroundColor: '$white',
    boxShadow: 'none',
  },
});

const StyledPaymentMethod = styled(Flex, {
  width: '100%',
  gap: '$xs',
});

const StyledRadio = styled(RadioGroup.Radio, {
  '&.abyss-radio-label': {
    display: 'none',
  },
});

const StyledFlex = styled(Flex, {
  gap: '$sm',
});

const StyledCardBrand = styled(Text, {
  verticalAlign: 'top',
  lineHeight: '18px',
});
