import { IPaymentsHSA } from '@rally/rallypay-commons';
import { AxiosPromise } from 'axios';
import HttpClient, { contentTypeJsonAxiosOptions } from 'scripts/api/http-client';
import { paymentUris } from 'scripts/util/uri/uri';
import { IPaymentsConsent, IPaymentsService, IPaymentsTokenAuth, IPreflightData } from './payments.interfaces';
import { IMultiClaimPaymentsHSA } from 'scripts/interface/multipay.interfaces';

export const PaymentsService: IPaymentsService = {
  hsa: ({ authToken, token }): AxiosPromise<IPaymentsHSA> => {
    const url = paymentUris.hsaV6();
    return HttpClient.post<IPaymentsHSA>(url, { authToken, token }, contentTypeJsonAxiosOptions);
  },
  multiClaimHsa: ({ authToken, tokens }): AxiosPromise<IMultiClaimPaymentsHSA> => {
    const url = paymentUris.hsaV7();
    return HttpClient.post<IMultiClaimPaymentsHSA>(url, { authToken, tokens }, contentTypeJsonAxiosOptions);
  },
  getConsent: ({ rallyId }): AxiosPromise<IPaymentsConsent> => {
    const url = paymentUris.consent(rallyId);
    return HttpClient.get(url);
  },
  submitConsent: ({ rallyId, authToken }): AxiosPromise => {
    const url = paymentUris.consent(rallyId);
    return HttpClient.post(url, { source: 'WEB', authToken }, contentTypeJsonAxiosOptions);
  },
  preflight: (tokenValidatorData: IPreflightData): AxiosPromise<IPaymentsTokenAuth> => {
    const { token, rallypayUIVersion, hsaAccountBalance, paymentAmount, claimKey, amountOwed, hashedTin } =
      tokenValidatorData;
    if (hashedTin) {
      const url = paymentUris.preflightV4();
      return HttpClient.post<IPaymentsTokenAuth>(
        url,
        { token, rallypayUIVersion, hsaAccountBalance, paymentAmount, claimKey, amountOwed, hashedTin },
        contentTypeJsonAxiosOptions,
      );
    } else {
      const url = paymentUris.preflightV3();
      return HttpClient.post<IPaymentsTokenAuth>(
        url,
        { token, rallypayUIVersion, hsaAccountBalance, paymentAmount, claimKey, amountOwed },
        contentTypeJsonAxiosOptions,
      );
    }
  },
};
