import React, { FunctionComponent, useState, useEffect } from 'react';
import { styled } from '@abyss/web/tools/styled';
import { Card } from '@abyss/web/ui/Card';
import { Button } from '@abyss/web/ui/Button';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { SelectPaymentMethod } from './select-payment-method/select-payment-method';
import { useTranslation } from 'react-i18next';
import { PaymentMethodCard } from './select-payment-method/payment-method-card';
import { IPaymentMethodsItem } from '@rally/rallypay-commons';
import { Alert } from '@abyss/web/ui/Alert';
import { usePaymentMethods } from 'scripts/hooks/use-payment-methods/use-payment-methods';
// eslint-disable-next-line max-len
import { ErrorCallbackArgs } from '@optum-ccg/convenient-checkout-ui/dist/cjs/capabilities/checkout-v2/types';
import { HSA_METHOD, IMultipayDefaultProps } from 'scripts/interface/multipay.interfaces';
import { HsaPaymentMethodCard } from './select-payment-method/hsa-payment-method-card';

interface IPaymentMethod {
  isHsaDefaultPayment: boolean;
  isDefaultCardPresent: boolean;
  insufficientHsaBalance: boolean;
  updatePaymentMethod: (paymentMethod: IPaymentMethodsItem | string) => void;
  updatePaymentMethodList: (paymentMethods: IPaymentMethodsItem[]) => void;
  data: IMultipayDefaultProps;
  setPaymentMethodAdditionError(error: ErrorCallbackArgs): void;
  setIsCardDefaultPayment(isCardDefaultPayment: boolean): void;
  setIsHsaDefaultPayment(isHsaDefaultPayment: boolean): void;
  defaultPaymentMethod: IPaymentMethodsItem | string;
  selectedPaymentMethod: IPaymentMethodsItem | string;
  setDefaultPayment(defaultPaymentMethod: IPaymentMethodsItem | string): void;
  setSelectedPaymentMethod(selectedPaymentMethod: IPaymentMethodsItem | string): void;
}

export const PaymentMethod: FunctionComponent<IPaymentMethod> = props => {
  const { t } = useTranslation();
  const {
    isDefaultCardPresent,
    isHsaDefaultPayment,
    insufficientHsaBalance,
    updatePaymentMethod,
    updatePaymentMethodList,
    data,
    setPaymentMethodAdditionError,
    setIsCardDefaultPayment,
    setIsHsaDefaultPayment,
    defaultPaymentMethod: defaultPayment,
    selectedPaymentMethod,
    setDefaultPayment,
    setSelectedPaymentMethod,
  } = props;
  const [isEditPayMethodEnabled, setIsEditPayMethodEnabled] = useState(false);
  const [showHSAWarning, setShowHSAWarning] = useState(false);

  const [isDefault, setIsDefault] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [fetchPaymentMethodCallCount, setFetchPaymentMethodCallCount] = useState(0);
  const [isPaymentSelectionOpen, setIsPaymentSelectionOpen] = useState(false);
  const [prevPaymentMethods, setPrevPaymentMethods] = useState<IPaymentMethodsItem[]>([]);
  const [hsaSelected, setHsaSelected] = useState(false);

  const { paymentMethodsData, postPaymentMethodsError, postPaymentMethodsLoading, fetchPaymentMethods } =
    usePaymentMethods();
  const fetchedPaymentMethodsData: IPaymentMethodsItem[] = paymentMethodsData?.paymentMethods || [];

  const {
    rallyId,
    authToken,
    encryptedProfileInfo,
    confirmSelectionFlag: allowChangePaymentMethod,
    isPaymentMethodAdded,
    setIsPaymentMethodAdded,
    setIsSessionCheckoutError,
    hsaAccount,
    minimumAmountAllowedForOnlinePayment,
    claimInfoPacketsError,
  } = data.initialData;

  const hsaBalance = hsaAccount?.balance?.availableAmount?.value;
  const toggleCollapse = (): void => {
    allowChangePaymentMethod && setIsCollapsed(true);
  };

  const handleChangePaymentMethod = (): void => {
    setIsPaymentSelectionOpen(!isPaymentSelectionOpen);
    setIsEditPayMethodEnabled(!isEditPayMethodEnabled);
    toggleCollapse();
  };

  useEffect(() => {
    if (rallyId && authToken && encryptedProfileInfo) {
      if (fetchPaymentMethodCallCount === 0 || isPaymentMethodAdded) {
        fetchPaymentMethods({
          rallyId: rallyId,
          authToken: authToken,
          encryptedProfileInfo: encryptedProfileInfo,
        });
        if (postPaymentMethodsError) {
          setFetchPaymentMethodCallCount(prevCount => prevCount + 1);
        }
      }
    }
  }, [rallyId, authToken, encryptedProfileInfo, isPaymentMethodAdded]);

  useEffect(() => {
    if (fetchedPaymentMethodsData.length > 0) {
      setPrevPaymentMethods(fetchedPaymentMethodsData);
      updatePaymentMethodList(fetchedPaymentMethodsData);
      setIsPaymentMethodAdded(false);
      setIsSessionCheckoutError(false);

      const defaultPaymentMethod = fetchedPaymentMethodsData.find(payment => payment.default);
      const isPrevSelectedPaymentMethodExists =
        selectedPaymentMethod &&
        typeof selectedPaymentMethod === 'object' &&
        fetchedPaymentMethodsData.some(payment => payment.paymentMethodId === selectedPaymentMethod.paymentMethodId);
      if (isPrevSelectedPaymentMethodExists) {
        setSelectedPaymentMethod(selectedPaymentMethod);
        setDefaultPayment(selectedPaymentMethod);
        setIsDefault(true);
        if (defaultPaymentMethod) {
          setIsCardDefaultPayment(true);
          setIsHsaDefaultPayment(false);
        } else {
          setIsCardDefaultPayment(false);
          setIsHsaDefaultPayment(true);
        }
      } else if (defaultPaymentMethod) {
        setIsCardDefaultPayment(true);
        setIsHsaDefaultPayment(false);
        if (!hsaSelected) {
          setSelectedPaymentMethod(defaultPaymentMethod);
          setDefaultPayment(defaultPaymentMethod);
        }
      } else if (hsaAccount && hsaBalance && parseFloat(hsaBalance) >= minimumAmountAllowedForOnlinePayment) {
        setIsCardDefaultPayment(false);
        setIsHsaDefaultPayment(true);
        setSelectedPaymentMethod(HSA_METHOD);
        setDefaultPayment(HSA_METHOD);
      } else {
        setIsDefault(true);
        setIsCardDefaultPayment(false);
        setIsHsaDefaultPayment(false);
        setSelectedPaymentMethod(fetchedPaymentMethodsData[fetchedPaymentMethodsData.length - 1]);
        setDefaultPayment(fetchedPaymentMethodsData[fetchedPaymentMethodsData.length - 1]);
      }
    } else {
      if (
        !isPaymentMethodAdded &&
        prevPaymentMethods.length === 1 &&
        typeof selectedPaymentMethod === 'object' &&
        prevPaymentMethods.some(payment => payment.paymentMethodId === selectedPaymentMethod.paymentMethodId)
      ) {
        if (hsaAccount && hsaBalance && parseFloat(hsaBalance) >= minimumAmountAllowedForOnlinePayment) {
          setIsHsaDefaultPayment(true);
          setIsCardDefaultPayment(false);
          setDefaultPayment(HSA_METHOD);
          setSelectedPaymentMethod(HSA_METHOD);
        } else {
          setIsCardDefaultPayment(false);
          setIsHsaDefaultPayment(false);
          setSelectedPaymentMethod('');
          setDefaultPayment('');
        }
      } else if (
        hsaAccount &&
        hsaBalance &&
        parseFloat(hsaBalance) >= minimumAmountAllowedForOnlinePayment &&
        !(
          typeof selectedPaymentMethod === 'object' &&
          prevPaymentMethods.some(payment => payment.paymentMethodId === selectedPaymentMethod.paymentMethodId)
        )
      ) {
        setIsCardDefaultPayment(false);
        setIsHsaDefaultPayment(true);
        setSelectedPaymentMethod(HSA_METHOD);
        setDefaultPayment(HSA_METHOD);
      }
    }
  }, [fetchedPaymentMethodsData]);

  useEffect(() => {
    setIsDefault(isDefaultCardPresent ? isDefaultCardPresent : isDefault);
  }, [isDefaultCardPresent]);

  useEffect(() => {
    if (!allowChangePaymentMethod) {
      setIsPaymentSelectionOpen(false);
      setIsEditPayMethodEnabled(false);
    } else if ((!hsaAccount && fetchedPaymentMethodsData.length === 0) || (hsaAccount && insufficientHsaBalance)) {
      setIsPaymentSelectionOpen(true);
      setIsEditPayMethodEnabled(true);
    }
    toggleCollapse();
  }, [allowChangePaymentMethod, fetchedPaymentMethodsData, insufficientHsaBalance, hsaAccount]);

  const Header = (
    <HeaderContainer>
      <HeaderTitle>{`2. ${t('PAYMENT_METHOD')}`}</HeaderTitle>
      {allowChangePaymentMethod && !claimInfoPacketsError && (
        <ChangePaymentButton
          data-testid="edit-selection-button"
          data-ui-element-name="edit-selection-button"
          variant="tertiary"
          onClick={handleChangePaymentMethod}
        >
          <EditIcon icon={isPaymentSelectionOpen ? 'close' : 'mode'} variant="outlined" />
          {isPaymentSelectionOpen ? t('CLOSE') : t('CHANGE_METHOD')}
        </ChangePaymentButton>
      )}
    </HeaderContainer>
  );

  const fetchSelectedPaymentId = (paymentMethodId: string): void => {
    setIsEditPayMethodEnabled(false);
    setIsPaymentSelectionOpen(!isPaymentSelectionOpen);
    setIsDefault(true);
    if (paymentMethodId === HSA_METHOD) {
      setIsDefault(false);
      setHsaSelected(true);
      updatePaymentMethod(HSA_METHOD);
      setDefaultPayment(HSA_METHOD);
    } else {
      setShowHSAWarning(false);
      setHsaSelected(false);
      const paymentMethodList = fetchedPaymentMethodsData || [];
      const currentSelectedPaymentMethod = paymentMethodList.find(paymentData =>
        paymentData.paymentMethodId.includes(paymentMethodId),
      );
      currentSelectedPaymentMethod && setDefaultPayment(currentSelectedPaymentMethod);
      currentSelectedPaymentMethod && updatePaymentMethod(currentSelectedPaymentMethod);
    }
  };

  const onclickEventForHSAPaymentMethod = (): void => {
    if (insufficientHsaBalance) {
      setShowHSAWarning(true);
    }
  };

  return (
    <>
      <StyledCard header={Header} collapseOnClick={toggleCollapse}>
        <div style={isCollapsed ? { display: 'block' } : { display: 'none' }}>
          {!postPaymentMethodsError &&
            allowChangePaymentMethod &&
            !claimInfoPacketsError &&
            isPaymentSelectionOpen &&
            !isDefaultCardPresent &&
            !isHsaDefaultPayment &&
            !selectedPaymentMethod && (
              <StyledAlertWrapper>
                <Alert title={t('SELECT_ADD_PAYMENT_ERROR')} variant="error" />
              </StyledAlertWrapper>
            )}
          {allowChangePaymentMethod && !claimInfoPacketsError && postPaymentMethodsError && (
            <StyledAlertWrapper>
              <Alert title={t('PAYMENT_METHOD_LOADING_ERROR')} variant="error" />
            </StyledAlertWrapper>
          )}
          {allowChangePaymentMethod &&
            !claimInfoPacketsError &&
            insufficientHsaBalance &&
            (selectedPaymentMethod === HSA_METHOD || showHSAWarning) && (
              <StyledAlertWrapper>
                <Alert title={t('HSA_TOAST_TITLE')} variant="warning" />
              </StyledAlertWrapper>
            )}
          {allowChangePaymentMethod && !claimInfoPacketsError && !postPaymentMethodsError && isEditPayMethodEnabled ? (
            <SelectPaymentMethod
              data={data}
              handleCallBack={fetchSelectedPaymentId}
              onclickEventForHSAPaymentMethod={onclickEventForHSAPaymentMethod}
              isHsaDefaultPayment={isHsaDefaultPayment}
              insufficientHsaBalance={insufficientHsaBalance}
              fetchedPaymentMethodsData={fetchedPaymentMethodsData}
              setPaymentMethodAdditionError={setPaymentMethodAdditionError}
              postPaymentMethodsLoading={postPaymentMethodsLoading}
              defaultPayment={defaultPayment}
              isDefaultCardPresent={isDefaultCardPresent}
            />
          ) : (
            allowChangePaymentMethod &&
            !claimInfoPacketsError && (
              <StyledSection>
                {isDefault && typeof defaultPayment === 'object' && !postPaymentMethodsError ? (
                  <div data-testid={`${defaultPayment?.card?.cardBrand}-payment-card-box`}>
                    <PaymentMethodCard
                      cardBrand={defaultPayment?.card?.cardBrand}
                      lastFourCard={defaultPayment?.card?.last4}
                      expiryYear={defaultPayment?.card?.expiryYear}
                      paymentMethodId={defaultPayment?.paymentMethodId}
                      expiryMonth={defaultPayment?.card?.expiryMonth}
                      cardStatus={defaultPayment?.card?.status}
                      bankName={defaultPayment?.bankAccount?.bankName || ''}
                      lastFourBank={defaultPayment?.bankAccount?.last4}
                      nickname={defaultPayment?.nickname}
                      default={defaultPayment?.default}
                      showRadiobtn={false}
                      rallyId={rallyId}
                    />
                  </div>
                ) : (
                  hsaAccount &&
                  [hsaAccount].map((account, index) => {
                    const { accountType, accountSystemCode, balance } = account;
                    {
                      return (
                        <HsaPaymentMethodCard
                          account={account}
                          accountSystemCode={accountSystemCode}
                          accountType={accountType}
                          availableBalance={balance?.availableAmount}
                          onHSACardClick={onclickEventForHSAPaymentMethod}
                          selectedAccount={null}
                          key={index}
                          default={isHsaDefaultPayment}
                          insufficientHsaBalance={insufficientHsaBalance}
                          showRadio={false}
                          minimumAmountAllowedForOnlinePayment={minimumAmountAllowedForOnlinePayment}
                          isDefaultCardPresent={isDefaultCardPresent}
                        />
                      );
                    }
                  })
                )}
              </StyledSection>
            )
          )}
        </div>
      </StyledCard>
    </>
  );
};

const StyledCard = styled(Card, {
  '&.abyss-card-title': {
    backgroundColor: '$white',
    fontSize: '22px',
  },
  '&.abyss-button-root': {
    border: 'none',
  },
});

const HeaderContainer = styled('div', {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '@screen < $sm': {
    width: '100%',
    display: 'block',
    alignItems: 'center',
  },
});

const StyledSection = styled(Card.Section, {
  '&.abyss-card-section': {
    paddingTop: 'unset',
  },
});

const ChangePaymentButton = styled(Button, {
  '&.abyss-button-root': {
    padding: 'unset',
    height: 'unset',
    '@screen < $md': {
      margin: '$sm 0 $md 0',
    },
  },
  '&.abyss-button-content-container': { fontWeight: '$semibold', fontSize: '$md' },
  '&.abyss-icon-material': { marginLeft: '$sm' },
});

const HeaderTitle = styled('div', {
  '@screen < $md': {
    paddingTop: '$md',
  },
});

const StyledAlertWrapper = styled('div', {
  margin: '0 $lg $md $lg',
});

const EditIcon = styled(IconSymbol, { '&.abyss-icon-symbol': { marginRight: '$sm' } });
