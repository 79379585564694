import { useCallback, useEffect } from 'react';
import { handleMessageFactory, HandleMessage, InboundMessageEventData } from '@rally/rallypay-commons';
import { getConfig } from 'scripts/util/constants/config';

export default function useMessageHandler(handleMessage: HandleMessage<InboundMessageEventData>): void {
  const handleEvent = useCallback(
    handleMessageFactory(handleMessage, getConfig().RALLYPAY_WEB_WHITELISTED_ORIGINS.split(',')),
    [handleMessage],
  );

  useEffect(() => {
    window.addEventListener('message', handleEvent, false);
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, [handleEvent]);
}
