import React, { useReducer } from 'react';

import {
  postPaymentMethodsLoading as postPaymentMethodsLoadingAction,
  postPaymentMethodsError as postPaymentMethodsErrorAction,
  deletePaymentMethodsLoading as deletePaymentMethodsLoadingAction,
  deletePaymentMethodsError as deletePaymentMethodsErrorAction,
  postPaymentMethodsSuccess,
  deletePaymentMethodsSuccess,
} from 'scripts/actions/payment-methods-actions';
import {
  IDeletePaymentMethodsData,
  IPostPaymentMethodsData,
} from 'scripts/api/convenient-checkout/convenient-checkout.interfaces';
import { ConvenientCheckoutService } from 'scripts/api/convenient-checkout/convenient-checkout.service';
import paymentMethodsReducer, { IPaymentMethodsState, initialState } from 'scripts/reducers/payment-methods-reducer';

type DispatchActionPost = (postPaymentMethodsData: IPostPaymentMethodsData) => Promise<void>;

type DispatchActionDelete = (deletePaymentMethodsData: IDeletePaymentMethodsData) => Promise<void>;

export interface IPaymentMethodsContext extends IPaymentMethodsState {
  deletePaymentMethods: DispatchActionDelete;
  fetchPaymentMethods: DispatchActionPost;
}
export const PaymentMethodsContext = React.createContext<IPaymentMethodsContext | undefined>(undefined);

export interface IPaymentMethodsProvider {
  children: React.ReactNode;
}

export const PaymentMethodsProvider: React.FunctionComponent<IPaymentMethodsProvider> = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [
    {
      paymentMethodsData,
      postPaymentMethodsError,
      postPaymentMethodsLoading,
      deletePaymentMethodsError,
      deletePaymentMethodsLoading,
    },
    dispatch,
  ] = useReducer(paymentMethodsReducer, initialState);

  const fetchPaymentMethods: DispatchActionPost = async ({ rallyId, authToken, encryptedProfileInfo }) => {
    dispatch(postPaymentMethodsLoadingAction());
    try {
      const { data } = await ConvenientCheckoutService.postPaymentMethods({
        rallyId,
        authToken,
        encryptedProfileInfo,
      });
      dispatch(postPaymentMethodsSuccess(data));
    } catch {
      dispatch(postPaymentMethodsErrorAction());
    }
  };

  const deletePaymentMethods: DispatchActionDelete = async ({ rallyId, paymentMethodId }) => {
    dispatch(deletePaymentMethodsLoadingAction());
    try {
      const { status } = await ConvenientCheckoutService.deletePaymentMethod({
        rallyId,
        paymentMethodId,
      });

      if (status !== 204) {
        throw new Error('Delete payment method failed');
      }

      dispatch(deletePaymentMethodsSuccess({ paymentMethodId }));
    } catch {
      dispatch(deletePaymentMethodsErrorAction());
    }
  };

  return (
    <PaymentMethodsContext.Provider
      value={{
        paymentMethodsData,
        postPaymentMethodsError,
        postPaymentMethodsLoading,
        deletePaymentMethodsError,
        deletePaymentMethodsLoading,
        deletePaymentMethods,
        fetchPaymentMethods,
      }}
    >
      {children}
    </PaymentMethodsContext.Provider>
  );
};
