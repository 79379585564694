import {
  ConsentAction,
  GET_CONSENT_ERROR,
  GET_CONSENT_LOADING,
  GET_CONSENT_SUCCESS,
  SUBMIT_CONSENT_ERROR,
  SUBMIT_CONSENT_LOADING,
  SUBMIT_CONSENT_SUCCESS,
} from 'scripts/actions/consent-actions';
import { IPaymentsConsent } from 'scripts/api/payments/payments.interfaces';

export interface IConsentState {
  consentData?: IPaymentsConsent;
  getConsentLoading: boolean;
  getConsentError: boolean;
  submitConsentLoading: boolean;
  submitConsentError: boolean;
}
export const initialState: IConsentState = {
  consentData: undefined,
  getConsentLoading: false,
  getConsentError: false,
  submitConsentLoading: false,
  submitConsentError: false,
};

const consentReducer = (state: IConsentState, action: ConsentAction): IConsentState => {
  switch (action.type) {
    case SUBMIT_CONSENT_LOADING: {
      return {
        ...state,
        submitConsentLoading: true,
        submitConsentError: false,
      };
    }
    case SUBMIT_CONSENT_ERROR: {
      return {
        ...state,
        submitConsentLoading: false,
        submitConsentError: true,
      };
    }
    case SUBMIT_CONSENT_SUCCESS: {
      return {
        ...state,
        consentData: action.payload,
        submitConsentLoading: false,
        submitConsentError: false,
      };
    }
    case GET_CONSENT_LOADING:
      return {
        ...state,
        getConsentLoading: true,
        getConsentError: false,
        consentData: undefined,
      };
    case GET_CONSENT_ERROR:
      return {
        ...state,
        getConsentLoading: false,
        getConsentError: true,
        consentData: undefined,
      };
    case GET_CONSENT_SUCCESS:
      return {
        ...state,
        getConsentLoading: false,
        getConsentError: false,
        consentData: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default consentReducer;
