import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useAppState } from 'scripts/hooks/use-app-state/use-app-state';
import { PayAnywayDispatch } from 'scripts/state';
import { fetchCheckoutSession, ICheckoutSessionState, selectCheckoutSession } from 'scripts/state/checkoutSession';

export function useCheckoutSession(): ICheckoutSessionState {
  const dispatch = useDispatch<PayAnywayDispatch>();

  const { initialData } = useAppState();
  const { rallyId, token: authToken, encryptedProfileInfo } = initialData;
  const session = useSelector(selectCheckoutSession);

  // Fetch a new session on load
  useEffect(() => {
    if (!authToken || !encryptedProfileInfo) return;
    dispatch(fetchCheckoutSession({ rallyId, authToken, encryptedProfileInfo }));
  }, []);

  return session;
}
