import { IPaymentsConsent } from 'scripts/api/payments/payments.interfaces';

export declare enum ConsentFormErrorType {
  DEFAULT = 'ERROR_DEFAULT',
}

export const GET_CONSENT_LOADING = 'GET_CONSENT_LOADING';
export const GET_CONSENT_ERROR = 'GET_CONSENT_ERROR';
export const GET_CONSENT_SUCCESS = 'GET_CONSENT_SUCCESS';
export const SUBMIT_CONSENT_LOADING = 'SUBMIT_CONSENT_LOADING';
export const SUBMIT_CONSENT_SUCCESS = 'SUBMIT_CONSENT_SUCCESS';
export const SUBMIT_CONSENT_ERROR = 'SUBMIT_CONSENT_ERROR';

export interface IGetConsentLoading {
  type: typeof GET_CONSENT_LOADING;
}

export interface IGetConsentError {
  type: typeof GET_CONSENT_ERROR;
}

export interface IGetConsentSuccess {
  type: typeof GET_CONSENT_SUCCESS;
  payload: IPaymentsConsent;
}

export interface ISubmitConsentLoading {
  type: typeof SUBMIT_CONSENT_LOADING;
}
export interface ISubmitConsentError {
  type: typeof SUBMIT_CONSENT_ERROR;
}

export interface ISubmitConsentSuccess {
  payload: IPaymentsConsent;
  type: typeof SUBMIT_CONSENT_SUCCESS;
}

export function getConsentLoading(): IGetConsentLoading {
  return {
    type: GET_CONSENT_LOADING,
  };
}

export function getConsentError(): IGetConsentError {
  return {
    type: GET_CONSENT_ERROR,
  };
}

export function getConsentSuccess(payload: IPaymentsConsent): IGetConsentSuccess {
  return {
    type: GET_CONSENT_SUCCESS,
    payload,
  };
}
export function submitConsentLoading(): ISubmitConsentLoading {
  return {
    type: SUBMIT_CONSENT_LOADING,
  };
}
export function submitConsentError(): ISubmitConsentError {
  return {
    type: SUBMIT_CONSENT_ERROR,
  };
}

export function submitConsentSuccess(payload: IPaymentsConsent): ISubmitConsentSuccess {
  return {
    type: SUBMIT_CONSENT_SUCCESS,
    payload,
  };
}

export type ConsentAction =
  | ISubmitConsentLoading
  | ISubmitConsentError
  | ISubmitConsentSuccess
  | IGetConsentLoading
  | IGetConsentError
  | IGetConsentSuccess;
