import React from 'react';
import { useConfirmData } from 'scripts/hooks/use-confirm-data/use-confirm-data';
import { usePaymentMethods } from 'scripts/hooks/use-payment-methods/use-payment-methods';
import { ConfirmationView } from './confirmation-view';
import { useAdobeImpressionTracking } from 'scripts/util/analytics/useAdobeImpressionTracking';

export interface ICardConfirmation {
  paymentMethodId: string;
}

const CardConfirmation: React.FunctionComponent<ICardConfirmation> = ({ paymentMethodId }) => {
  const { amount, confirmationNumber, providerName, onDone } = useConfirmData(true) || {};
  const { paymentMethodsData } = usePaymentMethods();
  const fetchedPaymentMethodsData = paymentMethodsData?.paymentMethods || [];
  const paymentMethod = fetchedPaymentMethodsData.filter(item => item.paymentMethodId === paymentMethodId)?.[0];

  if (!amount) {
    return null;
  }

  const { cardBrand, last4 } = paymentMethod?.card || {};
  const methodDisplayName = cardBrand?.charAt(0) + cardBrand?.slice(1).toLocaleLowerCase() + ' *' + last4;

  useAdobeImpressionTracking('payment method', cardBrand, true);

  return (
    <ConfirmationView
      timeStamp=""
      methodDisplayName={methodDisplayName}
      paymentAmount={amount}
      confirmationNumber={confirmationNumber}
      providerName={providerName}
      isCardPayment={true}
      onDone={onDone}
    />
  );
};

export default CardConfirmation;
