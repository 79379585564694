import { css, keyframes } from '@emotion/react';
import { Style } from 'scripts/styles/themes/themes.interfaces';
import * as designSystemRally from '@rally/ui-themes/dist/web/rally/tokens.es6';
import defaultTheme from 'scripts/styles/themes/default';

const pulse = keyframes`
  0% {
    background-color: ${designSystemRally.RDS_COLOR_NEUTRAL_80};
    opacity: 0.5;
  }

  20% {
    background-color: ${defaultTheme.colors.uhcBlue};
    opacity: 1;
  }

  40% {
    background-color: ${designSystemRally.RDS_COLOR_NEUTRAL_80};
    opacity: 0.5;
  }

  100% {
    background-color: ${designSystemRally.RDS_COLOR_NEUTRAL_80};
    opacity: 0.5;
  }
`;

const loadingDotsStyles: Style = () => css`
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 24px 0;

  .loading-dot {
    animation: ${pulse} 2.1s infinite;
    animation-fill-mode: both;
    border-radius: 50%;
    font-size: 0 !important;
  }

  .loading-dot:first-of-type {
    animation-delay: 0;
  }

  .loading-dot:first-of-type + .loading-dot {
    animation-delay: 0.7s;
    margin-left: 10px;
  }

  .loading-dot:first-of-type + .loading-dot + .loading-dot {
    animation-delay: 1.4s;
    margin-left: 10px;
  }
`;

export default loadingDotsStyles;
