/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@abyss/web/tools/styled';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { useHasReachedBottom } from 'scripts/hooks/use-has-reached-bottom/use-has-reached-bottom';

export interface IACHAgreement {
  isACHAgreementAccepted: boolean;
  setIsACHAgreementAccepted: React.Dispatch<React.SetStateAction<boolean>>;
  showACHAgreementError: boolean;
  setShowACHAgreementError: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ACHAgreement: FunctionComponent<IACHAgreement> = ({
  isACHAgreementAccepted,
  setIsACHAgreementAccepted,
  showACHAgreementError,
  setShowACHAgreementError,
}) => {
  const { t } = useTranslation();
  const [componentRef, hasReachedBottom] = useHasReachedBottom();

  const toggleCheckbox = (): void => {
    setIsACHAgreementAccepted(!isACHAgreementAccepted);
    setShowACHAgreementError(isACHAgreementAccepted);
  };

  return (
    <Container>
      <div className="agreement-label">{t('ACH-AGREEMENT-LABEL')}</div>
      <div className="agreement-container" ref={componentRef}>
        <div className="agreement-content-top">{t('ACH-AGREEMENT-ONE')}</div>
        <div className="agreement-content">{t('ACH-AGREEMENT-TWO')}</div>
      </div>
      <StyledCheckbox
        data-testid={'ach-agreement-checkbox'}
        label={t('ACH-CHECKBOX-LABEL') as string | undefined}
        isChecked={isACHAgreementAccepted}
        onChange={toggleCheckbox}
        isDisabled={!hasReachedBottom}
        errorMessage={showACHAgreementError && t('SUBMIT-WITHOUT-AGREEMENT-ERROR-MESSAGE')}
      />
    </Container>
  );
};

const Container = styled('div', {
  '.agreement-label': {
    marginTop: '16px',
    fonzSize: '16px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '20px',
  },
  '.agreement-container': {
    borderRadius: '4px',
    overflowY: 'scroll',
    border: '1px solid #808080',
    marginTop: '12px',
    marginBottom: '4px',
    padding: '8px 16px',
    width: 'auto',
    maxWidth: '320px',
    height: '144px',
  },
  '.agreement-content': {
    color: '#000000',
    fontFamily: 'UHC Sans, Helvetica, Arial, sans-serif',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
  },
  '.agreement-content-top': {
    color: '#000000',
    fontFamily: 'UHC Sans, Helvetica, Arial, sans-serif',
    fontSize: '1rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    marginBottom: '20px',
  },
});

const StyledCheckbox = styled(Checkbox, {
  '.abyss-checkbox-flex-container': {
    width: 'auto',
    height: '64px',
    maxWidth: '320px',
    display: 'flex',
    alignItems: 'center',
  },
  '&.abyss-checkbox-label': {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    width: '280px',
  },
});
