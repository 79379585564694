import React from 'react';
import {
  IPaymentMethodsContext,
  PaymentMethodsContext,
} from 'scripts/contexts/payment-methods-context/payment-methods-context';

export function usePaymentMethods(): IPaymentMethodsContext {
  const context = React.useContext(PaymentMethodsContext);
  if (context === undefined) {
    throw new Error('usePaymentMethods must be used within a PaymentMethodsProvider');
  }
  return context;
}
