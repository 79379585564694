/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useConfirmData } from 'scripts/hooks/use-confirm-data/use-confirm-data';
import { ConfirmationView } from './confirmation-view';
import { useAdobeImpressionTracking } from 'scripts/util/analytics/useAdobeImpressionTracking';

const ConfirmationScreen: FunctionComponent = () => {
  const { t } = useTranslation();
  useAdobeImpressionTracking('payment method', 'HSA', true);
  const { amount, confirmationNumber, timeStamp = '', providerName, onDone } = useConfirmData(false) || {};

  if (!amount) {
    return null;
  }

  return (
    <ConfirmationView
      timeStamp={timeStamp}
      methodDisplayName={t('HSA_TITLE')}
      confirmationNumber={confirmationNumber}
      providerName={providerName}
      onDone={onDone}
      isCardPayment={false}
      paymentAmount={amount}
    />
  );
};

export default ConfirmationScreen;
