import {
  ICurrencyAmount,
  CardBrand,
  PaymentCardStatus,
  IPaymentMethodsItem,
  IClaimsInfoPacket,
  ICreatePayment,
  IPaymentsHSA,
} from '@rally/rallypay-commons';
import { i18n } from 'i18next';

export enum ClaimType {
  All = 'ALL',
  Dental = 'DENTAL',
  Medical = 'MEDICAL',
  Rx = 'RX',
  Vision = 'VISION',
}

export enum AccountType {
  // Commuter Expense Reimbursement Account
  CERA = 'CERA',
  // FSA account types
  FSADC = 'FSADC',
  FSAHC = 'FSAHC',
  FSALP = 'FSALP',
  // HRA account types
  HRA = 'HRA',
  HRABC = 'HRABC',
  HRAEC = 'HRAEC',
  HRAG = 'HRAG',
  HRAAP = 'HRAAP',
  HRAPD = 'HRAPD',
  HRASD = 'HRASD',
  // HSA account types
  HSA = 'HSA',
  // JPMC account types
  DCSA = 'DCSA',
  HCSA = 'HCSA',
  MRA = 'MRA',
  // Optum managed account types
  HIA = 'HIA',
  RMSA = 'RMSA',
  PRA = 'PRA',
}

export enum ServiceTypes {
  DENTAL = 'Dental',
  DEPENDENT_CARE = 'Dependent Care',
  OVER_THE_COUNTER = 'Over the Counter',
  HEARING = 'Hearing',
  MEDICAL = 'Medical',
  PHARMACY = 'Pharmacy',
  VISION = 'Vision',
}

export enum ClaimStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  InProcess = 'IN_PROCESS',
  PartiallyDenied = 'PARTIALLY_DENIED',
  Processed = 'PROCESSED',
  ClosedMemberActionRequired = 'CLOSED_MEMBER_ACTION_REQUIRED',
  ClosedNoActionRequired = 'CLOSED_NO_ACTION_REQUIRED',
  Submitted = 'SUBMITTED',
}

export enum ClaimVideoEligibility {
  Eligible = 'ELIGIBLE',
  FirstEligible = 'FIRST_ELIGIBLE',
  NotEligible = 'NOT_ELIGIBLE',
  Unknown = 'UNKNOWN',
}

/*
    This enum defines possible values for url params used by explanation modals
  */
export enum ClaimBalanceType {
  AmountBilled = 'AMOUNT_BILLED',
  DifferenceFromAmountBilled = 'DIFFERENCE_FROM_AMOUNT_BILLED',
  PlanDiscount = 'PLAN_DISCOUNT',
  PlanPaid = 'PLAN_PAID',
  YourTotalAmountOwed = 'YOUR_TOTAL_AMOUNT_OWED',
  YouPaid = 'YOU_PAID',
  AmountSubmitted = 'AMOUNT_SUBMITTED',
  AmountPaid = 'AMOUNT_PAID',
  PendingTerms = 'AMOUNT_PENDING',
}

export enum ClaimPaymentType {
  Check = 'CHECK',
  Online = 'ONLINE',
  SpendingAccount = 'SPENDING_ACCOUNT',
  Unknown = 'UNKNOWN',
}

export enum ClaimDocumentType {
  EOB = 'EOB',
  FsaForm = 'FSAFORM',
  HraForm = 'HRAFORM',
  Unknown = 'UNKNOWN',
}

export enum ClaimDisposition {
  Original = 'ORIGINAL',
  Reversal = 'REVERSAL',
  Refund = 'REFUND',
  BulkRecovery = 'BULK_RECOVERY',
  Unknown = 'UNKNOWN',
}

export enum ClaimTransactionType {
  ChargeBack = 'CHARGEBACK',
  Refund = 'REFUND',
  Return = 'RETURN',
  Sale = 'SALE',
  Unknown = 'UNKNOWN',
}

export enum ServiceType {
  ChildCare = 'CHILD_CARE',
  Dental = 'DENTAL',
  DependentCare = 'DEPENDENT_CARE',
  Expense = 'EXPENSE',
  Institutional = 'INSTITUTIONAL',
  Interest = 'INTEREST',
  Medical = 'MEDICAL',
  Pharmacy = 'PHARMACY',
  Vision = 'VISION',
  Unknown = 'UNKNOWN',
}

export enum ClaimServiceType {
  Hospital = 'Hospital',
  Medical = 'Medical',
  Other = 'Other',
  Unknown = 'UNKNOWN',
}

export enum ClaimNetworkStatusType {
  InNetwork = 'IN_NETWORK',
  OutOfNetwork = 'OUT_OF_NETWORK',
  Partial = 'PARTIAL',
  Unknown = 'UNKNOWN',
}

export enum NaviguardActionType {
  Negotiate = 'NEGOTIATE',
  Advocate = 'ADVOCATE',
  Unknown = 'UNKNOWN',
}

export enum TelephoneType {
  Mobile = 'Mobile',
  Home = 'Home',
  Work = 'Work',
  Global = 'Global',
}
export interface IFinancialClaim {
  balance: IFinancialClaimBalance;
  claimKey?: string;
  claimNetworkStatus?: ClaimNetworkStatusType;
  claimNumber: string;
  claimReference?: string;
  claimStatus: ClaimStatus;
  accountType: AccountType;
  lastServiceDate: string;
  processedDate: string;
  providerName?: string;
  serviceDate: string;
  serviceRecipient: IServiceRecipient;
  // Generated on FE
  uuid: string;
}

export interface IClawbackIndicators {
  isClawbackClaim: boolean;
  isClawbackReversal: boolean;
}

export interface IDescriptionCode {
  description?: string;
  code?: string;
}

export interface IClaimPaymentType {
  checkIssueDate?: string;
  confirmationNumber?: string;
  draftNumber: string;
  payeeType?: IDescriptionCode;
  payment?: ICurrencyAmount;
  paymentType?: string;
  postedDate?: string;
  transactionType?: ClaimTransactionType;
}

export interface IDeniedClaimInfo {
  explanation: string;
  category: DeniedClaimCategory;
  cta: string;
}

export enum DeniedClaimCategory {
  OutOfNetwork = 'OUT_OF_NETWORK',
  Eligibility = 'ELIGIBILITY',
  MissingInformation = 'MISSING_INFORMATION',
  // @note: Below not supported for new CST?
  DDPDenied = 'DDP_DENIED', // DDP - Designated Diagnostic Provider
  DDPWarning = 'DDP_WARNING', // DDP - Designated Diagnostic Provider
}

export enum CoverageTypeCode {
  // ThirdPartyCarveOutRx
  CRX = 'CRX',
  Dental = 'D',
  EhbDental = 'DS',
  HospitalIndemnity = 'HIP',
  HospitalIndemnityRider = 'HIPRIDER',
  MA = 'MA',
  MAPD = 'MAPD',
  Medical = 'M',
  MedicareSupplement = 'MEDSUPP',
  MedicareSupplementRider = 'MEDSUPPRIDER',
  // CarveOutRx
  ORX = 'ORX',
  PDP = 'PDP',
  PHIP = 'PHIP',
  SSP = 'SSP',
  Unknown = 'UNKNOWN',
  LI = 'LI',
  AD = 'AD',
}

export interface IClaimManagementInfo {
  claimNote: boolean;
  isMerp: boolean;
  isSaved: boolean;
  markPaid: boolean;
  youOwe?: boolean;
}

export interface IClaim {
  balance: IClaimBalance;
  claimDisposition?: ClaimDisposition;
  claimId: string;
  claimIdSuffix?: string;
  claimKey?: string;
  claimManagementInfo: IClaimManagementInfo;
  claimNetworkStatus?: ClaimNetworkStatusType;
  claimReference?: string;
  claimServiceType?: ClaimServiceType;
  claimStatus: ClaimStatus;
  claimType: ClaimType;
  claimVideoEligibility?: ClaimVideoEligibility;
  clawbackIndicators: IClawbackIndicators;
  claimPayKey: string;
  claimPaymentTypes?: IClaimPaymentType[];
  deniedClaimInfo?: IDeniedClaimInfo[];
  claimCoverageTypeDesc: CoverageTypeCode;
  claimMatchId?: string;
  datePaid?: string;
  draftNumber?: string;
  eobDocument?: string;
  fieldLabelIndicator?: string;
  fieldLabelText?: string;
  isEmergencyClaim?: boolean;
  lastServiceDate: string;
  listOfTransactionInformation: ITransactionInformation[];
  medicationName?: string;
  naviguardActionType?: NaviguardActionType;
  prescriptionNumber?: string;
  processedDate?: string;
  providerId?: IProviderId;
  providerName?: string;
  providerHash?: string;
  serviceDate: string;
  serviceRecipient: IServiceRecipient;
  // Generated on FE
  uuid: string;
  isPayable?: boolean;
  claimHistory?: IClaimHistory;
}

export interface ITransactionInformation {
  confirmationNumber: string;
  fundingType: TransactionFundingType;
  transactionId?: string;
  optumId?: string;
  transactionStatus?: TransactionStatus;
}

export enum TransactionFundingType {
  RallyPay = 'RALLYPAY',
}

export enum TransactionStatus {
  scheduled = 'Scheduled',
  refunded = 'Refunded',
  canceled = 'Canceled',
  rejected = 'Rejected',
  processed = 'Processed',
}

export enum ProviderIdType {
  mpin = 'mpin',
  npi = 'npi',
}

export interface IFullName {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  suffix?: string;
}

export interface IProviderId {
  providerIdType: ProviderIdType;
  providerIdValue: string;
  tin?: string;
}

export interface IServiceRecipient extends IFullName {
  dependentSeqNbr: string;
  suppressDocs: string;
}

export interface IClaimHistory {
  claimHistory: {
    claims: IClaim[];
  };
}

export type IAnyClaim = IClaim | IFinancialClaim;

export interface IClaimBalance {
  copay?: ICurrencyAmount;
  deductible?: ICurrencyAmount;
  healthPlanDiscount: ICurrencyAmount;
  healthPlanPays?: ICurrencyAmount;
  medicarePaidAmount?: ICurrencyAmount;
  onlinePaidAmount?: ICurrencyAmount;
  other?: ICurrencyAmount;
  patientPaidAmt?: ICurrencyAmount;
  patientResponsibility?: ICurrencyAmount;
  planPaidAmount?: ICurrencyAmount;
  totalAmountPaidToEmployee?: ICurrencyAmount;
  totalBilledAmount: ICurrencyAmount;
  totalContractAmount?: ICurrencyAmount;
  totalMemberPayments?: ICurrencyAmount;
  youMayOweAmount?: ICurrencyAmount;
}

export interface IHealthcareClaimDetailsBalance {
  claimAdjustmentAmount?: ICurrencyAmount;
  claimCoinsurance?: ICurrencyAmount;
  claimMemberNotCovered?: ICurrencyAmount;
  claimTotalNotCovered?: ICurrencyAmount;
  copay?: ICurrencyAmount;
  deductible?: ICurrencyAmount;
  fsaPaidToProvider?: ICurrencyAmount;
  fsaPaidToYou?: ICurrencyAmount;
  healthPlanDiscount: ICurrencyAmount;
  healthPlanPay: ICurrencyAmount;
  hraPaidToProvider?: ICurrencyAmount;
  hraPaidToYou?: ICurrencyAmount;
  medicarePaid?: ICurrencyAmount;
  otherInsurancePaid?: ICurrencyAmount;
  other?: ICurrencyAmount;
  patientPaidAmt?: ICurrencyAmount;
  patientResponsibility: ICurrencyAmount;
  planPaidAmount?: ICurrencyAmount;
  totalAmountPaidToEmployee?: ICurrencyAmount;
  totalBilledAmount: ICurrencyAmount;
  totalContractAmount?: ICurrencyAmount;
  youMayOweAmount?: ICurrencyAmount;
}

export interface IFinancialClaimBalance {
  amountSubmitted: ICurrencyAmount;
  amountPaid: ICurrencyAmount;
  amountPending: ICurrencyAmount;
  paidWithDebitCard: boolean;
}

export interface IPaymentCard {
  cardBrand: CardBrand;
  expiryMonth: number;
  expiryYear: number;
  last4Digits: string;
  manufacturerCard: boolean;
  nameOnCard: string;
  status: PaymentCardStatus;
  zipCode: string;
}
export interface IPaymentBank {
  accountHolderType: string;
  accountType: string;
  bankName: string;
  last4Digits: string;
  nameOnAccount: string;
  routingNumber: string;
  status: string;
}

export enum AccountSystemCode {
  CAMS = 'CAMS',
  OptumBank = 'OPTUM_BANK',
  WEX = 'WEX',
}

export interface IPaidCardInfo {
  claimIndex: number;
  name: string;
  dateOfService: string;
  confirmation: string;
  patientName: string;
  payment: number;
}

export interface IUserDetails {
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export interface IClaimInfoItem {
  claimId?: string;
  claimType: ClaimType;
  claimKey: string;
  claimAmount: ICurrencyAmount;
  youMayOweAmount?: ICurrencyAmount;
  providerName?: string;
  hashedTin?: string;
}

export type IClaimInfoItemV2 = IClaimInfoItem & {
  hashedTin?: string;
};

export interface IAccountInfoItem {
  accountId: string;
  accountType: AccountType;
  availableBalance: ICurrencyAmount;
  accountSystemCode: AccountSystemCode;
}

export interface ILedgerAccount {
  accessPointInformation?: IAccessPointInfo;
  accountNumber?: string;
  accountPeriod?: IAccountPeriod;
  accountSystemCode?: AccountSystemCode;
  accountType: AccountType;
  balance?: ILedgerAccountBalance;
  isActive: boolean;
  rallypayLedgerData?: string;
}

export interface IAccountPeriod {
  startDate: string;
  endDate?: string;
}

export interface ILedgerAccountBalance {
  availableAmount?: ICurrencyAmount;
  currentAmount: ICurrencyAmount;
  paidAmount?: ICurrencyAmount;
  investmentAmount?: ICurrencyAmount;
  totalBalance?: ICurrencyAmount;
  accountFunding?: ILedgerAccountFunding;
  carryOverAmount?: ICurrencyAmount;
}

export interface IAccessPointInfo {
  familyAccessPointInformation: IFamilyAccessPointInfo;
  individualAccessPointInformation: IIndividualAccessPointInfo;
}

export interface IFamilyAccessPointInfo {
  familyAccessPoint: string;
  familyAccessPointAmountApplied: string;
  hraAccessibleByFamily: boolean;
}

export interface IIndividualAccessPointInfo {
  individualAccessPoint: string;
  individualMaxReimbursementAmount?: string;
  individualAccessPointAccumulators: IIndividualAccessPointAccumulator[];
}

export interface IIndividualAccessPointAccumulator extends IFullName {
  individualAccessPointApplied: string;
  individualMaxReimbursementAmountUsed?: string;
  hraAccessibleByIndividual: boolean;
}

export interface ILedgerAccountFunding {
  annualElections?: ICurrencyAmount;
  employeeContribution?: ICurrencyAmount;
  employerContribution?: ICurrencyAmount;
  employerContributionIndicator?: boolean;
  beginningAmount?: ICurrencyAmount;
  employerContributionLessIncentiveAmount?: ICurrencyAmount;
  hraIncentiveAmount?: ICurrencyAmount;
}

export enum SourceType {
  DEFAULT = 'DEFAULT',
  CYC = 'CYC',
}

export interface IHsaStatus {
  isEligible: boolean;
  isActive: boolean;
}
export interface IAccountsSummary {
  accounts: ILedgerAccount[];
  sourceType: SourceType;
  hsaStatus?: IHsaStatus;
}

export interface IMultipayResponseMessage {
  type: string;
  code: string;
  title: string;
  message?: string;
}

export interface IMultiClaimPaymentsHSA {
  trackingId: string;
  unpaidClaims: IUnpaidClaimPaymentHSA[];
  paidClaims: IPaidClaimPaymentHSA[];
}

export interface IUnpaidClaimPaymentHSA {
  claimKey: string;
  failureReason: string;
  timestamp: string;
  transactionId: string;
}

export interface IPaidClaimPaymentHSA {
  claimKey: string;
  data: IPaymentsHSA;
}

export interface IUpdatedRallyPayData {
  claimKey: string;
  paymentAmount: ICurrencyAmount;
  rallyPayData: string;
}

export interface IMultipayDefaultProps {
  i18n?: i18n;
  multipayFeatureFlag: boolean;
  initialData: {
    multipay: boolean;
    rallyId: string;
    authToken: string;
    encryptedProfileInfo: string;
    setSelectedPayment(paymentMethod: IPaymentMethodsItem | string): void;
    confirmSelectionFlag: boolean;
    setResponseMessage(responseMessage: IMultipayResponseMessage | undefined): void;
    claimInfoPackets: (IClaimsInfoPacket | undefined)[];
    paymentSuccessCallback: (response: ICreatePayment) => void;
    hsaPaymentSuccessCallback: (response: IMultiClaimPaymentsHSA) => void;
    paymentFailureCallback: (response: string | { statusCode: number } | boolean) => void;
    hsaAccount: ILedgerAccount;
    isSessionCheckoutError: boolean;
    setIsSessionCheckoutError(isSessionCheckoutError: boolean): void;
    isPaymentMethodAdded: boolean;
    setIsPaymentMethodAdded(isPaymentMethodAdded: boolean): void;
    getUpdatedRallyPayDataHsa: (data: IUpdatedRallyPayData[]) => void;
    refreshToken: string;
    subTotalPartial: string;
    minimumAmountAllowedForOnlinePayment: number;
    claimInfoPacketsError: string | undefined;
    getClaimsInfoToken: () => void;
  };
}

export const HSA_METHOD: string = 'hsa';
