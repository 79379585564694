import React from 'react';
import { ClickTrackingEventType, MessageType } from '@rally/rallypay-commons';

export interface IClickTrackingProps<C extends HTMLElement> {
  onClick?: (e: React.MouseEvent<C>) => void;
  trackingName: string;
  trackingSection: string;
  href?: string;
}

export default function withClickTracking<T, C extends HTMLElement>(
  WrappedComponent: React.ComponentType<T>,
): React.ComponentType<T & IClickTrackingProps<C>> {
  const WithClickTrackingComponent: React.FunctionComponent<T & IClickTrackingProps<C>> = ({
    onClick,
    trackingName,
    trackingSection,
    ...props
  }) => {
    const { href } = props;

    const handleClick = (e: React.MouseEvent<C>): void => {
      if (!window.parent) return;

      const clickEvent: ClickTrackingEventType = {
        type: MessageType.ClickTracking,
        payload: {
          url: href,
          name: trackingName,
          section: trackingSection,
        },
      };

      window.parent.postMessage(clickEvent, '*');
      onClick?.(e);
    };

    return <WrappedComponent onClick={handleClick} {...(props as T)} />;
  };

  return WithClickTrackingComponent;
}
