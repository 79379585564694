import { useReducer } from 'react';
import { submitACHPayment, submitACHPaymentFailure, submitACHPaymentSuccess } from 'scripts/actions/ach-payment-action';
import achPaymentReducer, { initialState, IACHPaymentFormState } from 'scripts/reducers/ach-payment-reducer';
import { ICreatePaymentData } from 'scripts/api/convenient-checkout/convenient-checkout.interfaces';
import { ConvenientCheckoutService } from 'scripts/api/convenient-checkout/convenient-checkout.service';
import { CreatePaymentStatus, PaymentFormErrorType } from '@rally/rallypay-commons';
import { AxiosError } from 'axios';
import { getPaymentFormErrorCode } from 'scripts/util/payment-form/payment-form';

type onSubmitType = (cardPaymentData: ICreatePaymentData) => Promise<void>;
type axiosErrorResponseData = { status: number | PaymentFormErrorType };

export default function useACHPaymentSubmitter(): [IACHPaymentFormState, onSubmitType] {
  const [{ data, attempt, loading, error, errorResponse }, dispatch] = useReducer(achPaymentReducer, initialState);
  const onSubmitACHPayment: onSubmitType = async (paymentData: ICreatePaymentData) => {
    dispatch(submitACHPayment());
    try {
      const response = await ConvenientCheckoutService.submitACHPayment(paymentData);
      const { status } = response?.data || '';
      if (status === CreatePaymentStatus.COMPLETED || status === CreatePaymentStatus.PROCESSING) {
        dispatch(submitACHPaymentSuccess(response.data));
      }
    } catch (err) {
      const axError = err as AxiosError;
      const axErrorResponse = (axError?.response?.data as axiosErrorResponseData)?.status;
      dispatch(submitACHPaymentFailure(getPaymentFormErrorCode(axErrorResponse)));
    }
  };

  return [{ attempt, data, loading, error, errorResponse }, onSubmitACHPayment];
}
