import { css, Theme } from '@emotion/react';
import { Style } from 'scripts/styles/themes/themes.interfaces';

export const strong: Style = (theme: Theme) => css`
  font-weight: ${theme.p.fontWeight};
`;

export const uppercase: Style = css`
  text-transform: uppercase;
`;

export const capitalize: Style = css`
  text-transform: capitalize;
`;

export const positionMiddleRow: Style = (theme: Theme) => css`
  align-items: center;
  display: flex;
  height: ${theme.row.height};
  margin-left: 0;
  margin-top: 0;
`;

export const errorMessage: Style = ({ error }: Theme) => css`
  font-size: ${error.fontSize};
  text-align: ${error.textAlign};
  padding: ${error.padding};
`;

export const pos: Style = ({ p }: Theme) => css`
  font-size: ${p.fontSize};
  margin: 20px auto;
  max-width: 750px;
  text-align: center;
`;

export const useAnotherAccount: Style = ({ useAnotherAccount }: Theme) => css`
  font-size: ${useAnotherAccount.fontSize};
  text-align: ${useAnotherAccount.textAlign};
`;
