import React, { useReducer } from 'react';

import {
  getConsentLoading as getConsentLoadingAction,
  getConsentError as getConsentErrorAction,
  getConsentSuccess,
  submitConsentLoading as submitConsentLoadingAction,
  submitConsentError as submitConsentErrorAction,
  submitConsentSuccess,
} from 'scripts/actions/consent-actions';
import { IConsentData, ISubmitConsentData } from 'scripts/api/payments/payments.interfaces';
import { PaymentsService } from 'scripts/api/payments/payments.service';
import formReducer, { IConsentState, initialState } from 'scripts/reducers/consent-reducer';

type DispatchAction = (consentData: IConsentData) => Promise<void>;
type DispatchSubmitAction = (submitConsentData: ISubmitConsentData) => Promise<void>;

export interface IConsentContext extends IConsentState {
  submitConsent: DispatchSubmitAction;
  fetchConsent: DispatchAction;
}
export const ConsentContext = React.createContext<IConsentContext | undefined>(undefined);

export interface IConsentProvider {
  children: React.ReactNode;
}

export const ConsentProvider: React.FunctionComponent<IConsentProvider> = ({
  children,
}: {
  children?: React.ReactNode;
}) => {
  const [{ consentData, getConsentError, getConsentLoading, submitConsentError, submitConsentLoading }, dispatch] =
    useReducer(formReducer, initialState);

  const fetchConsent: DispatchAction = async ({ rallyId }) => {
    if (!rallyId) {
      return;
    }
    dispatch(getConsentLoadingAction());
    try {
      const { data } = await PaymentsService.getConsent({ rallyId });
      dispatch(getConsentSuccess(data));
    } catch {
      dispatch(getConsentErrorAction());
    }
  };

  const submitConsent: DispatchSubmitAction = async ({ rallyId, authToken }) => {
    dispatch(submitConsentLoadingAction());
    try {
      await PaymentsService.submitConsent({ rallyId, authToken });
      // manually setting payload because only response is 204, no data
      dispatch(submitConsentSuccess({ accepted: true }));
    } catch (error) {
      dispatch(submitConsentErrorAction());
    }
  };

  return (
    <ConsentContext.Provider
      value={{
        consentData,
        getConsentError,
        getConsentLoading,
        submitConsentError,
        submitConsentLoading,
        submitConsent,
        fetchConsent,
      }}
    >
      {children}
    </ConsentContext.Provider>
  );
};
