/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FunctionComponent, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AlertIcon from 'images/icons/icon-alert.svg';
import addCardErrorStyles from 'scripts/styles/add-card-error';
import { RdsButton } from '@rally/energon-react';
import { AppContext } from 'scripts/contexts/app-context/app-context';

const AddCardError: FunctionComponent = () => {
  const { t } = useTranslation();
  const { setIsAddCardScreenOpen, setShowAddCardScreenError } = useContext(AppContext);

  const handleClick = (): void => {
    setShowAddCardScreenError(false);
    setIsAddCardScreenOpen(false);
  };

  return (
    <div data-testid="add-card-error" css={addCardErrorStyles}>
      <div className={'add-card-error'}>
        <i className={'alert-icon'} aria-hidden="true">
          <AlertIcon />
        </i>
        <div className={'error-text'}>{t('ADD_CARD_ERROR')}</div>
        <RdsButton onClick={handleClick}>{t('CLOSE')}</RdsButton>
      </div>
    </div>
  );
};

export default AddCardError;
