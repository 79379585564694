import { getConfig } from 'scripts/util/constants/config';
import UrlSearchParams from 'url-search-params';
import { IQueryParams } from './uri.interfaces';

export function getBaseApiUrl(): string {
  return getConfig().RALLYPAY_WEB_BASE_API_URL;
}

export function getBaseMyuhcApiUrl(): string {
  return getConfig().RALLYPAY_WEB_MYUHC_BASE_API_URL;
}

const baseApiUri = (getBaseApiUrl() || '') + '/rest/[api]/v[version]/[method]';
const baseAuthUri = baseApiUri + '/u/[rallyId]';

const baseMyuhcApiUri = (getBaseMyuhcApiUrl() || '') + '/rest/[api]/v[version]/[method]';
const baseAuthMyuhcApiUri = baseMyuhcApiUri + '/u/[rallyId]';
const deletePaymentMethodApiUri = baseAuthMyuhcApiUri + '/paymentid/[paymentMethodId]';

function constructParams(queryParams: IQueryParams): string {
  const usp = new UrlSearchParams('');
  Object.keys(queryParams).forEach(key => {
    usp.append(key, queryParams[key]);
  });
  return `?${usp.toString()}`;
}

export function constructUri(
  uri: string,
  api: string,
  version: string,
  method: string,
  addUriParams?: IQueryParams,
  addQueryParams?: IQueryParams,
): string {
  const encodedAddUriParams: IQueryParams = {};
  if (addUriParams) {
    Object.keys(addUriParams).forEach(key => {
      encodedAddUriParams[key] = encodeURIComponent(addUriParams[key]);
    });
  }

  const params: IQueryParams = {
    api,
    method,
    version,
    ...encodedAddUriParams,
  };

  const paramMatches = uri.match(/\[/g);

  if (paramMatches && paramMatches.length === Object.keys(params).length) {
    const assignedUri = Object.keys(params).reduce((accumulator, current) => {
      return accumulator.replace(`[${current}]`, params[current]);
    }, uri);

    return assignedUri + (addQueryParams ? constructParams(addQueryParams) : '');
  } else {
    throw new Error('Not enough parameters supplied to construct uri.');
  }
}

export const paymentUris = {
  hsaV6: (): string => constructUri(baseApiUri, 'payments', '6', 'hsa'),
  hsaV7: (): string => constructUri(baseApiUri, 'payments', '7', 'hsa'),
  monitor: (): string => constructUri(baseApiUri, 'payments', '1', 'monitor'),
  provider: (rallyId: string): string => constructUri(baseAuthUri, 'payments', '1', 'provider', { rallyId }),
  preflightV3: (): string => constructUri(baseApiUri, 'payments', '3', 'preflight'),
  preflightV4: (): string => constructUri(baseApiUri, 'payments', '4', 'preflight'),
  consent: (rallyId: string): string => constructUri(baseAuthUri, 'payments', '1', 'consent', { rallyId }),
};

export const convenientCheckoutUris = {
  paymentMethods: (rallyId: string): string =>
    constructUri(baseAuthMyuhcApiUri, 'payments', '1', 'checkout/paymentmethods', { rallyId }),
  createPayment: (rallyId: string, paymentMode: string): string =>
    constructUri(baseAuthMyuhcApiUri, 'payments', '1', 'checkout', { rallyId }, { paymentMode }),
  deletePaymentMethod: (rallyId: string, paymentMethodId: string): string =>
    constructUri(deletePaymentMethodApiUri, 'payments', '1', 'checkout/paymentmethods', {
      rallyId,
      paymentMethodId,
    }),
};
