import { useContext } from 'react';
import { ICurrencyAmount, MessageType, MoneyUtils } from '@rally/rallypay-commons';
import { AppContext } from 'scripts/contexts/app-context/app-context';

interface IUseConfirmData {
  amount?: string | number | ICurrencyAmount;
  confirmationNumber?: string;
  timeStamp: string;
  providerName: string;
  onDone: () => void;
}

export const useConfirmData = (isCardPayment: boolean): IUseConfirmData | null => {
  const { successResponse, cardPaymentResponse, achPaymentResponse, initialData } = useContext(AppContext);
  const claimInfo = initialData?.claimsInfoPacket?.displayInfo?.claimInfo;
  if (!claimInfo && (!successResponse || !cardPaymentResponse || !achPaymentResponse)) {
    return null;
  }
  let amountBilled,
    confirmationNumber,
    timeStamp = '',
    amount;

  const { formatCurrency } = MoneyUtils;

  const parentWindow = window.parent;

  const onDone: () => void = () => {
    if (parentWindow) {
      if (isCardPayment) {
        const { totalAmount, trackingId } = cardPaymentResponse || achPaymentResponse || {};
        const amountBilled = { iso4217: 'USD', value: totalAmount?.toString() };
        const confirmationNumber = trackingId;
        parentWindow.postMessage({ type: MessageType.Done, payload: { amountBilled, confirmationNumber } }, '*');
      } else {
        parentWindow.postMessage({ type: MessageType.Done }, '*');
      }
    }
  };

  const providerName = claimInfo?.providerName || '';
  if (isCardPayment) {
    // TODO Add timestamp
    ({ totalAmount: amountBilled, trackingId: confirmationNumber } = cardPaymentResponse || achPaymentResponse || {});
    amount = amountBilled ? formatCurrency({ iso4217: 'USD', value: amountBilled.toString() }) : undefined;
  } else {
    ({ amountBilled, confirmationNumber, timeStamp = '' } = successResponse || {});
    amount = amountBilled ? formatCurrency(amountBilled) : undefined;
  }

  return {
    amount,
    confirmationNumber,
    timeStamp,
    providerName,
    onDone,
  };
};
