export const focusableSelectors =
  'a[href], area[href], input:not([disabled]), select:not([disabled]), ' +
  'textarea:not([disabled]), button:not([disabled]), *[tabindex], *[contenteditable]';

/*
 this concept of trapping focus is borrowed from arcade-ui
 it allows for shift-tabbing to previous elements(like the modal close button)
 This works well as long as the widget iframe is the last
 focusable item on the page, which is the case right now.
 if later we have a case where the iframe is not the last
 focusable item, we make this configurable.
 */
export function trapFocus(event: KeyboardEvent): void {
  const tabs = document.querySelectorAll(focusableSelectors);
  if (!event.shiftKey && tabs.length && event.target === tabs[tabs.length - 1]) {
    (tabs[0] as HTMLElement).focus();
    event.preventDefault();
  }
}
