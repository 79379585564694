import { IPaymentsHSA, PaymentFormErrorType } from '@rally/rallypay-commons';

export enum PaymentActionTypes {
  SUBMIT_PAYMENT = 'SUBMIT_PAYMENT',
  SUBMIT_PAYMENT_SUCCESS = 'SUBMIT_PAYMENT_SUCCESS',
  SUBMIT_PAYMENT_FAILURE = 'SUBMIT_PAYMENT_FAILURE',
}

export interface ISubmitPayment {
  type: PaymentActionTypes.SUBMIT_PAYMENT;
}

export interface ISubmitPaymentSuccess {
  payload: IPaymentsHSA;
  type: PaymentActionTypes.SUBMIT_PAYMENT_SUCCESS;
}

export interface ISubmitPaymentFailure {
  type: PaymentActionTypes.SUBMIT_PAYMENT_FAILURE;
  payload: PaymentFormErrorType;
}

export function submitPayment(): ISubmitPayment {
  return {
    type: PaymentActionTypes.SUBMIT_PAYMENT,
  };
}

export function submitPaymentSuccess(payload: IPaymentsHSA): ISubmitPaymentSuccess {
  return {
    payload,
    type: PaymentActionTypes.SUBMIT_PAYMENT_SUCCESS,
  };
}

export function submitPaymentFailure(payload: PaymentFormErrorType): ISubmitPaymentFailure {
  return {
    type: PaymentActionTypes.SUBMIT_PAYMENT_FAILURE,
    payload,
  };
}

export type PaymentAction = ISubmitPayment | ISubmitPaymentSuccess | ISubmitPaymentFailure;
