/** @jsx jsx */
import { jsx } from '@emotion/react';
import { getVersion, MessageType, MoneyUtils } from '@rally/rallypay-commons';
import React, { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FullForm from 'scripts/components/full-form/full-form';
import usePreflight from 'scripts/hooks/use-preflight/use-preflight';

import { errorMessage } from 'scripts/styles/utils';
import { getClaimKey } from 'scripts/util/claim/claim';
import { trapFocus } from 'scripts/util/dom/dom';
import URLSearchParams from 'url-search-params';
import LoadingDots from '../loading-dots/loading-dots';
import { useAppState } from 'scripts/hooks/use-app-state/use-app-state';
import AddCardWidget from '../add-card/add-card';
import AddCardError from '../add-card-error/add-card-error';
import { getFeatureFlags } from 'scripts/util/constants/feature-flags';

const { getHsaAccountBalance, getYouMayOweAmount } = MoneyUtils;

const RallyPay: FunctionComponent = () => {
  const { initialData, isAddCardScreenOpen, showAddCardScreenError } = useAppState();
  const urlParams = new URLSearchParams(window.location.search);
  const { t } = useTranslation();
  const isLocaleValid = ['en-US', 'es-US'].indexOf(urlParams.get('locale') as string) > -1;

  const featureFlags = getFeatureFlags();
  const cardPaymentsEnabled = featureFlags.RALLYPAY_FEATURES_CARD_PAYMENTS;

  useEffect(() => {
    window?.parent.postMessage({ type: MessageType.SetTitle }, '*');
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        document.body.classList.remove('hide-outlines');
        trapFocus(event);
      }
    });
  }, []);

  const {
    error: preflightError,
    isLoading: isPreflightLoading,
    rallyPayDisabled,
    providerEnrolled,
  } = usePreflight({
    token: initialData && initialData.token,
    rallypayUIVersion: getVersion(),
    hsaAccountBalance: getHsaAccountBalance(initialData && initialData.claimsInfoPacket),
    paymentAmount: getYouMayOweAmount(initialData && initialData.claimsInfoPacket),
    claimKey: getClaimKey(initialData && initialData.claimsInfoPacket),
    amountOwed: getYouMayOweAmount(initialData && initialData.claimsInfoPacket),
    hashedTin: initialData && initialData?.claimsInfoPacket?.displayInfo?.claimInfo?.hashedTin,
  });

  // Preflight is legacy and no longer used.  But for safety, we are keeping it while card payments is off.
  // We will clean up preflight code post-cred-card-payments launch.
  if (!cardPaymentsEnabled) {
    return isPreflightLoading ? (
      <LoadingDots />
    ) : (
      <React.Fragment>
        {isLocaleValid && !preflightError && providerEnrolled && !isAddCardScreenOpen && !showAddCardScreenError && (
          <FullForm />
        )}
        {isLocaleValid && !preflightError && providerEnrolled && isAddCardScreenOpen && !showAddCardScreenError && (
          <AddCardWidget />
        )}
        {showAddCardScreenError && <AddCardError />}
        <div aria-live="assertive">
          {!isLocaleValid && <p css={errorMessage}>{t('ERROR_LOCALE_IS_NOT_VALID')}</p>}
          {preflightError && <p css={errorMessage}>{t('ERROR_TOKEN_IS_NOT_VALID')}</p>}
          {rallyPayDisabled && <p css={errorMessage}>{t('ERROR_RALLYPAY_UNAVAILABLE_MESSAGE')}</p>}
          {!providerEnrolled && <p css={errorMessage}>{t('ERROR_PROVIDER_NOT_ENROLLED')}</p>}
        </div>
      </React.Fragment>
    );
  }

  // Non preflight code when card payments is turned on.
  return (
    <React.Fragment>
      {isLocaleValid && !isAddCardScreenOpen && !showAddCardScreenError && <FullForm />}
      {isLocaleValid && isAddCardScreenOpen && !showAddCardScreenError && <AddCardWidget />}
      {showAddCardScreenError && <AddCardError />}
      <div aria-live="assertive">{!isLocaleValid && <p css={errorMessage}>{t('ERROR_LOCALE_IS_NOT_VALID')}</p>}</div>
    </React.Fragment>
  );
};

export default RallyPay;
