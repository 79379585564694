import { useReducer } from 'react';
import { AxiosError } from 'axios';
import {
  submitMultiPaymentSuccess,
  submitMultiPayment,
  submitMultiPaymentFailure,
} from 'scripts/actions/multi-payment-actions';
import { IHsaMultiClaimPaymentData } from 'scripts/api/payments/payments.interfaces';
import { PaymentsService } from 'scripts/api/payments/payments.service';
import formReducer, { IFormState, initialState } from 'scripts/reducers/multi-payment-reducer';
import { getPaymentFormErrorCode } from 'scripts/util/payment-form/payment-form';
import { PaymentFormErrorType } from '@rally/rallypay-commons';

type onSubmitType = (hsaPaymentData: IHsaMultiClaimPaymentData) => Promise<void>;
type errorResponseData = { code: number | PaymentFormErrorType };

export default function useHsaMultiPaySubmitter(): [IFormState, onSubmitType] {
  const [{ attempts, data, errorCode, loading, success }, dispatch] = useReducer(formReducer, initialState);
  const onSubmitHsaPayment: onSubmitType = async ({ authToken, tokens }) => {
    dispatch(submitMultiPayment());
    try {
      const response = await PaymentsService.multiClaimHsa({ authToken, tokens });
      dispatch(submitMultiPaymentSuccess(response.data));
    } catch (err) {
      const error = err as AxiosError;
      const errorCode = (error?.response?.data as errorResponseData)?.code;
      dispatch(submitMultiPaymentFailure(getPaymentFormErrorCode(errorCode)));
    }
  };

  return [
    {
      attempts,
      data,
      errorCode,
      loading,
      success,
    },
    onSubmitHsaPayment,
  ];
}
