import { IPaymentsHSA, PaymentFormErrorType } from '@rally/rallypay-commons';
import { PaymentAction, PaymentActionTypes } from 'scripts/actions/payment-actions';

export interface IFormState {
  attempts: number;
  data?: IPaymentsHSA;
  errorCode?: PaymentFormErrorType;
  loading: boolean;
  success: boolean;
}

export const initialState: IFormState = {
  attempts: 0,
  data: undefined,
  errorCode: undefined,
  loading: false,
  success: false,
};

const paymentReducer = (state: IFormState, action: PaymentAction): IFormState => {
  switch (action.type) {
    case PaymentActionTypes.SUBMIT_PAYMENT: {
      return { ...state, data: undefined, errorCode: undefined, loading: true, attempts: state.attempts + 1 };
    }
    case PaymentActionTypes.SUBMIT_PAYMENT_SUCCESS: {
      return { ...state, data: action.payload, loading: false, success: true };
    }
    case PaymentActionTypes.SUBMIT_PAYMENT_FAILURE: {
      return { ...state, loading: false, success: false, errorCode: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default paymentReducer;
