import React, { useEffect, useState } from 'react';
import { PaymentMethod } from './payment-method/payment-method';
import { ReviewAndSubmit } from './review-and-submit/review-and-submit';
import { HSA_METHOD, IMultipayDefaultProps, IUpdatedRallyPayData } from 'scripts/interface/multipay.interfaces';
import { styled } from '@abyss/web/tools/styled';
import { PaymentMethodsProvider } from 'scripts/contexts/payment-methods-context/payment-methods-context';
import { IPaymentMethodsItem } from '@rally/rallypay-commons';
import useCardPaymentSubmitter from 'scripts/hooks/use-card-payment-submitter/use-card-payment-submitter';
import {
  IClaimPaymentDetails,
  ICreatePaymentData,
  IPaymentMode,
} from 'scripts/api/convenient-checkout/convenient-checkout.interfaces';
import useACHPaymentSubmitter from 'scripts/hooks/use-ach-payment-submitter/use-ach-payment-submitter';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line max-len
import { ErrorCallbackArgs } from '@optum-ccg/convenient-checkout-ui/dist/cjs/capabilities/checkout-v2/types';
import useHsaMultiPaySubmitter from 'scripts/hooks/use-hsa-multipay-submitter/use-hsa-multipay-submitter';

const MultipayForm: React.FunctionComponent<IMultipayDefaultProps> = props => {
  const {
    rallyId,
    authToken,
    encryptedProfileInfo,
    claimInfoPackets,
    paymentSuccessCallback,
    hsaPaymentSuccessCallback,
    paymentFailureCallback,
    setSelectedPayment,
    setResponseMessage,
    confirmSelectionFlag,
    hsaAccount,
    isSessionCheckoutError,
    getUpdatedRallyPayDataHsa,
    refreshToken,
    subTotalPartial,
    claimInfoPacketsError,
    getClaimsInfoToken,
  } = props.initialData;

  const { t } = useTranslation();
  const [totalAmountToPay, setTotalAmountToPay] = useState<number>(0.0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<IPaymentMethodsItem | string>('');
  const [paymentMethodList, setPaymentMethodList] = useState<IPaymentMethodsItem[]>([]);
  const [isCardDefaultPayment, setIsCardDefaultPayment] = useState<boolean>(false);
  const [isHsaDefaultPayment, setIsHsaDefaultPayment] = useState<boolean>(false);
  const [defaultPayment, setDefaultPayment] = useState<IPaymentMethodsItem | string>('');
  const [paymentMethodAdditionError, setPaymentMethodAdditionError] = useState<ErrorCallbackArgs>();
  const [claimPaymentDetails, setClaimPaymentDetails] = useState<IClaimPaymentDetails[]>([]);
  const [paymentSubmissionLoader, setPaymentSubmissionLoader] = useState<boolean>(false);
  const [isPaymentButtonDisabled, setIsPaymentButtonDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (claimInfoPackets && claimInfoPackets.length > 0 && !claimInfoPacketsError) {
      setClaimPaymentDetails(
        claimInfoPackets
          .filter(claim => !!claim?.rallyPayData)
          .map(claim => {
            return {
              claimToken: claim?.rallyPayData,
              amount: Number(claim?.displayInfo.claimInfo.youMayOweAmount?.value),
            };
          }),
      );
      if (subTotalPartial) {
        updatePartialClaimDetails();
      } else {
        const totalAmount = claimInfoPackets.reduce((acc, claim) => {
          const youMayOweAmount = claim?.displayInfo.claimInfo.youMayOweAmount?.value
            ? Number(claim?.displayInfo.claimInfo.youMayOweAmount?.value)
            : 0;
          return acc + youMayOweAmount;
        }, 0);
        setTotalAmountToPay(totalAmount);
      }

      // to check if the payment submission is a retry or not.
      if ((cardPaymentAttempt > 0 || achPaymentAttempt > 0 || hsaPaymentAttempts > 0) && paymentSubmissionLoader) {
        subTotalPartial ? handlePartialPayments() : paymentSelectionHandler();
      }
    }
  }, [claimInfoPackets]);

  useEffect(() => {
    if (subTotalPartial) {
      updatePartialClaimDetails();
    }
  }, [subTotalPartial]);

  const updatePartialClaimDetails = (): void => {
    setTotalAmountToPay(Number(subTotalPartial));
    if (claimInfoPackets && claimInfoPackets.length === 1) {
      setClaimPaymentDetails(
        claimInfoPackets
          .filter(claim => !!claim?.rallyPayData)
          .map(claim => {
            return {
              claimToken: claim?.rallyPayData,
              amount: Number(subTotalPartial),
            };
          }),
      );
    }
  };

  useEffect(() => {
    if (isSessionCheckoutError) {
      setResponseMessage({
        type: 'error',
        code: paymentMethodAdditionError?.code === undefined ? 'UNKOWN_ERROR' : paymentMethodAdditionError?.code,
        title:
          paymentMethodAdditionError?.description === undefined
            ? t('SESSION_EXPIRED')
            : paymentMethodAdditionError?.description,
        message: t('PAYMENT_METHOD_TRY_AGAIN') || '',
      });
    } else {
      setResponseMessage(undefined);
    }
  }, [isSessionCheckoutError]);

  const insufficientHsaBalance =
    parseFloat(hsaAccount?.balance?.availableAmount?.value || '') < parseFloat(totalAmountToPay.toFixed(2));

  const [
    {
      data: cardPaymentResponseData,
      loading: cardPaymentLoading,
      error: cardPaymentError,
      errorResponse: cardErrorResponse,
      attempt: cardPaymentAttempt,
    },
    onSubmitCardPayment,
  ] = useCardPaymentSubmitter() || {};

  const [
    {
      data: achPaymentResponseData,
      loading: achPaymentLoading,
      error: achPaymentError,
      errorResponse: achErrorResponse,
      attempt: achPaymentAttempt,
    },
    onSubmitACHPayment,
  ] = useACHPaymentSubmitter() || {};

  const [
    {
      data: hsaPaymentResponseData,
      errorCode: hsaPaymentErrorCode,
      loading: hsaPaymentLoading,
      success: hsaPaymentSuccess,
      attempts: hsaPaymentAttempts,
    },
    onSubmitHsaPayment,
  ] = useHsaMultiPaySubmitter();

  useEffect(() => {
    setSelectedPayment(selectedPaymentMethod);
  }, [selectedPaymentMethod]);

  const updatePaymentMethod = (paymentMethod: IPaymentMethodsItem | string): void => {
    setSelectedPaymentMethod(paymentMethod);
    if (paymentMethod && typeof paymentMethod === 'object') {
      setSelectedPayment(paymentMethod);
    }
  };

  const updatePaymentMethodList = (paymentMethods: IPaymentMethodsItem[]): void => {
    setPaymentMethodList([...paymentMethods]);
  };

  useEffect(() => {
    if (achPaymentLoading || cardPaymentLoading || hsaPaymentLoading) {
      setPaymentSubmissionLoader(achPaymentLoading || cardPaymentLoading || hsaPaymentLoading);
    }
  }, [achPaymentLoading, cardPaymentLoading, hsaPaymentLoading]);

  useEffect(() => {
    if (achPaymentError && achErrorResponse) {
      setPaymentSubmissionLoader(false);
      paymentFailureCallback(achErrorResponse);
    } else if (cardPaymentError && cardErrorResponse) {
      setPaymentSubmissionLoader(false);
      paymentFailureCallback(cardErrorResponse);
    } else if (hsaPaymentErrorCode) {
      setPaymentSubmissionLoader(false);
      paymentFailureCallback(hsaPaymentErrorCode);
    } else {
      paymentFailureCallback(false);
    }
    setIsPaymentButtonDisabled(false);
  }, [achPaymentError, cardPaymentError, hsaPaymentErrorCode]);

  useEffect(() => {
    if (cardPaymentResponseData || achPaymentResponseData) {
      const response = cardPaymentResponseData || achPaymentResponseData;
      setIsPaymentButtonDisabled(true);
      setPaymentSubmissionLoader(false);
      if (response) paymentSuccessCallback(response);
    }
  }, [cardPaymentResponseData, cardErrorResponse, achPaymentResponseData, achErrorResponse]);

  useEffect(() => {
    if (hsaPaymentResponseData && hsaPaymentSuccess) {
      if (hsaPaymentResponseData?.paidClaims.length === 0) {
        paymentFailureCallback(hsaPaymentResponseData.unpaidClaims[0].failureReason);
      } else {
        setIsPaymentButtonDisabled(true);
        hsaPaymentSuccessCallback(hsaPaymentResponseData);
      }
      setPaymentSubmissionLoader(false);
    }
  }, [hsaPaymentResponseData, hsaPaymentSuccess]);

  useEffect(() => {
    if (refreshToken) {
      paymentSelectionHandler();
    }
  }, [refreshToken]);

  // Payment Submission Handlers
  const handleSubmitCardPayment = (partialClaimPaymentDetails?: IClaimPaymentDetails[]): void => {
    const claimDetails = partialClaimPaymentDetails
      ? partialClaimPaymentDetails
      : claimInfoPackets
          .filter(claim => !!claim?.rallyPayData)
          .map(claim => {
            return {
              claimToken: claim?.rallyPayData,
              amount: Number(claim?.displayInfo.claimInfo.youMayOweAmount?.value),
            };
          });
    if (encryptedProfileInfo && selectedPaymentMethod && typeof selectedPaymentMethod === 'object') {
      const data: ICreatePaymentData = {
        rallyId: rallyId,
        paymentMode: IPaymentMode.CARD,
        authToken: authToken,
        encryptedProfileInfo,
        paymentMethodId: selectedPaymentMethod?.paymentMethodId || '',
        claims: claimDetails,
      };
      onSubmitCardPayment(data);
    }
  };

  const handleSubmitACHPayment = (partialClaimPaymentDetails?: IClaimPaymentDetails[]): void => {
    const claimDetails = partialClaimPaymentDetails
      ? partialClaimPaymentDetails
      : claimInfoPackets
          .filter(claim => !!claim?.rallyPayData)
          .map(claim => {
            return {
              claimToken: claim?.rallyPayData,
              amount: Number(claim?.displayInfo.claimInfo.youMayOweAmount?.value),
            };
          });
    if (encryptedProfileInfo && selectedPaymentMethod && typeof selectedPaymentMethod === 'object') {
      const ACHConsentText = `${t('ACH-AGREEMENT-ONE')} ${t('ACH-AGREEMENT-TWO')}`;
      const data: ICreatePaymentData = {
        rallyId: rallyId,
        paymentMode: IPaymentMode.ACH,
        authToken: authToken,
        encryptedProfileInfo,
        paymentMethodId: selectedPaymentMethod?.paymentMethodId || '',
        consent: {
          consentText: ACHConsentText,
          deviceId: '127.0.0.1',
          userAgent: window?.navigator?.userAgent,
        },
        claims: claimDetails,
      };
      onSubmitACHPayment(data);
    }
  };

  const handleHSASubmission = async (): Promise<void> => {
    const tokens = claimInfoPackets
      .filter(claim => claim && !!claim.rallyPayData)
      .map(claim => {
        return claim && claim?.rallyPayData ? claim.rallyPayData : '';
      });
    onSubmitHsaPayment({ authToken, tokens });
  };

  // Partial Payments Handlers
  const handleHSAPartialPayments = (): void => {
    const tokens = [refreshToken];
    onSubmitHsaPayment({ authToken, tokens });
  };
  const handleCardPartialPayments = (): void => {
    const partialClaimPaymentDetails = claimPaymentDetails.map(claim => {
      return {
        claimToken: refreshToken,
        amount: claim.amount,
      } as IClaimPaymentDetails;
    });
    handleSubmitCardPayment(partialClaimPaymentDetails);
  };
  const handleACHPartialPayments = (): void => {
    const partialClaimPaymentDetails = claimPaymentDetails.map(claim => {
      return {
        claimToken: refreshToken,
        amount: claim.amount,
      } as IClaimPaymentDetails;
    });
    handleSubmitACHPayment(partialClaimPaymentDetails);
  };

  const handlePartialPayments = (): void => {
    if (subTotalPartial) {
      const data = claimInfoPackets.map(claim => {
        return {
          claimKey: claim?.displayInfo?.claimInfo?.claimKey,
          paymentAmount: {
            ...claim?.displayInfo?.claimInfo?.youMayOweAmount,
            value: subTotalPartial,
          },
          rallyPayData: claim?.rallyPayData,
        } as IUpdatedRallyPayData;
      });
      if (data?.length > 0) getUpdatedRallyPayDataHsa(data);
    }
  };

  // To check handler corresponding to the payment method.
  const paymentSelectionHandler = (): void => {
    if (selectedPaymentMethod && typeof selectedPaymentMethod === 'object' && claimPaymentDetails.length > 0) {
      if (selectedPaymentMethod.paymentMethodType === 'CARD') {
        subTotalPartial ? handleCardPartialPayments() : handleSubmitCardPayment();
      } else if (selectedPaymentMethod.paymentMethodType === 'BANK_ACCOUNT') {
        subTotalPartial ? handleACHPartialPayments() : handleSubmitACHPayment();
      }
    } else if (selectedPaymentMethod && selectedPaymentMethod === HSA_METHOD) {
      subTotalPartial ? handleHSAPartialPayments() : handleHSASubmission();
    }
  };

  // To handle the payment submission if the payment is a retry or not.
  const handleSubmitPayment = (): void => {
    if (selectedPaymentMethod && claimPaymentDetails.length > 0) {
      setPaymentSubmissionLoader(true);
      if (cardPaymentAttempt > 0 || achPaymentAttempt > 0 || hsaPaymentAttempts > 0) {
        getClaimsInfoToken();
      } else {
        subTotalPartial ? handlePartialPayments() : paymentSelectionHandler();
      }
    }
  };

  return (
    <>
      <PaymentMethodsProvider>
        <PaymentMethod
          isHsaDefaultPayment={isHsaDefaultPayment}
          isDefaultCardPresent={isCardDefaultPayment}
          setIsCardDefaultPayment={setIsCardDefaultPayment}
          setIsHsaDefaultPayment={setIsHsaDefaultPayment}
          insufficientHsaBalance={insufficientHsaBalance}
          updatePaymentMethod={updatePaymentMethod}
          updatePaymentMethodList={updatePaymentMethodList}
          data={props}
          setPaymentMethodAdditionError={setPaymentMethodAdditionError}
          defaultPaymentMethod={defaultPayment}
          selectedPaymentMethod={selectedPaymentMethod}
          setDefaultPayment={setDefaultPayment}
          setSelectedPaymentMethod={setSelectedPaymentMethod}
        />
        <ReviewAndSubmitWrapper>
          <ReviewAndSubmit
            isHsaDefaultPayment={isHsaDefaultPayment}
            handleSubmitPayment={handleSubmitPayment}
            isCardDefaultPayment={isCardDefaultPayment}
            selectedClaims={claimPaymentDetails}
            totalAmountToPay={totalAmountToPay}
            confirmSelectionFlag={confirmSelectionFlag}
            claimInfoPacketsError={claimInfoPacketsError}
            selectedPaymentMethod={selectedPaymentMethod ? selectedPaymentMethod : defaultPayment}
            hsaAccount={hsaAccount}
            paymentMethodList={paymentMethodList}
            claimInfoPackets={claimInfoPackets}
            paymentSubmissionLoader={paymentSubmissionLoader}
            insufficientHsaBalance={insufficientHsaBalance}
            isPaymentButtonDisabled={isPaymentButtonDisabled}
            setIsPaymentButtonDisabled={setIsPaymentButtonDisabled}
          />
        </ReviewAndSubmitWrapper>
      </PaymentMethodsProvider>
    </>
  );
};

const ReviewAndSubmitWrapper = styled('div', {
  marginTop: '$lg',
  marginBottom: '$md',
  '@screen < $md': {
    marginTop: '0',
    marginBottom: '0',
  },
});

export default MultipayForm;
