import { IConfig } from './config.interfaces';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    CONFIG: IConfig;
  }
}

export const DEFAULT_CONFIG: IConfig = {
  RALLYPAY_WEB_BASE_API_URL: '',
  RALLYPAY_WEB_DATADOG_RUM_APPLICATION_ID: '',
  RALLYPAY_WEB_DATADOG_RUM_CLIENT_TOKEN: '',
  RALLYPAY_WEB_ENVIRONMENT_NAME: 'Local',
  RALLYPAY_WEB_MYUHC_BASE_API_URL: 'https://rallypay-payments-myuhc.ecstatic-ride.rally-dev.com',
  RALLYPAY_WEB_WHITELISTED_ORIGINS: 'http://localhost:1972,http://localhost:2001',
};

export function getConfig(): IConfig {
  const envConfig = window.CONFIG;
  const useDefaultConfig = !envConfig || (envConfig.constructor === Object && Object.keys(envConfig).length === 0);
  return useDefaultConfig ? DEFAULT_CONFIG : envConfig;
}
