import { ICreatePayment } from '@rally/rallypay-commons';
import { CardPaymentActionTypes, CardPaymentAction } from 'scripts/actions/card-payment-action';

export interface ICardPaymentFormState {
  data?: ICreatePayment;
  loading: boolean;
  error: boolean;
  errorResponse?: string | { statusCode: number };
  attempt: number;
}

export const initialState: ICardPaymentFormState = {
  data: undefined,
  loading: false,
  error: false,
  errorResponse: '',
  attempt: 0,
};

const cardPaymentReducer = (state: ICardPaymentFormState, action: CardPaymentAction): ICardPaymentFormState => {
  switch (action.type) {
    case CardPaymentActionTypes.SUBMIT_CARD_PAYMENT: {
      return { ...state, data: undefined, attempt: state.attempt + 1, loading: true, error: false };
    }
    case CardPaymentActionTypes.SUBMIT_CARD_PAYMENT_SUCCESS: {
      return { ...state, data: action.payload, loading: false, error: false };
    }
    case CardPaymentActionTypes.SUBMIT_CARD_PAYMENT_FAILURE: {
      return { ...state, loading: false, error: true, data: undefined, errorResponse: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default cardPaymentReducer;
