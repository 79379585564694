import defaultTheme from './default';
import * as designSystemRally from '@rally/ui-themes/dist/web/rally/tokens.es6';

const arcadeTablet = {
  ...defaultTheme,
  button: {
    ...defaultTheme.button,
    width: '100%',
  },
  confirmationScreen: {
    ...defaultTheme.confirmationScreen,
    iconContainer: {
      ...defaultTheme.confirmationScreen.iconContainer,
      margin: `0 0 ${designSystemRally.RDS_SPACING_SIZE_24}`,
    },
    h2: {
      ...defaultTheme.confirmationScreen.h2,
      fontSize: designSystemRally.RDS_FONT_SIZE_20,
      margin: `0 0 ${designSystemRally.RDS_SPACING_SIZE_24}`,
    },
    p: {
      ...defaultTheme.confirmationScreen.p,
      fontSize: designSystemRally.RDS_FONT_SIZE_16,
    },
    providerName: {
      ...defaultTheme.confirmationScreen.providerName,
      fontSize: designSystemRally.RDS_FONT_SIZE_16,
    },
  },
  isMobile: true,
  label: {
    ...defaultTheme.label,
    color: defaultTheme.colors.grayDark,
    fontSize: designSystemRally.RDS_FONT_SIZE_16,
    fontWeight: designSystemRally.RDS_FONT_WEIGHT_SEMIBOLD,
  },
  p: {
    ...defaultTheme.p,
    fontSize: designSystemRally.RDS_FONT_SIZE_16,
    fontWeight: designSystemRally.RDS_FONT_WEIGHT_SEMIBOLD,
  },
  typeAndBalance: {
    ...defaultTheme.typeAndBalance,
    lineHeight: 1.3,
  },
};

export default arcadeTablet;
