import { PaymentFormErrorType } from '@rally/rallypay-commons';
import { IMultiClaimPaymentsHSA } from 'scripts/interface/multipay.interfaces';

export enum MultiPaymentActionTypes {
  SUBMIT_MULTI_PAYMENT = 'SUBMIT_PAYMENT',
  SUBMIT_MULTI_PAYMENT_SUCCESS = 'SUBMIT_PAYMENT_SUCCESS',
  SUBMIT_MULTI_PAYMENT_FAILURE = 'SUBMIT_PAYMENT_FAILURE',
}

export interface ISubmitMultiPayment {
  type: MultiPaymentActionTypes.SUBMIT_MULTI_PAYMENT;
}

export interface ISubmitMultiPaymentSuccess {
  payload: IMultiClaimPaymentsHSA;
  type: MultiPaymentActionTypes.SUBMIT_MULTI_PAYMENT_SUCCESS;
}

export interface ISubmitMultiPaymentFailure {
  type: MultiPaymentActionTypes.SUBMIT_MULTI_PAYMENT_FAILURE;
  payload: PaymentFormErrorType;
}

export function submitMultiPayment(): ISubmitMultiPayment {
  return {
    type: MultiPaymentActionTypes.SUBMIT_MULTI_PAYMENT,
  };
}

export function submitMultiPaymentSuccess(payload: IMultiClaimPaymentsHSA): ISubmitMultiPaymentSuccess {
  return {
    payload,
    type: MultiPaymentActionTypes.SUBMIT_MULTI_PAYMENT_SUCCESS,
  };
}
export function submitMultiPaymentFailure(payload: PaymentFormErrorType): ISubmitMultiPaymentFailure {
  return {
    type: MultiPaymentActionTypes.SUBMIT_MULTI_PAYMENT_FAILURE,
    payload,
  };
}

export type MultiPaymentAction = ISubmitMultiPayment | ISubmitMultiPaymentSuccess | ISubmitMultiPaymentFailure;
