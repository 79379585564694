import React from 'react';
import { ConsentContext, IConsentContext } from 'scripts/contexts/consent-context/consent-context';

export function useConsent(): IConsentContext {
  const context = React.useContext(ConsentContext);
  if (context === undefined) {
    throw new Error('useConsent must be used within a ConsentProvider');
  }
  return context;
}
