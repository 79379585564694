/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import MASTER_CARD from 'images/logos/creditcard_mastercard.svg';
import OPTUM_BANK from 'images/logos/creditcard_optum.svg';
import VISA from 'images/logos/creditcard_visa.svg';

export interface ILogos {
  [key: string]: React.FunctionComponent<React.SVGAttributes<SVGElement> & { title?: string }>;
}

export interface ILogoProps {
  accountSystemCode?: keyof ILogos;
}

const Logos: ILogos = {
  MASTER_CARD,
  OPTUM_BANK,
  VISA,
};

const Logo: FunctionComponent<ILogoProps> = ({ accountSystemCode }) => {
  const { t } = useTranslation();
  const accountSystemCodeToUse = accountSystemCode && Logos[accountSystemCode] ? accountSystemCode : 'OPTUM_BANK';
  const title = t('FORM_ACCOUNT_SYSTEM_CODE_' + accountSystemCodeToUse);
  const Comp = Logos[accountSystemCodeToUse];
  return (
    <div className="account-logo-container" data-testid={`logo-${accountSystemCodeToUse}`}>
      <svg>
        <Comp title={title} focusable="false" />
      </svg>
    </div>
  );
};

export default Logo;
