import { ICreatePayment } from '@rally/rallypay-commons';
import { AxiosPromise } from 'axios';

export interface IPostPaymentMethodsData {
  rallyId: string;
  authToken: string;
  encryptedProfileInfo: string;
}

export interface IDeletePaymentMethodsData {
  rallyId: string;
  paymentMethodId: string;
}

export interface IClaimPaymentDetails {
  claimToken?: string;
  amount: number;
}

interface IConsentDetails {
  consentText: string;
  deviceId: string;
  userAgent: string;
}

export enum IPaymentMode {
  CARD = 'CARD',
  ACH = 'ACH', //Currently not in the scope
}
export interface ICreatePaymentData {
  rallyId: string;
  paymentMode: IPaymentMode;
  authToken: string;
  paymentMethodId: string;
  encryptedProfileInfo: string;
  claims: IClaimPaymentDetails[];
  consent?: IConsentDetails;
}

export interface IConvenientCheckoutService {
  submitCardPayment: (createPaymentData: ICreatePaymentData) => AxiosPromise<ICreatePayment>;
  submitACHPayment: (createPaymentData: ICreatePaymentData) => AxiosPromise<ICreatePayment>;
  postPaymentMethods: (paymentMethodData: IPostPaymentMethodsData) => AxiosPromise;
  deletePaymentMethod: (paymentMethodData: IDeletePaymentMethodsData) => AxiosPromise;
}
