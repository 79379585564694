import { useEffect } from 'react';
import { MessageType, ImpressionTrackingEventType } from '@rally/rallypay-commons';

export function useAdobeImpressionTracking(promoType: string, promoTitle: string, track: boolean): void {
  const impressionId = `${promoType}:${promoTitle}`;

  useEffect(() => {
    if (!window.parent) return;

    const event: ImpressionTrackingEventType = {
      type: MessageType.ImpressionTracking,
      payload: { impressionId },
    };

    if (track && impressionId) {
      window.parent.postMessage(event, '*');
    }
  }, [track, impressionId]);
}
