import { datadogRum } from '@datadog/browser-rum';
import { getVersion } from '@rally/rallypay-commons';
import { getConfig } from '../constants/config';

export function initDatadog(): void {
  const config = getConfig();
  if (config.RALLYPAY_WEB_DATADOG_RUM_APPLICATION_ID && config.RALLYPAY_WEB_DATADOG_RUM_CLIENT_TOKEN) {
    datadogRum.init({
      applicationId: config.RALLYPAY_WEB_DATADOG_RUM_APPLICATION_ID,
      clientToken: config.RALLYPAY_WEB_DATADOG_RUM_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'rallypay-ui',
      env: config.RALLYPAY_WEB_ENVIRONMENT_NAME,
      version: getVersion(),
      sampleRate: 100,
      trackInteractions: true,
    });
  }
}
