/** @jsx jsx */
import { jsx } from '@emotion/react';
import {
  AccountType,
  IAccountInfoItem,
  ICurrencyAmount,
  IPaymentMethodsItem,
  MessageType,
  MoneyUtils,
  PaymentCardStatus,
  PaymentFormErrorType,
  PaymentMethodType,
} from '@rally/rallypay-commons';
import * as React from 'react';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Logo from 'scripts/components/logo/logo';
import { AppContext } from 'scripts/contexts/app-context/app-context';
import usePaymentSubmitter from 'scripts/hooks/use-payment-submitter/use-payment-submitter';
import useACHPaymentSubmitter from 'scripts/hooks/use-ach-payment-submitter/use-ach-payment-submitter';

import paymentFormStyles from 'scripts/styles/payment-form';
import { getFeatureFlags } from 'scripts/util/constants/feature-flags';
import { validateForm } from 'scripts/util/payment-form/payment-form';
import { AnchorWithClickTracking, LinkTarget } from '../shared/anchor/anchor';
import LinkArrow from 'images/icons/link-arrow.svg';
import logCustomEvent from 'scripts/util/analytics/logCustomEvent';
import { HsaPaymentMethodCard } from '../payment-method-card/hsa-payment-method-card';
import { AbyssThemed } from 'scripts/ui/abyss-themed/abyss-themed';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { PaymentMethodCard } from '../payment-method-card/payment-method-card';
import { ACHAgreement } from '../consent-screen/ach-agreement';
import { styled } from '@abyss/web/tools/styled';
import { usePaymentMethods } from 'scripts/hooks/use-payment-methods/use-payment-methods';
import { ICreatePaymentData, IPaymentMode } from 'scripts/api/convenient-checkout/convenient-checkout.interfaces';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import useCardPaymentSubmitter from 'scripts/hooks/use-card-payment-submitter/use-card-payment-submitter';
import { AbyssButtonWithClickTracking } from '../shared/abyss-button/abyss-button';
import { TermsAndConditions } from '../consent-screen/terms-and-conditions';
import { HSA_METHOD } from 'scripts/interface/multipay.interfaces';
import { TokenType } from 'scripts/contexts/app-context/app-context.interfaces';

const { formatCurrency, getYouMayOweAmount, getAmountStringValue } = MoneyUtils;

function shouldGetUpdatedRallyPayData(amountPaying: ICurrencyAmount, ymo: ICurrencyAmount): boolean {
  return Number(amountPaying.value) !== Number(ymo.value);
}

const ACCEPTED_ACCOUNT_TYPES = [AccountType.HSA];

const PAYMENT_TAKING_TOO_LONG_TIMEOUT = 9000;

const getAcceptedAccounts = (accounts: IAccountInfoItem[]): IAccountInfoItem[] =>
  accounts.filter(acc => ACCEPTED_ACCOUNT_TYPES.indexOf(acc.accountType) > -1);

/*
  Preselects an hsa account
*/
const getInitiallySelectedAccount = (accounts: IAccountInfoItem[]): IAccountInfoItem | null =>
  accounts.filter(({ accountType }) => accountType === AccountType.HSA)[0] || null;

export interface IPaymentForm {
  radioValue: string;
  setRadioValue: React.Dispatch<React.SetStateAction<string>>;
  isPaymentSubmitting?: boolean;
}

const PaymentForm: FunctionComponent<IPaymentForm> = ({ radioValue, setRadioValue, isPaymentSubmitting = false }) => {
  const {
    formError,
    initialData,
    setIsAddCardScreenOpen,
    receiveTokenResponse,
    setFormError,
    setSuccessResponse,
    splitPaymentAmount,
    setCardPaymentResponse,
    setACHPaymentResponse,
  } = useContext(AppContext);
  const isCardPayment = radioValue !== HSA_METHOD;
  const isHsaPayment = radioValue === HSA_METHOD;
  const { claimsInfoPacket, token: authToken, otherData, encryptedProfileInfo } = initialData;
  const hasBeenFetched = React.useRef(false);

  const { paymentMethodsData, postPaymentMethodsLoading, postPaymentMethodsError, fetchPaymentMethods } =
    usePaymentMethods();

  const fetchedPaymentMethodsData = paymentMethodsData?.paymentMethods || [];

  const paymentMethodType = fetchedPaymentMethodsData.find(
    item => item.paymentMethodId === radioValue,
  )?.paymentMethodType;
  const isACHPayment = radioValue && paymentMethodType === PaymentMethodType.BANK_ACCOUNT;

  const parentWindow = window.parent;
  const featureFlags = getFeatureFlags();
  const accountInfo = claimsInfoPacket?.displayInfo?.accountInfo || [];
  const acceptedAccounts = getAcceptedAccounts(accountInfo);

  const [isPaymentSubmitted, setIsPaymentSubmitted] = useState<boolean>(isPaymentSubmitting);
  const isSplitPayment = featureFlags.RALLYPAY_FEATURES_SPLIT_PAYMENT;
  const isACHEnabled = featureFlags.RALLYPAY_FEATURES_WITH_ACH;
  const [paymentTakingTooLongInterval, setPaymentTakingTooLongInterval] = useState<number | undefined>(undefined);
  const [loadingToken, setLoadingToken] = useState(false);
  const [showACHAgreement, setShowACHAgreement] = useState<boolean>(false);
  const [isACHAgreementAccepted, setIsACHAgreementAccepted] = useState<boolean>(false);
  const [showACHAgreementError, setShowACHAgreementError] = useState<boolean>(false);
  const showTermsAndConditions = radioValue !== '' && (isCardPayment || isHsaPayment);
  const [isPartialPayment, setIsPartialPayment] = useState<boolean>(false);
  const [selectedAccount, setSelectedAccount] = useState(getInitiallySelectedAccount(acceptedAccounts));

  const [
    {
      data: cardPaymentResponseData,
      loading: cardPaymentLoading,
      error: cardPaymentError,
      errorResponse: errorResponse,
      attempt: cardPaymentAttempt,
    },
    onSubmitCardPayment,
  ] = useCardPaymentSubmitter() || {};
  const [
    {
      data: achPaymentResponseData,
      loading: achPaymentLoading,
      error: achPaymentError,
      errorResponse: achErrorResponse,
      attempt: achPaymentAttempt,
    },
    onSubmitACHPayment,
  ] = useACHPaymentSubmitter() || {};
  const [{ attempts, data, errorCode, loading, success }, onSubmit] = usePaymentSubmitter();
  const getIsPaymentSubmitted = (): boolean => {
    if (isHsaPayment) {
      return !loading && attempts > 0;
    } else if (isACHPayment) {
      return !achPaymentLoading && achPaymentAttempt > 0;
    }
    return !cardPaymentLoading && cardPaymentAttempt > 0;
  };

  const getIsPaymentSuccessful = (): boolean => {
    if (isHsaPayment) {
      return success;
    } else if (isACHPayment) {
      return !achPaymentError;
    }
    return !cardPaymentError;
  };

  const paymentSubmitted = getIsPaymentSubmitted();
  const isPaymentSuccessful = paymentSubmitted && getIsPaymentSuccessful();
  const rallyPayDisabled = errorCode && errorCode === PaymentFormErrorType.RALLYPAY_UNAVAILABLE;
  const rallyPayData = receiveTokenResponse?.rallyPayData || claimsInfoPacket.rallyPayData;
  const cardPaymentsEnabled = featureFlags.RALLYPAY_FEATURES_CARD_PAYMENTS;

  const { t } = useTranslation();
  const ariaLoadingLabel = t('LOADING_PAYMENT_PROCESSING');

  const requestUpdatedRallyPayData = (paymentAmount: ICurrencyAmount): void => {
    parentWindow &&
      parentWindow.postMessage(
        {
          type: MessageType.RequestToken,
          payload: { tokenType: TokenType.REFRESH_RALLPAYDATA_TOKEN, paymentAmount, rallyPayData },
        },
        '*',
      );
    setLoadingToken(true);
  };

  const requestNewRallyPayData = (): void => {
    parentWindow &&
      parentWindow.postMessage(
        {
          type: MessageType.RequestToken,
          payload: { tokenType: TokenType.NEW_RALLPAYDATA_TOKEN },
        },
        '*',
      );
    setLoadingToken(true);
  };

  useEffect(() => {
    if (cardPaymentsEnabled && !!initialData?.rallyId && !hasBeenFetched.current) {
      fetchPaymentMethods({
        rallyId: initialData?.rallyId,
        authToken: authToken,
        encryptedProfileInfo: encryptedProfileInfo || '',
      });
      hasBeenFetched.current = true;
    }
  }, [initialData?.rallyId, hasBeenFetched]);

  useEffect(() => {
    if (receiveTokenResponse?.rallyPayData && isPaymentSubmitted) {
      if (isPartialPayment && receiveTokenResponse.tokenType === TokenType.NEW_RALLPAYDATA_TOKEN) {
        const ymo = getYouMayOweAmount(claimsInfoPacket);
        let paymentAmount = ymo;
        paymentAmount = { ...paymentAmount, value: getAmountStringValue(splitPaymentAmount) } as ICurrencyAmount;
        requestUpdatedRallyPayData(paymentAmount);
        setIsPartialPayment(false);
      } else if (
        receiveTokenResponse.tokenType === TokenType.REFRESH_RALLPAYDATA_TOKEN ||
        receiveTokenResponse.tokenType === TokenType.NEW_RALLPAYDATA_TOKEN
      ) {
        setLoadingToken(false);
        setIsPaymentSubmitted(false);
        submitPayment();
      }
    } else if (receiveTokenResponse?.error) {
      setLoadingToken(false);
      clearTimeout(paymentTakingTooLongInterval);
      setFormError({ type: PaymentFormErrorType.DEFAULT });
    }
  }, [receiveTokenResponse]);

  // Send "Submit" message after HSA payment has been submitted
  useEffect(() => {
    if (!loading && attempts > 0) {
      if (!errorCode) {
        parentWindow && parentWindow.postMessage({ type: MessageType.Submit, payload: data }, '*');
      } else {
        logCustomEvent('Rally Pay Payment Completed Successful false', { error: [errorCode] });
      }
    }
  }, [attempts, errorCode, loading]);

  // Send "Submit" message after CARD payment has been submitted
  useEffect(() => {
    if (!cardPaymentLoading && cardPaymentAttempt > 0) {
      if (!errorResponse) {
        parentWindow && parentWindow.postMessage({ type: MessageType.Submit, payload: cardPaymentResponseData }, '*');
      } else {
        logCustomEvent('Rally Pay Payment Completed Successful false', { error: [errorResponse] });
      }
    }
  }, [cardPaymentAttempt, errorResponse, cardPaymentLoading]);

  // Send "Submit" message after ACH payment has been submitted
  useEffect(() => {
    if (!achPaymentLoading && achPaymentAttempt > 0) {
      if (!achErrorResponse) {
        parentWindow && parentWindow.postMessage({ type: MessageType.Submit, payload: achPaymentResponseData }, '*');
      } else {
        logCustomEvent('Rally Pay ACH Payment Completed Successful false', { error: [achErrorResponse] });
      }
    }
  }, [achPaymentAttempt, achErrorResponse, achPaymentLoading]);

  useEffect(() => {
    clearTimeout(paymentTakingTooLongInterval);
    if (cardPaymentResponseData && setCardPaymentResponse) {
      setFormError();
      setCardPaymentResponse(cardPaymentResponseData);
    }
    if (cardPaymentError) {
      setFormError({ type: PaymentFormErrorType.DEFAULT });
    }
    if (cardPaymentsEnabled && cardPaymentError && errorResponse === PaymentFormErrorType.INVALID_PAYMENT_METHOD) {
      setFormError({ type: PaymentFormErrorType.INVALID_PAYMENT_METHOD });
    }
    if (cardPaymentsEnabled && cardPaymentError && errorResponse === PaymentFormErrorType.INVALID_PROVIDER_PAYMENT) {
      setFormError({ type: PaymentFormErrorType.INVALID_PROVIDER_PAYMENT });
    }
  }, [cardPaymentResponseData, cardPaymentError]);

  useEffect(() => {
    clearTimeout(paymentTakingTooLongInterval);
    if (achPaymentResponseData && setACHPaymentResponse) {
      setFormError();
      setACHPaymentResponse(achPaymentResponseData);
    }
    if (achPaymentError) {
      setFormError({ type: PaymentFormErrorType.DEFAULT });
    }
    if (isACHEnabled && achPaymentError && errorResponse === PaymentFormErrorType.INVALID_PAYMENT_METHOD) {
      setFormError({ type: PaymentFormErrorType.INVALID_PAYMENT_METHOD });
    }
    if (isACHEnabled && achPaymentError && errorResponse === PaymentFormErrorType.INVALID_PROVIDER_PAYMENT) {
      setFormError({ type: PaymentFormErrorType.INVALID_PROVIDER_PAYMENT });
    }
  }, [achPaymentResponseData, achPaymentError]);

  useEffect(() => {
    clearTimeout(paymentTakingTooLongInterval);
    if (data && setSuccessResponse) {
      setSuccessResponse(data);
    }
    if (errorCode) {
      setFormError({ type: errorCode });
    } else {
      setFormError();
    }
  }, [data, errorCode]);

  useEffect(() => {
    fetchedPaymentMethodsData?.map((paymentMethod: IPaymentMethodsItem) => {
      if (paymentMethod?.default) setRadioValue(paymentMethod.paymentMethodId);
    });
  }, [fetchedPaymentMethodsData]);

  useEffect(() => {
    const paymentMethodType = fetchedPaymentMethodsData.find(
      item => item.paymentMethodId === radioValue,
    )?.paymentMethodType;
    if (radioValue && paymentMethodType === PaymentMethodType.BANK_ACCOUNT) {
      setShowACHAgreement(true);
    } else {
      setShowACHAgreement(false);
    }
  }, [radioValue]);

  useEffect(() => {
    if (!showACHAgreement) {
      setIsACHAgreementAccepted(false);
      setShowACHAgreementError(false);
    }
  }, [showACHAgreement]);

  const clearSelectedPaymentMethod = (id: string): void => {
    if (id === radioValue) setRadioValue('');
  };

  const isSelectedCardExpired = (): boolean => {
    let retVal = false;
    fetchedPaymentMethodsData?.forEach((paymentMethod: IPaymentMethodsItem) => {
      if (paymentMethod.paymentMethodId === radioValue && paymentMethod?.card?.status === PaymentCardStatus.EXPIRED) {
        retVal = true;
      }
    });
    return retVal;
  };

  const handleSubmitCardPayment = (): void => {
    if (encryptedProfileInfo) {
      const data: ICreatePaymentData = {
        rallyId: initialData.rallyId,
        paymentMode: IPaymentMode.CARD,
        authToken: authToken,
        encryptedProfileInfo,
        paymentMethodId: radioValue,
        claims: [
          {
            claimToken: rallyPayData,
            amount: parseFloat(getAmountStringValue(splitPaymentAmount)),
          },
        ],
      };
      setFormError();
      onSubmitCardPayment(data);
    }
  };

  const handleSubmitACHPayment = (): void => {
    if (encryptedProfileInfo) {
      const ACHConsentText = `${t('ACH-AGREEMENT-ONE')} ${t('ACH-AGREEMENT-TWO')}`;
      const data: ICreatePaymentData = {
        rallyId: initialData.rallyId,
        paymentMode: IPaymentMode.ACH,
        authToken: authToken,
        encryptedProfileInfo,
        paymentMethodId: radioValue,
        consent: {
          consentText: ACHConsentText,
          deviceId: '127.0.0.1',
          userAgent: window?.navigator?.userAgent,
        },
        claims: [
          {
            claimToken: rallyPayData,
            amount: parseFloat(getAmountStringValue(splitPaymentAmount)),
          },
        ],
      };
      setFormError();
      onSubmitACHPayment(data);
    }
  };

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault();
    setIsPaymentSubmitted(true);
    const ymo = getYouMayOweAmount(claimsInfoPacket);
    let paymentAmount = ymo;
    if (!paymentAmount) {
      setFormError({ type: PaymentFormErrorType.DEFAULT });
      return;
    }

    const errorType = validateForm(splitPaymentAmount, claimsInfoPacket, !isCardPayment, selectedAccount?.accountId);

    if (errorType && isSplitPayment) {
      setFormError({ type: errorType });
    } else if (isSplitPayment) {
      paymentAmount = { ...paymentAmount, value: getAmountStringValue(splitPaymentAmount) };
      if (ymo && shouldGetUpdatedRallyPayData(paymentAmount, ymo)) {
        setIsPartialPayment(true);
        requestNewRallyPayData();
      } else {
        requestNewRallyPayData();
      }
    } else {
      requestNewRallyPayData();
    }
  };

  const submitPayment = (): void => {
    if (isACHPayment && cardPaymentsEnabled) {
      handleSubmitACHPayment();
    } else if (isCardPayment && cardPaymentsEnabled && !isACHPayment) {
      if (isSelectedCardExpired()) {
        setFormError({ type: PaymentFormErrorType.EXPIRED_CREDIT_CARD });
      } else {
        handleSubmitCardPayment();
      }
    } else if (selectedAccount) {
      onSubmit({ authToken, token: rallyPayData });
      const timeoutInterval = window.setTimeout(() => {
        setFormError({ type: PaymentFormErrorType.PAYMENT_TAKING_TOO_LONG });
      }, PAYMENT_TAKING_TOO_LONG_TIMEOUT);
      setPaymentTakingTooLongInterval(timeoutInterval);
    }
  };

  const handleAccountChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedAccount(acceptedAccounts.find(({ accountId }) => event.target.value === accountId) || null);
  };

  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setFormError();
    setRadioValue(event.target.value);
  };

  const trackClick = (): void => {
    parentWindow &&
      parentWindow.postMessage(
        {
          type: MessageType.ClickTracking,
          payload: {
            url: otherData?.instamedUrl,
            name: 'rp-use-another-account',
          },
        },
        '*',
      );
  };

  const handleAddCardClick = (): void => {
    setIsAddCardScreenOpen(true);
  };

  const handleSubmitWithoutAcceptAgreement = (): void => {
    if (disablePayButton) {
      setShowACHAgreementError(true);
    } else {
      setShowACHAgreementError(false);
    }
  };

  const showLoadingIndicator = loading || loadingToken || cardPaymentLoading || achPaymentLoading;

  const hasInstamedUrl = !!otherData?.instamedUrl;

  const disablePayButton =
    (cardPaymentsEnabled ? !radioValue : !selectedAccount) ||
    rallyPayDisabled ||
    (showACHAgreement && !isACHAgreementAccepted);

  return (
    <div css={paymentFormStyles} className="payment-form">
      {isPaymentSuccessful && (
        <div className="message success" data-testid="success-message">
          {t('FORM_PAYMENT_SUCCESS_MESSAGE')}
        </div>
      )}
      {!isPaymentSuccessful && (
        <form onSubmit={handleSubmit} data-testid="payment-form">
          {!cardPaymentsEnabled &&
            acceptedAccounts.map((account, index) => {
              const { accountType, accountSystemCode, availableBalance, accountId } = account;
              return (
                <div key={index} className={'input-group'} data-testid="account-container">
                  <input
                    id={`radio-${accountId}`}
                    type="radio"
                    name="account-type"
                    value={accountId}
                    onChange={handleAccountChange}
                    checked={selectedAccount === account}
                    disabled={!!formError}
                  />
                  <label htmlFor={`radio-${accountId}`}>
                    <Logo accountSystemCode={accountSystemCode} />
                    <div className="type-and-balance">
                      <div className="account-type">{accountType}</div>
                      <div className="account-balance">
                        {t('FORM_BALANCE_LABEL')}{' '}
                        <span className="account-balance-amount">{formatCurrency(availableBalance)}</span>
                      </div>
                    </div>
                  </label>
                </div>
              );
            })}
          {cardPaymentsEnabled && !isPaymentSuccessful && (
            <React.Fragment>
              <AbyssThemed>
                <StyledRadioGroup onChange={handlePaymentMethodChange} value={radioValue} label="">
                  {acceptedAccounts.map((account, index) => {
                    const { accountType, accountSystemCode, availableBalance } = account;
                    {
                      return (
                        <HsaPaymentMethodCard
                          account={account}
                          accountSystemCode={accountSystemCode}
                          accountType={accountType}
                          availableBalance={availableBalance}
                          selectedAccount={selectedAccount}
                          key={index}
                        />
                      );
                    }
                  })}
                  <StyledLoadingSpinner
                    isLoading={postPaymentMethodsLoading}
                    ariaLoadingLabel={t('LOADING_PAYMENT_METHODS')}
                  />
                  {fetchedPaymentMethodsData?.map((paymentMethod: IPaymentMethodsItem, index: number) => (
                    <PaymentMethodCard
                      cardBrand={paymentMethod?.card?.cardBrand}
                      lastFourCard={paymentMethod?.card?.last4}
                      expiryYear={paymentMethod?.card?.expiryYear}
                      paymentMethodId={paymentMethod?.paymentMethodId}
                      expiryMonth={paymentMethod?.card?.expiryMonth}
                      cardStatus={paymentMethod?.card?.status}
                      bankName={paymentMethod?.bankAccount?.bankName}
                      lastFourBank={paymentMethod?.bankAccount?.last4}
                      nickname={paymentMethod?.nickname}
                      clearSelectedPaymentMethod={clearSelectedPaymentMethod}
                      key={index}
                    />
                  ))}
                </StyledRadioGroup>
              </AbyssThemed>
            </React.Fragment>
          )}
          {hasInstamedUrl && !cardPaymentsEnabled && (
            <p data-testid="rp-use-different-payment-method">
              <Trans>
                <AnchorWithClickTracking
                  className="link"
                  onClick={trackClick}
                  href={otherData?.instamedUrl}
                  target={LinkTarget.Blank}
                  variant="standalone"
                  trackingName="use-different-payment-method"
                  trackingSection="submit-payment"
                >
                  {t('USE_DIFFERENT_PAYMENT_METHOD')}
                  <LinkArrow className="link-arrow" />
                </AnchorWithClickTracking>
              </Trans>
            </p>
          )}
          {cardPaymentsEnabled && (
            <div className="add-payment-method" data-testid="add-payment-method">
              <AnchorWithClickTracking
                className="link"
                onClick={handleAddCardClick}
                trackingName="add-a-payment-method"
                trackingSection="submit-payment"
                variant="standalone"
              >
                + {t('ADD_PAYMENT_METHOD')}
              </AnchorWithClickTracking>
            </div>
          )}
          {hasInstamedUrl && cardPaymentsEnabled && !isACHEnabled && (
            <LinkContainer>
              <p data-testid="rp-use-different-payment-method">
                <Trans>
                  <AnchorWithClickTracking
                    className="link"
                    onClick={trackClick}
                    href={otherData?.instamedUrl}
                    target={LinkTarget.Blank}
                    variant="standalone"
                    trackingName="use-different-payment-method"
                    trackingSection="submit-payment"
                  >
                    {t('PAY_WITH_AHC_BANK_ACCOUNT')}
                    <LinkArrow className="link-arrow" />
                  </AnchorWithClickTracking>
                </Trans>
              </p>
            </LinkContainer>
          )}
          <AbyssThemed>
            {showACHAgreement && (
              <ACHAgreement
                isACHAgreementAccepted={isACHAgreementAccepted}
                setIsACHAgreementAccepted={setIsACHAgreementAccepted}
                showACHAgreementError={showACHAgreementError}
                setShowACHAgreementError={setShowACHAgreementError}
              />
            )}
            {showTermsAndConditions && <TermsAndConditions />}
            <div className="account-button-container">
              <AbyssButtonWithClickTracking
                type="submit"
                isDisabled={disablePayButton || showLoadingIndicator}
                data-testid="submit-button"
                isLoading={showLoadingIndicator}
                ariaLoadingLabel={ariaLoadingLabel}
                trackingName="submit-payment"
                trackingSection="submit-payment"
                onClick={handleSubmitWithoutAcceptAgreement}
              >
                {t('FORM_SUBMIT_PAYMENT')}
              </AbyssButtonWithClickTracking>
            </div>
          </AbyssThemed>
        </form>
      )}
      {!isSplitPayment && errorCode && !rallyPayDisabled && (
        <div aria-live="assertive" className="message error" data-testid="error-message">
          {t('FORM_PAYMENT_ERROR_MESSAGE')}
        </div>
      )}
      {!isSplitPayment && rallyPayDisabled && (
        <div aria-live="assertive" className="message error" data-testid="error-message-disabled">
          {t('ERROR_RALLYPAY_UNAVAILABLE_MESSAGE')}
        </div>
      )}
      {postPaymentMethodsError && (
        <div aria-live="assertive" className="message error">
          {t('ERROR_PAYMENT_METHODS_FAILED_TO_LOAD')}
        </div>
      )}
    </div>
  );
};

const StyledRadioGroup = styled(RadioGroup, {
  paddingBottom: '4px',
});

const StyledLoadingSpinner = styled(LoadingSpinner, {
  margin: '14px 0 0 140px',
});

const LinkContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  margin: '12px 0 0 0',
  width: '320px',
});

export default PaymentForm;
