import React, { FunctionComponent, ReactNode } from 'react';
// import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { useAbyssTheme } from '@abyss/web/hooks/useAbyssTheme';
import { createTheme } from '@abyss/web/tools/theme';

export const theme = createTheme('uhc');

// We must use this component to wrap any component we are migrate to Abyss.
// As we are migrating components on a page, we can move this provider up to the top root of the page.
// Once the entire app has been migrated to Abyss, we can use this a single time in the root
// of our application in the app.tsx file.

export const AbyssThemed: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const themeContext = useAbyssTheme();

  if (themeContext) {
    return <>{children}</>;
  }

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
