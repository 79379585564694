import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import AMEX from 'images/logos/creditcard_amex.svg';
import DINERS from 'images/logos/creditcard_diners.svg';
import DISCOVER from 'images/logos/creditcard_discover.svg';
import JCB from 'images/logos/creditcard_jcb.svg';
import MASTERCARD from 'images/logos/creditcard_mastercard.svg';
import OPTUM_BANK from 'images/logos/creditcard_optum.svg';
import UNIONPAY from 'images/logos/creditcard_unionpay.svg';
import UNKNOWN from 'images/logos/creditcard_unknown.svg';
import VISA from 'images/logos/creditcard_visa.svg';

export interface ILogos {
  [key: string]: React.FunctionComponent<React.SVGAttributes<SVGElement> & { title?: string }>;
}

export interface ICcgLogoProps {
  accountSystemCode?: keyof ILogos;
  cardLogo?: keyof ILogos;
}

const Logos: ILogos = {
  AMEX,
  DINERS,
  DISCOVER,
  JCB,
  MASTERCARD,
  OPTUM_BANK,
  UNIONPAY,
  UNKNOWN,
  VISA,
};

export const Logo: FunctionComponent<ICcgLogoProps> = ({ accountSystemCode }) => {
  const { t } = useTranslation();
  const accountSystemCodeToUse = accountSystemCode && Logos[accountSystemCode] ? accountSystemCode : 'OPTUM_BANK';
  const Comp = Logos[accountSystemCodeToUse];
  const formAccountSystemCode = t('FORM_ACCOUNT_SYSTEM_CODE_' + accountSystemCodeToUse);
  return (
    <div data-testid={`logo-${accountSystemCodeToUse}`}>
      <Comp title={formAccountSystemCode} focusable="false" />
    </div>
  );
};

export const CcgLogo: FunctionComponent<ICcgLogoProps> = ({ cardLogo }) => {
  const { t } = useTranslation();
  const cardLogoToUse = cardLogo && Logos[cardLogo] ? cardLogo : 'UNKNOWN';
  const Comp = Logos[cardLogoToUse];
  const paymentMethod = t('PAYMENT_METHOD_' + cardLogoToUse);
  return (
    <div data-testid={`logo-${cardLogoToUse}`}>
      <Comp title={paymentMethod} focusable="false" />
    </div>
  );
};
