import { IClaimsInfoPacket, MoneyUtils, PaymentFormErrorType } from '@rally/rallypay-commons';

export declare enum ConsentFormErrorType {
  DEFAULT = 'ERROR_DEFAULT',
}

const { getYouMayOweAmount, getAmountStringValue, getHsaAccountBalance, isAmountValid, parseCurrency } = MoneyUtils;

export const WARNING_TYPES = [
  PaymentFormErrorType.INSUFFICIENT_BALANCE,
  PaymentFormErrorType.INSUFFICIENT_BALANCE_PENDING,
  PaymentFormErrorType.PAYMENT_TAKING_TOO_LONG,
  PaymentFormErrorType.RALLYPAY_UNAVAILABLE,
];

export const INSUFFICIENT_BALANCE_TYPES = [
  PaymentFormErrorType.INSUFFICIENT_BALANCE,
  PaymentFormErrorType.INSUFFICIENT_BALANCE_PENDING,
];

// This class of errors are not tied to user input.
export const SYSTEM_ERRORS = [
  PaymentFormErrorType.DEFAULT,
  PaymentFormErrorType.INVALID_PAYMENT_METHOD,
  PaymentFormErrorType.INVALID_PROVIDER_PAYMENT,
  PaymentFormErrorType.PAYMENT_TAKING_TOO_LONG,
  PaymentFormErrorType.RALLYPAY_UNAVAILABLE,
];

export function getPaymentFormErrorCode(errorCode: PaymentFormErrorType | number): PaymentFormErrorType {
  let code = PaymentFormErrorType.DEFAULT;

  if (typeof errorCode === 'number') {
    switch (errorCode) {
      case 406:
        code = PaymentFormErrorType.INVALID_PAYMENT_METHOD;
        break;
      case 412:
        code = PaymentFormErrorType.INVALID_PROVIDER_PAYMENT;
    }
    return code;
  }

  const foundCode = Object.values(PaymentFormErrorType).find(value => value === errorCode);

  if (foundCode) {
    code = foundCode;
  }

  return code;
}

export function getConsentFormErrorCode(errorCode: ConsentFormErrorType): ConsentFormErrorType {
  const found = Object.values(ConsentFormErrorType).find(value => value === errorCode);
  return found ? errorCode : ConsentFormErrorType.DEFAULT;
}

export function validateForm(
  splitPaymentAmount: string,
  claimsInfoPacket: IClaimsInfoPacket,
  isHSAPayment: boolean,
  selectedPaymentMethod: string | undefined,
): PaymentFormErrorType | void {
  const valueWithoutDollarSign = getAmountStringValue(splitPaymentAmount);
  const parsedPayingAmount = parseFloat(valueWithoutDollarSign);
  const parsedYouMayOwnAmount = parseCurrency(getYouMayOweAmount(claimsInfoPacket));
  const parsedHsaBalance = parseCurrency(getHsaAccountBalance(claimsInfoPacket));
  const remainingBalance = parsedYouMayOwnAmount - parsedPayingAmount;
  if (!isAmountValid(splitPaymentAmount)) {
    return PaymentFormErrorType.INVALID_INPUT;
  } else if (parsedPayingAmount > parsedHsaBalance && isHSAPayment) {
    return PaymentFormErrorType.INSUFFICIENT_BALANCE;
  } else if (remainingBalance > 0 && remainingBalance <= 3 && isHSAPayment) {
    return PaymentFormErrorType.REMAINING_BALANCE_TOO_LOW;
  } else if (parsedPayingAmount <= 3) {
    return PaymentFormErrorType.AMOUNT_TOO_SMALL;
  } else if (parsedPayingAmount > parsedYouMayOwnAmount) {
    return PaymentFormErrorType.AMOUNT_TOO_LARGE;
  } else if (
    isHSAPayment &&
    (selectedPaymentMethod === null || selectedPaymentMethod === undefined || selectedPaymentMethod === '')
  ) {
    return PaymentFormErrorType.NO_PAYMENT_METHOD_SELECTED;
  }
}
