/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { RdsButton } from '@rally/energon-react';
import defaultErrorScreenStyles from 'scripts/styles/default-error-screen';

interface IProps {
  onClick: () => void;
}

export const DefaultErrorScreen: FunctionComponent<IProps> = props => {
  const { t } = useTranslation();

  return (
    <div css={defaultErrorScreenStyles}>
      <p>{t('ERROR_SOMETHING_WENT_WRONG')}</p>
      <RdsButton variant="primary" onClick={props.onClick}>
        {t('TRY_AGAIN')}
      </RdsButton>
    </div>
  );
};
