import { ArcadeWebTheme } from '@rally/rallypay-commons';
import URLSearchParams from 'url-search-params';

const urlParams = new URLSearchParams(window.location.search);
const arcadeWebTheme = (urlParams.get('arcadeWebTheme') as ArcadeWebTheme) || 'unitedhealth';
switch (arcadeWebTheme) {
  case ArcadeWebTheme.Advantage:
    require('@rally/creation-matrix/dist/css/rally.css');
    break;
  default:
    require('@rally/creation-matrix/dist/css/uhc.css');
}
