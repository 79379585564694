import { AxiosPromise } from 'axios';
import { getBaseMyuhcApiUrl } from 'scripts/util/uri/uri';
import HttpClient, { contentTypeJsonAxiosOptions } from '../http-client';
import { ICheckoutSessionRequestBody, ICheckoutSessionResponse } from './checkoutSession.interfaces';

export function getCheckoutSession(
  rallyId: string,
  body: ICheckoutSessionRequestBody,
): AxiosPromise<ICheckoutSessionResponse> {
  return HttpClient.post(
    `${getBaseMyuhcApiUrl()}/rest/payments/v1/checkout/session/u/${rallyId}`,
    body,
    contentTypeJsonAxiosOptions,
  );
}
