import { useReducer } from 'react';
import { AxiosError } from 'axios';
import { submitPayment, submitPaymentFailure, submitPaymentSuccess } from 'scripts/actions/payment-actions';
import { IHsaPaymentData } from 'scripts/api/payments/payments.interfaces';
import { PaymentsService } from 'scripts/api/payments/payments.service';
import formReducer, { IFormState, initialState } from 'scripts/reducers/payment-reducer';
import { getPaymentFormErrorCode } from 'scripts/util/payment-form/payment-form';
import { PaymentFormErrorType } from '@rally/rallypay-commons';

type onSubmitType = (hsaPaymentData: IHsaPaymentData) => Promise<void>;
type errorResponseData = { code: number | PaymentFormErrorType };

export default function usePaymentSubmitter(): [IFormState, onSubmitType] {
  const [{ attempts, data, errorCode, loading, success }, dispatch] = useReducer(formReducer, initialState);
  const onSubmit: onSubmitType = async ({ authToken, token }) => {
    dispatch(submitPayment());
    try {
      const response = await PaymentsService.hsa({ authToken, token });
      dispatch(submitPaymentSuccess(response.data));
    } catch (err) {
      const error = err as AxiosError;
      const errorCode = (error?.response?.data as errorResponseData)?.code;
      dispatch(submitPaymentFailure(getPaymentFormErrorCode(errorCode)));
    }
  };

  return [
    {
      attempts,
      data,
      errorCode,
      loading,
      success,
    },
    onSubmit,
  ];
}
