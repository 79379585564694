export enum ConsentFormError {
  NO_NAME = 'RALLYPAY_CONSENT.ERROR_NO_NAME',
  NO_DATE = 'RALLYPAY_CONSENT.ERROR_NO_DATE',
  HAS_NOT_SCROLLED = 'RALLYPAY_CONSENT.ERROR_HAS_NOT_SCROLLED',
  DEFAULT = 'ERROR_DEFAULT',
}

export function validateForm(name: string, date: string, hasReachedBottom: boolean): ConsentFormError | null {
  if (!name) {
    return ConsentFormError.NO_NAME;
  } else if (!date) {
    return ConsentFormError.NO_DATE;
  } else if (!hasReachedBottom) {
    return ConsentFormError.HAS_NOT_SCROLLED;
  }
  return null;
}
