import { css, Theme } from '@emotion/react';
import { Style } from 'scripts/styles/themes/themes.interfaces';

const consentScreenStyles: Style = (theme: Theme) => css`
  .consent-form-container {
    max-width: 99%;
    line-height: 20px;
  }
  .bold-label {
    font-weight: bold;
  }
  .link {
    margin-top: 12px;
    & > .rds-icon {
      display: none;
    }
    & > .link-arrow {
      margin-left: 5px;
    }
  }
  .user-input-container {
    display: flex;
    flex-direction: row;
    margin-top: 28px;
    flex-wrap: wrap;
    gap: 16px;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    width: 307px;
  }
  .name-input {
    border-radius: 4px;
    height: 40px;
    width: 307px;
    margin-top: 4px;
    margin-bottom: 4px;
    padding: 8px;
  }
  .date-input {
    border-radius: 4px;
    height: 40px;
    width: 250px;
    margin-top: 4px;
    margin-bottom: 4px;
    border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
  }
  .error-message {
    color: red;
  }
  .account-button-container {
    margin-top: 26px;
    padding-bottom: 64px;
    button + button {
      margin-left: 16px;
      svg {
        margin-left: 11px;
      }
    }
  }
  .from-button {
    border: 0;
    font-weight: 600;
    padding: 0;
    margin-left: 5px;
    &,
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      color: ${theme.colors.uhcBlue};
    }
    & > .link-arrow {
      padding-bottom: 1px;
    }
  }
  .cancel-button {
    width: 98px;
    margin-left: 5px;
  }
  .agree-button {
    width: 123px;
  }
  .disabled-agree-button {
    width: 123px;
    background-color: ${theme.colors.gray};
    border-color: ${theme.colors.gray};
  }
`;

export default consentScreenStyles;
