/** @jsx jsx */
import { jsx } from '@emotion/react';
import React, { ChangeEvent, FunctionComponent, useEffect, useState } from 'react';
import { RdsButton } from '@rally/energon-react';
import { useTranslation } from 'react-i18next';
import consentScreenStyles from 'scripts/styles/consent-screen';
import CheckIcon from 'images/icons/check.svg';
import LinkArrow from 'images/icons/link-arrow.svg';
import { AgreementBox } from './agreement-box';
import { ConsentFormError, validateForm } from 'scripts/util/consent-form/consent-form';
import { MessageType } from '@rally/rallypay-commons';
import { format } from 'date-fns/format';
import { useConsent } from 'scripts/hooks/use-consent/use-consent';
import { useAppState } from 'scripts/hooks/use-app-state/use-app-state';
import { AnchorWithClickTracking, LinkTarget } from 'scripts/components/shared/anchor/anchor';
import LoadingDots from '../loading-dots/loading-dots';
import withClickTracking from 'scripts/util/analytics/withClickTracking';
import { usePageTracking } from 'scripts/util/analytics/usePageTracking';
import logCustomEvent from 'scripts/util/analytics/logCustomEvent';
import { getFeatureFlags } from 'scripts/util/constants/feature-flags';

const CURRENT_DATE = format(new Date(), 'MM/dd/yyyy');
const RALLYPAY_PRINTABLE_AGREEMENT_URL =
  'https://www.uhc.com/content/dam/uhcdotcom/en/Legal/PDF/payment-authorization-for-release-of-information-form.pdf';

const RdsButtonWithClickTracking = withClickTracking(RdsButton);

export const ConsentScreen: FunctionComponent = () => {
  const { t } = useTranslation();

  const { initialData } = useAppState();
  const { submitConsent, submitConsentLoading, submitConsentError } = useConsent();

  const [hasReachedBottom, setHasReachedBottom] = useState<boolean>(false);
  const [formError, setFormError] = useState<ConsentFormError | null>(null);
  const [inputName, setInputName] = useState<string>(initialData.memberName || '');

  const { otherData, rallyId, token: authToken } = initialData;
  const instaMedUrl = otherData?.instamedUrl;
  const agreeButtonDisabled = !hasReachedBottom || !inputName;

  const featureFlags = getFeatureFlags();
  const isACHEnabled = featureFlags.RALLYPAY_FEATURES_WITH_ACH;

  usePageTracking('payment-authorization');

  useEffect(() => {
    window?.parent.postMessage(
      { type: MessageType.SetTitle, payload: { title: t('RALLYPAY_CONSENT.PAYMENT_AUTHORIZATION') } },
      '*',
    );
  }, []);

  useEffect(() => {
    if (submitConsentError) {
      logCustomEvent('Rally Pay Authorization Error', {});
    }
  }, [submitConsentError]);

  const handleInputNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setInputName(value);
  };

  const handleSubmit = (): void => {
    const errorType = validateForm(inputName, CURRENT_DATE, hasReachedBottom);
    setFormError(errorType);
    if (errorType) {
      return;
    }
    submitConsent({ rallyId, authToken });
  };
  const handleCancel = (): void => {
    if (window?.parent) {
      window?.parent.postMessage({ type: MessageType.Done }, '*');
    }
  };

  return (
    <div css={consentScreenStyles}>
      <div className="consent-form-container">
        <div>{t('RALLYPAY_CONSENT.IN_ORDER_TO_PROCESS')}</div>
        {instaMedUrl && !isACHEnabled && (
          <AnchorWithClickTracking
            className="link"
            trackingName="use-different-payment-method-link"
            trackingSection="payment-authorization"
            typographySize="body-2"
            variant="standalone"
            href={instaMedUrl}
            target={LinkTarget.Blank}
            onClick={handleCancel}
          >
            {t('USE_DIFFERENT_PAYMENT_METHOD')}
            <LinkArrow className="link-arrow" />
          </AnchorWithClickTracking>
        )}
        <AgreementBox setHasReachedBottom={setHasReachedBottom} />
        <div>{t('RALLYPAY_CONSENT.BY_CLICKING_AGREE')}</div>
        <AnchorWithClickTracking
          className="link"
          trackingName="view-printable"
          trackingSection="payment-authorization"
          typographySize="body-2"
          variant="standalone"
          href={RALLYPAY_PRINTABLE_AGREEMENT_URL}
          target={LinkTarget.Blank}
        >
          {t('RALLYPAY_CONSENT.VIEW_PRINTABLE')}
          <LinkArrow className="link-arrow" />
        </AnchorWithClickTracking>
        <div className="user-input-container">
          <div className="button-container">
            <label className="bold-label">{t('RALLYPAY_CONSENT.NAME')}</label>
            <input className="name-input" onChange={handleInputNameChange} value={inputName} type="text" name="name" />
            <label>{t('RALLYPAY_CONSENT.ENTER_YOUR_NAME')}</label>
          </div>
          <div className="button-container">
            <label className="bold-label">Date</label>
            <div className="date-input">{CURRENT_DATE}</div>
            <label>MM/DD/YYYY</label>
          </div>
        </div>
        <div className="account-button-container">
          {submitConsentError && <p className="error-message">{t('ERROR_SOMETHING_WENT_WRONG')}</p>}
          {formError && <p className="error-message">{t(formError)}</p>}
          <RdsButtonWithClickTracking
            className="cancel-button"
            trackingName="cancel"
            trackingSection="payment-authorization"
            onClick={handleCancel}
            variant="secondary"
            data-testid="cancel-button"
          >
            {t('RALLYPAY_CONSENT.CANCEL')}
          </RdsButtonWithClickTracking>
          <RdsButtonWithClickTracking
            onClick={handleSubmit}
            className={agreeButtonDisabled ? 'disabled-agree-button' : 'agree-button'}
            variant="primary"
            type="submit"
            data-testid="submit-button"
            trackingName="agree"
            trackingSection="payment-authorization"
          >
            {submitConsentLoading ? (
              <LoadingDots size="small" />
            ) : (
              <React.Fragment>
                {t('RALLYPAY_CONSENT.AGREE')}
                <CheckIcon />
              </React.Fragment>
            )}
          </RdsButtonWithClickTracking>
        </div>
      </div>
    </div>
  );
};
