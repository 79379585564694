import { PaymentFormErrorType } from '@rally/rallypay-commons';
import { MultiPaymentAction, MultiPaymentActionTypes } from 'scripts/actions/multi-payment-actions';
import { IMultiClaimPaymentsHSA } from 'scripts/interface/multipay.interfaces';

export interface IFormState {
  attempts: number;
  data?: IMultiClaimPaymentsHSA;
  errorCode?: PaymentFormErrorType;
  loading: boolean;
  success: boolean;
}

export const initialState: IFormState = {
  attempts: 0,
  data: undefined,
  errorCode: undefined,
  loading: false,
  success: false,
};

const multiPaymentReducer = (state: IFormState, action: MultiPaymentAction): IFormState => {
  switch (action.type) {
    case MultiPaymentActionTypes.SUBMIT_MULTI_PAYMENT: {
      return { ...state, data: undefined, errorCode: undefined, loading: true, attempts: state.attempts + 1 };
    }
    case MultiPaymentActionTypes.SUBMIT_MULTI_PAYMENT_SUCCESS: {
      return { ...state, data: action.payload, loading: false, success: true };
    }
    case MultiPaymentActionTypes.SUBMIT_MULTI_PAYMENT_FAILURE: {
      return { ...state, loading: false, success: false, errorCode: action.payload };
    }
    default: {
      return state;
    }
  }
};

export default multiPaymentReducer;
