import { IPaymentMethods } from '@rally/rallypay-commons';

export declare enum PaymentMethodsErrorType {
  DEFAULT = 'ERROR_DEFAULT',
}

export const POST_PAYMENT_METHODS_LOADING = 'POST_PAYMENT_METHODS_LOADING';
export const POST_PAYMENT_METHODS_ERROR = 'POST_PAYMENT_METHODS_ERROR';
export const POST_PAYMENT_METHODS_SUCCESS = 'POST_PAYMENT_METHODS_SUCCESS';
export const DELETE_PAYMENT_METHODS_LOADING = 'DELETE_PAYMENT_METHODS_LOADING';
export const DELETE_PAYMENT_METHODS_SUCCESS = 'DELETE_PAYMENT_METHODS_SUCCESS';
export const DELETE_PAYMENT_METHODS_ERROR = 'DELETE_PAYMENT_METHODS_ERROR';

export interface IPostPaymentMethodsLoading {
  type: typeof POST_PAYMENT_METHODS_LOADING;
}

export interface IPostPaymentMethodsError {
  type: typeof POST_PAYMENT_METHODS_ERROR;
}

export interface IPostPaymentMethodsSuccess {
  type: typeof POST_PAYMENT_METHODS_SUCCESS;
  payload: IPaymentMethods;
}

export interface IDeletePaymentMethodsLoading {
  type: typeof DELETE_PAYMENT_METHODS_LOADING;
}
export interface IDeletePaymentMethodsError {
  type: typeof DELETE_PAYMENT_METHODS_ERROR;
}

export interface IDeletePaymentMethodsSuccess {
  payload: { paymentMethodId: string };
  type: typeof DELETE_PAYMENT_METHODS_SUCCESS;
}

export function postPaymentMethodsLoading(): IPostPaymentMethodsLoading {
  return {
    type: POST_PAYMENT_METHODS_LOADING,
  };
}

export function postPaymentMethodsError(): IPostPaymentMethodsError {
  return {
    type: POST_PAYMENT_METHODS_ERROR,
  };
}

export function postPaymentMethodsSuccess(payload: IPaymentMethods): IPostPaymentMethodsSuccess {
  return {
    type: POST_PAYMENT_METHODS_SUCCESS,
    payload,
  };
}
export function deletePaymentMethodsLoading(): IDeletePaymentMethodsLoading {
  return {
    type: DELETE_PAYMENT_METHODS_LOADING,
  };
}
export function deletePaymentMethodsError(): IDeletePaymentMethodsError {
  return {
    type: DELETE_PAYMENT_METHODS_ERROR,
  };
}

export function deletePaymentMethodsSuccess(payload: { paymentMethodId: string }): IDeletePaymentMethodsSuccess {
  return {
    type: DELETE_PAYMENT_METHODS_SUCCESS,
    payload,
  };
}

export type PaymentMethodsAction =
  | IDeletePaymentMethodsLoading
  | IDeletePaymentMethodsError
  | IDeletePaymentMethodsSuccess
  | IPostPaymentMethodsLoading
  | IPostPaymentMethodsError
  | IPostPaymentMethodsSuccess;
