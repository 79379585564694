/** @jsx jsx */
import { jsx } from '@emotion/react';

import React, { FunctionComponent, useEffect } from 'react';

import '../../requireTheme';
import LoadingDots from 'scripts/components/loading-dots/loading-dots';

import { ConsentScreen } from 'scripts/components/consent-screen/consent-screen';
import RallyPay from 'scripts/components/rallypay/rallpay';
import { useConsent } from 'scripts/hooks/use-consent/use-consent';
import { useAppState } from 'scripts/hooks/use-app-state/use-app-state';

const ConsentGuard: FunctionComponent = () => {
  const { initialData } = useAppState();
  const initialDataComplete =
    typeof initialData !== 'undefined' && initialData.claimsInfoPacket && initialData.token && initialData.rallyId;

  const hasBeenFetched = React.useRef(false);

  const { consentData, getConsentLoading, getConsentError, fetchConsent } = useConsent();

  useEffect(() => {
    if (!!initialData?.rallyId && !hasBeenFetched.current) {
      fetchConsent({ rallyId: initialData?.rallyId });
      hasBeenFetched.current = true;
    }
  }, [hasBeenFetched, initialData?.rallyId]);

  if (!initialDataComplete || getConsentLoading || !hasBeenFetched.current) {
    return <LoadingDots />;
  }

  if (getConsentError) {
    throw new Error('unable to fetch consent history');
  }

  if (!consentData?.accepted) {
    return <ConsentScreen />;
  }

  return <RallyPay />;
};

export default ConsentGuard;
