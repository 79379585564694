import React from 'react';
import { useConfirmData } from 'scripts/hooks/use-confirm-data/use-confirm-data';
import { usePaymentMethods } from 'scripts/hooks/use-payment-methods/use-payment-methods';
import { ConfirmationView } from './confirmation-view';

export interface IACHConfirmation {
  paymentMethodId: string;
}

const ACHConfirmation: React.FunctionComponent<IACHConfirmation> = ({ paymentMethodId }) => {
  const { amount, confirmationNumber, providerName, onDone } = useConfirmData(true) || {};
  const { paymentMethodsData } = usePaymentMethods();
  const fetchedPaymentMethodsData = paymentMethodsData?.paymentMethods || [];
  const paymentMethod = fetchedPaymentMethodsData.filter(item => item.paymentMethodId === paymentMethodId)?.[0];

  if (!amount) {
    return null;
  }
  const { bankName = '', last4, accountType = '' } = paymentMethod?.bankAccount || {};

  function formatBankName(bank: string): string {
    if (!bank) {
      return '';
    }
    return bank
      .split(' ')
      .map((word: string) => word.charAt(0)?.toLocaleUpperCase() + word.slice(1)?.toLocaleLowerCase())
      .join(' ');
  }

  const formattedBankName = formatBankName(bankName);
  const formattedAccountType = accountType?.charAt(0)?.toLocaleUpperCase() + accountType?.slice(1)?.toLocaleLowerCase();
  const methodDisplayName = formattedBankName + ' ' + formattedAccountType + ' *' + last4;

  return (
    <ConfirmationView
      timeStamp=""
      methodDisplayName={methodDisplayName}
      paymentAmount={amount}
      confirmationNumber={confirmationNumber}
      providerName={providerName}
      isCardPayment={true}
      onDone={onDone}
    />
  );
};

export default ACHConfirmation;
