import { css, Theme } from '@emotion/react';
import { Style } from 'scripts/styles/themes/themes.interfaces';

const addCardErrorStyles: Style = (theme: Theme) => css`
  .add-card-error {
    font-weight: ${theme.p.fontWeight};
    text-align: center;
  }
  .alert-icon {
    color: ${theme.colors.red};
    display: block;
    height: 50px;
    width: 50px;
    margin: 0 auto;
  }
  path.triangle {
    fill: ${theme.colors.red};
  }
  .error-text {
    margin: 40px;
  }
`;

export default addCardErrorStyles;
