import { MutableRefObject, useEffect, useRef, useState } from 'react';

export const useHasReachedBottom = (): [MutableRefObject<HTMLDivElement | null>, boolean] => {
  const componentRef = useRef<HTMLDivElement | null>(null);
  const [reachedBottom, setReachedBottom] = useState(false);

  const handleScroll = (): void => {
    if (componentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = componentRef.current;
      const hasReachedBottom = scrollHeight - (scrollTop + clientHeight) <= 20;
      if (hasReachedBottom) {
        setReachedBottom(true);
      }
    }
  };

  useEffect(() => {
    componentRef.current?.addEventListener('scroll', handleScroll);
    return () => componentRef.current?.removeEventListener('scroll', handleScroll);
  }, []);

  return [componentRef, reachedBottom];
};
