/** @jsx jsx */
import React from 'react';
import { Global, jsx, ThemeProvider } from '@emotion/react';

import { MessageType } from '@rally/rallypay-commons';

import '../../requireTheme';

import ConsentGuard from 'scripts/components/consent-guard/consent-guard';
import { ErrorBoundary } from 'scripts/components/error-boundary/error-boundary';
import { AppProvider } from 'scripts/contexts/app-context/app-context';
import globalStyles from 'scripts/styles/global';
import { initDatadog } from 'scripts/util/datadog/datadog';
import { getTheme } from 'scripts/util/theme/theme';

import { DefaultErrorScreen } from '../default-error-screen/default-error-screen';
import { ConsentProvider } from 'scripts/contexts/consent-context/consent-context';
import { Provider } from 'react-redux';
import { PayAnywayStore } from 'scripts/state';
import { PaymentMethodsProvider } from 'scripts/contexts/payment-methods-context/payment-methods-context';

const App: React.FunctionComponent = () => {
  const theme = getTheme();

  /**
   * 1. Initiate Datadog monitoring
   * 2. Signal to the host app that the widget is ready to receive messages
   */
  React.useEffect(() => {
    initDatadog();
    const timeOutInterval = setTimeout(() => window.parent?.postMessage({ type: MessageType.Ready }, '*'), 100);
    return () => clearTimeout(timeOutInterval);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Global styles={globalStyles} />
      <Provider store={PayAnywayStore}>
        <AppProvider>
          <ErrorBoundary fallback={<DefaultErrorScreen onClick={location.reload} />}>
            <ConsentProvider>
              <PaymentMethodsProvider>
                <ConsentGuard />
              </PaymentMethodsProvider>
            </ConsentProvider>
          </ErrorBoundary>
        </AppProvider>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
