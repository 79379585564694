import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { PaymentFormErrorType } from '@rally/rallypay-commons';
import { IPreflightData } from 'scripts/api/payments/payments.interfaces';
import { PaymentsService } from 'scripts/api/payments/payments.service';

type errorResponseData = { code: number | PaymentFormErrorType };

export interface IPreflightState {
  error: boolean;
  isLoading: boolean;
  rallyPayDisabled: boolean;
  providerEnrolled: boolean;
}

export default function usePreflight(preflightData: IPreflightData): IPreflightState {
  const [isLoading, updateLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rallyPayDisabled, updateRallyPayDisabled] = useState(false);
  const [providerEnrolled, setProviderEnrolled] = useState(true);
  const { token, rallypayUIVersion } = preflightData;
  function handleResponse(): void {
    updateLoading(false);
  }

  function handleError(err: AxiosError): void {
    if ((err?.response?.data as errorResponseData)?.code === PaymentFormErrorType.RALLYPAY_UNAVAILABLE) {
      updateRallyPayDisabled(true);
    } else if (
      (err?.response?.data as errorResponseData)?.code === PaymentFormErrorType.ERROR_PROVIDER_NOT_ENROLLED ||
      (err?.response?.data as errorResponseData)?.code === PaymentFormErrorType.UNABLE_TO_DETERMINE_PROVIDER_ENROLLMENT
    ) {
      setProviderEnrolled(false);
    } else {
      setError(true);
    }
    updateLoading(false);
  }

  useEffect(() => {
    async function preflightRequest(preflightData: IPreflightData): Promise<void> {
      try {
        await PaymentsService.preflight(preflightData);
        handleResponse();
      } catch (err) {
        const error = err as AxiosError;
        handleError(error);
      }
    }

    if (token) {
      preflightRequest(preflightData);
    }
  }, [token, rallypayUIVersion]);

  return {
    error,
    isLoading,
    rallyPayDisabled,
    providerEnrolled,
  };
}
