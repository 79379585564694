import { IConvenientCheckoutService, ICreatePaymentData } from '../convenient-checkout/convenient-checkout.interfaces';
import { AxiosPromise } from 'axios';
import HttpClient, { contentTypeJsonAxiosOptions } from 'scripts/api/http-client';
import { convenientCheckoutUris } from 'scripts/util/uri/uri';
import { ICreatePayment } from '@rally/rallypay-commons';

export const ConvenientCheckoutService: IConvenientCheckoutService = {
  postPaymentMethods: ({ rallyId, authToken, encryptedProfileInfo }): AxiosPromise => {
    const url = convenientCheckoutUris.paymentMethods(rallyId);
    return HttpClient.post(url, { authToken, encryptedProfileInfo }, contentTypeJsonAxiosOptions);
  },
  deletePaymentMethod: ({ rallyId, paymentMethodId }): AxiosPromise => {
    const url = convenientCheckoutUris.deletePaymentMethod(rallyId, paymentMethodId);
    return HttpClient.delete(url, {});
  },
  submitCardPayment: (paymentData: ICreatePaymentData): AxiosPromise<ICreatePayment> => {
    const { rallyId, paymentMode, paymentMethodId, authToken, encryptedProfileInfo, claims } = paymentData;
    const url = convenientCheckoutUris.createPayment(rallyId, paymentMode);
    return HttpClient.post<ICreatePayment>(
      url,
      { paymentMethodId, authToken, encryptedProfileInfo, claims },
      contentTypeJsonAxiosOptions,
    );
  },
  submitACHPayment: (paymentData: ICreatePaymentData): AxiosPromise<ICreatePayment> => {
    const { rallyId, paymentMode, paymentMethodId, authToken, encryptedProfileInfo, claims, consent } = paymentData;
    const url = convenientCheckoutUris.createPayment(rallyId, paymentMode);
    return HttpClient.post<ICreatePayment>(
      url,
      { paymentMethodId, authToken, encryptedProfileInfo, claims, consent },
      contentTypeJsonAxiosOptions,
    );
  },
};
