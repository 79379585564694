import { IFeatureFlags } from './feature-flags.interface';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    FEATURE_FLAGS: IFeatureFlags;
  }
}

export const DEFAULT_FEATURE_FLAGS: IFeatureFlags = {
  RALLYPAY_FEATURES_CARD_PAYMENTS: true,
  RALLYPAY_FEATURES_OPTUM_BANK_SSO: true,
  RALLYPAY_FEATURES_WITH_ACH: true,
  RALLYPAY_FEATURES_SPLIT_PAYMENT: true,
  RALLYPAY_FEATURES_VALIDATE_SESSION: true,
  RALLYPAY_FEATURES_USE_RALLYPAY_WIDGET: true,
};

export function getFeatureFlags(): IFeatureFlags {
  const envFeatureFlags = window.FEATURE_FLAGS;
  const useDefaultFeatureFlags =
    !envFeatureFlags || (envFeatureFlags.constructor === Object && Object.keys(envFeatureFlags).length === 0);
  return useDefaultFeatureFlags ? DEFAULT_FEATURE_FLAGS : envFeatureFlags;
}
