import { css, Theme } from '@emotion/react';
import { Style } from 'scripts/styles/themes/themes.interfaces';

const fullFormStyles: Style = (theme: Theme) => css`
  .row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    .column {
      display: ${theme.isMobile ? 'block' : 'flex'};
      flex-basis: 100%;
      flex-direction: column;
      flex: ${theme.isMobile ? '100%' : 3};
      width: ${theme.isMobile ? '100%' : 'auto'};
      &.right {
        flex: ${theme.isMobile ? 'none' : 5};
      }
      &.pay-with {
        label {
          /* not technically on the grid but appears centered. */
          padding-top: 10px;
        }
      }
      &:not(.pay-with) {
        justify-content: center;
      }
      > label {
        color: ${theme.label.color};
        font-size: ${theme.label.fontSize};
        font-weight: ${theme.label.fontWeight};
        line-height: ${theme.label.lineHeight};
        margin-right: ${theme.isMobile ? '0' : '16px'};
        text-align: ${theme.isMobile ? 'left' : 'right'};
      }
      > p {
        font-size: ${theme.p.fontSize};
        font-weight: ${theme.p.fontWeight};
        margin: 0;
      }
    }
    .pay-amount {
      border: 1px solid ${theme.colors.grayDark};
      border-radius: 4px;
      font-size: ${theme.p.fontSize};
      line-height: normal;
      max-width: ${theme.isMobile ? 0 : '320px'};
      min-width: ${theme.isMobile ? '100%' : 0};
      padding: 8px 16px;
      &.error {
        border-color: ${theme.colors.red};
      }
    }
  }
`;
export default fullFormStyles;
