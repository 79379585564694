import { useEffect } from 'react';
import { MessageType, PageTrackingEventType } from '@rally/rallypay-commons';

export const usePageTracking = (pageName: string): void => {
  useEffect(() => {
    if (!window.parent) return;

    const event: PageTrackingEventType = {
      type: MessageType.PageTracking,
      payload: { pageName },
    };

    window.parent.postMessage(event, '*');
  }, [pageName]);
};
