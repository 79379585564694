/** @jsx jsx */
import { jsx } from '@emotion/react';
import { useEffect, useRef, FunctionComponent, useContext } from 'react';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { css } from '@emotion/react';
// eslint-disable-next-line max-len
import { OptumCCGWidgetInitializer as UntypedOptumCCGWidgetInitializer } from '@optum-ccg/convenient-checkout-ui/dist/widget/ccg-widget.min';

import type cjsWidget from '@optum-ccg/convenient-checkout-ui/dist/cjs/widget-wrapper/OptumCCGWidgetInitializer';
import { AppContext } from 'scripts/contexts/app-context/app-context';
import {
  ErrorCallbackArgs,
  MessageCallbackArgs,
  SuccessCallbackArgs,
} from '@optum-ccg/convenient-checkout-ui/dist/cjs/capabilities/checkout-v2/types';
import { getConfig } from 'scripts/util/constants/config';
import { useCheckoutSession } from './add-card.hooks';
import { resetCheckoutSession } from 'scripts/state/checkoutSession';
import { useDispatch } from 'react-redux';
import { PayAnywayDispatch } from 'scripts/state';

const OptumCCGWidgetInitializer = UntypedOptumCCGWidgetInitializer as typeof cjsWidget;

const AddCardStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .ccg-container {
    padding-bottom: 20px;
  }
`;

const AddCardWidget: FunctionComponent = () => {
  let ccgWidget: ReturnType<typeof cjsWidget>;
  const dispatch = useDispatch<PayAnywayDispatch>();
  const widgetContainer = useRef(null);
  const config = getConfig();

  const { setIsAddCardScreenOpen, setShowAddCardScreenError } = useContext(AppContext);

  const session = useCheckoutSession();

  const env = config.RALLYPAY_WEB_ENVIRONMENT_NAME.toLowerCase();
  const ccgEnv = env === 'production' ? 'prod' : 'stage';

  const handleSuccess = (event: SuccessCallbackArgs): void => {
    if (event.code == 'PAYMENT_METHOD_CREATED') {
      setIsAddCardScreenOpen(false);
      dispatch(resetCheckoutSession());
    }
  };

  const handleEvent = (result: MessageCallbackArgs): void => {
    if (result.code == 'WIDGET_CLOSED') {
      setIsAddCardScreenOpen(false);
      dispatch(resetCheckoutSession());
    }
  };

  const handleError = (event: ErrorCallbackArgs): void => {
    setIsAddCardScreenOpen(false);
    // SESSION_CANCELED is returned when the user closes the CCG widget without entering a new
    // payment method, or if the CCG widget is opened using the sessionId of a cancelled session
    if (event.code != 'SESSION_CANCELED') setShowAddCardScreenError(true);
    dispatch(resetCheckoutSession());
  };

  useEffect(() => {
    if (!session.sessionId || !widgetContainer.current || session.isLoading) {
      if (session.error) setShowAddCardScreenError(true);
      return;
    }

    ccgWidget = OptumCCGWidgetInitializer({
      rootElement: widgetContainer.current,
      checkoutSessionId: session.sessionId,
      appEnv: ccgEnv,
      containerConfig: {
        type: 'inline',
      },
      onSuccess: handleSuccess,
      onEvent: handleEvent,
      onError: handleError,
    });

    ccgWidget.render();
  }, [session]);

  return (
    <div css={AddCardStyles}>
      <LoadingSpinner ariaLoadingLabel="Downloading" isLoading={session.isLoading} />
      <div className="ccg-container" ref={widgetContainer} />
    </div>
  );
};

export default AddCardWidget;
