import React from 'react';
import MultipayForm from './multipay-form';

import { Global, ThemeProvider } from '@emotion/react';
import '../../requireTheme';
import { ErrorBoundary } from '../../components/error-boundary/error-boundary';
import globalStyles from '../../styles/global';
import { getTheme } from '../../util/theme/theme';
import { DefaultErrorScreen } from '../default-error-screen/default-error-screen';
import { IMultipayDefaultProps } from 'scripts/interface/multipay.interfaces';
import { I18nextProvider } from 'react-i18next';
import { AbyssThemed } from 'scripts/ui/abyss-themed/abyss-themed';

export interface IMultipay {
  multipay: boolean;
}
const Multipay: React.FunctionComponent<IMultipayDefaultProps> = props => {
  const theme = getTheme();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const i18nInstance = (props as any).i18n;

  return (
    <I18nextProvider i18n={i18nInstance} defaultNS={'pay-claims'}>
      <ThemeProvider theme={theme}>
        <Global styles={globalStyles} />
        <ErrorBoundary fallback={<DefaultErrorScreen onClick={location.reload} />}>
          <AbyssThemed>
            <MultipayForm {...props} />
          </AbyssThemed>
        </ErrorBoundary>
      </ThemeProvider>
    </I18nextProvider>
  );
};

export default Multipay;
