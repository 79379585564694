import { css, Theme } from '@emotion/react';
import { Style } from 'scripts/styles/themes/themes.interfaces';

const paymentFormStyles: Style = (theme: Theme) => css`
  .input-group {
    align-items: center;
    display: flex;
    margin: ${theme.row.margin};
    input[type='radio'] {
      align-self: center;
    }
    label {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      .account-logo-container {
        align-self: center;
        height: ${theme.logo.height};
        width: ${theme.logo.width};
        min-width: ${theme.logo.width};
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .type-and-balance {
        align-self: center;
        font-size: ${theme.label.fontSize};
        line-height: ${theme.typeAndBalance.lineHeight};
        .account-balance,
        .account-type {
          margin-left: 16px;
          font-weight: ${theme.p.fontWeight};
        }
        .account-balance {
          .account-balance-amount {
            font-weight: ${theme.p.fontWeight};
          }
        }
      }
    }
    input[type='radio'] {
      appearance: none;
      display: inline-block;
      height: 24px;
      overflow: hidden;
      vertical-align: middle;
      visibility: hidden;
      width: 24px;
    }
    input[type='radio'] + label:before {
      background: ${theme.colors.white};
      border: 3px solid ${theme.colors.primary};
      border-radius: 12.5px;
      content: '';
      display: inline-block;
      height: 24px;
      left: -24px;
      margin-right: -10px;
      position: relative;
      vertical-align: middle;
      width: 24px;
      align-self: center;
    }
    input[type='radio']:checked,
    input[type='radio']:checked + label:before {
      background: ${theme.colors.primary};
    }
  }
  .account-button-container {
    margin-top: 32px;
    button {
      min-width: 320px;
      width: ${theme.button.width};
    }
  }
  .message {
    padding: ${theme.message.padding};
    &.error {
      color: ${theme.colors.red};
    }
  }
  .link {
    & > .rds-icon {
      display: none;
    }
    & > .link-arrow {
      margin-left: 5px;
    }
  }
  .add-payment-method {
    border: dashed ${theme.colors.primary} 1px;
    border-radius: 4px;
    color: ${theme.colors.primary};
    text-align: center;
    width: 320px;
    padding: 8px 16px;
  }
`;

export default paymentFormStyles;
