import 'scripts/polyfills';
import 'normalize.css';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './scripts/components/app/app';
import './scripts/i18n';
import Multipay from './index';

const div = document.createElement('div');
if (!document.body) {
  throw new Error('Document has no body');
}

const container = document.getElementById('root') || null;

document.body.appendChild(div);
const root = createRoot(div);
root.render(
  <Suspense fallback={<div />}>
    { container?.getAttribute('multipayFeatureFlag') ? <Multipay props  /> : <App /> }
  </Suspense>,
);
