import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@abyss/web/tools/styled';
import { Card } from '@abyss/web/ui/Card';
import { TermsAndConditions } from './terms-and-conditions/terms-and-conditions';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import { Flex } from '@abyss/web/ui/Flex';
import { Modal } from '@abyss/web/ui/Modal';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { ACHAgreement } from './ach-agreement';
import { IClaimsInfoPacket, IPaymentMethodsItem, PaymentMethodType } from '@rally/rallypay-commons';
import { AbyssButtonWithClickTracking } from 'scripts/components/shared/abyss-button/abyss-button';
import { Logo } from '../../ccg-logo/ccg-logo';
import { IClaimPaymentDetails } from 'scripts/api/convenient-checkout/convenient-checkout.interfaces';
import { HSA_METHOD, ILedgerAccount } from 'scripts/interface/multipay.interfaces';

export interface IReviewAndSubmitProps {
  isHsaDefaultPayment: boolean;
  isCardDefaultPayment: boolean;
  handleSubmitPayment: () => void;
  selectedClaims?: IClaimPaymentDetails[];
  totalAmountToPay: number;
  confirmSelectionFlag?: boolean;
  selectedPaymentMethod?: IPaymentMethodsItem | string;
  hsaAccount: ILedgerAccount | undefined;
  defaultPayment?: IPaymentMethodsItem;
  paymentMethodList: IPaymentMethodsItem[];
  claimInfoPackets: (IClaimsInfoPacket | undefined)[];
  paymentSubmissionLoader: boolean;
  insufficientHsaBalance: boolean;
  isPaymentButtonDisabled: boolean;
  claimInfoPacketsError: string | undefined;
  setIsPaymentButtonDisabled: (isPaymentButtonDisabled: boolean) => void;
}

export const ReviewAndSubmit: FunctionComponent<IReviewAndSubmitProps> = props => {
  const { t } = useTranslation();
  const {
    handleSubmitPayment,
    selectedClaims,
    totalAmountToPay,
    confirmSelectionFlag = false,
    selectedPaymentMethod,
    hsaAccount,
    paymentMethodList,
    claimInfoPackets,
    paymentSubmissionLoader = false,
    insufficientHsaBalance,
    isPaymentButtonDisabled = true,
    setIsPaymentButtonDisabled,
    claimInfoPacketsError,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showACHAgreement, setShowACHAgreement] = useState<boolean>(false);
  const [isACHAgreementAccepted, setIsACHAgreementAccepted] = useState<boolean>(false);
  const [showACHAgreementError, setShowACHAgreementError] = useState<boolean>(false);

  const handleCancelPayment = (): void => {
    window.location.href = '/claims-and-accounts/claims';
    setIsOpen(false);
  };
  useEffect(() => {
    if (
      (selectedPaymentMethod &&
        selectedPaymentMethod !== '' &&
        selectedPaymentMethod !== HSA_METHOD &&
        !claimInfoPacketsError) ||
      (hsaAccount &&
        selectedPaymentMethod &&
        selectedPaymentMethod === HSA_METHOD &&
        !insufficientHsaBalance &&
        !claimInfoPacketsError)
    ) {
      setIsCollapsed(confirmSelectionFlag);
    } else {
      setIsCollapsed(false);
    }
    if (!confirmSelectionFlag) {
      setShowACHAgreementError(false);
      setIsACHAgreementAccepted(false);
    }
  }, [confirmSelectionFlag, insufficientHsaBalance, selectedPaymentMethod, hsaAccount, claimInfoPacketsError]);

  useEffect(() => {
    setShowACHAgreementError(false);
    setIsACHAgreementAccepted(false);
    setShowACHAgreement(false);
    selectedPaymentMethod &&
      typeof selectedPaymentMethod === 'object' &&
      setShowACHAgreement(selectedPaymentMethod?.paymentMethodType === PaymentMethodType.BANK_ACCOUNT);
  }, [selectedPaymentMethod]);

  useEffect(() => {
    if (!claimInfoPackets || claimInfoPackets.length === 0) {
      setIsPaymentButtonDisabled(true);
    } else {
      setIsPaymentButtonDisabled(false);
    }
  }, [claimInfoPackets]);

  const paymentMethodName = (): string => {
    if (typeof selectedPaymentMethod === 'object') {
      if (selectedPaymentMethod?.bankAccount?.bankName) {
        return `${selectedPaymentMethod?.bankAccount?.bankName?.toLocaleLowerCase()} *${
          selectedPaymentMethod?.bankAccount?.last4
        }`;
      }
      if (selectedPaymentMethod?.card?.cardBrand) {
        return `${selectedPaymentMethod?.card?.cardBrand?.toLocaleLowerCase()} *${selectedPaymentMethod?.card?.last4}`;
      }
      if (selectedPaymentMethod?.nickname) {
        return `${selectedPaymentMethod?.nickname?.toLocaleLowerCase()} *${
          selectedPaymentMethod?.bankAccount?.last4 || selectedPaymentMethod?.card?.last4
        }`;
      }
    }
    return '';
  };

  const onToggleCheckbox = (): void => {
    setIsACHAgreementAccepted(!isACHAgreementAccepted);
    setShowACHAgreementError(isACHAgreementAccepted);
  };

  const ValidationCheck = (): void => {
    if (showACHAgreement) {
      isACHAgreementAccepted ? handleSubmitPayment() : setShowACHAgreementError(true);
    } else {
      handleSubmitPayment();
    }
  };

  return (
    <>
      <StyledCardReviewSubmit header={`3. ${t('REVIEW_AND_SUBMIT')}`}>
        <div
          style={{
            display: isCollapsed ? 'block' : 'none',
          }}
        >
          <Card.Section>
            {selectedPaymentMethod === HSA_METHOD && hsaAccount && (
              <StyledPaymentCard>
                <CcgLogoWrapper>
                  <Logo accountSystemCode={hsaAccount.accountSystemCode} />
                </CcgLogoWrapper>
                <Text size="$sm" transform="capitalize" data-testid="bank-label" fontWeight="bold">
                  {t('SAVINGS_ACCOUNT')}
                </Text>
              </StyledPaymentCard>
            )}
            {selectedPaymentMethod && typeof selectedPaymentMethod === 'object' && (
              <StyledPaymentCard>
                {selectedPaymentMethod?.bankAccount?.last4 ? (
                  <StyledIconBox>
                    <IconMaterial icon="account_balance" size="$md" color="$black" />
                  </StyledIconBox>
                ) : (
                  <CcgLogoWrapper>
                    <Logo accountSystemCode={selectedPaymentMethod.card.cardBrand} />
                  </CcgLogoWrapper>
                )}
                <StyledText size="$md" transform="capitalize" data-testid="bank-label" fontWeight="bold">
                  {paymentMethodName()}
                </StyledText>
              </StyledPaymentCard>
            )}
            {paymentMethodList && paymentMethodList.length === 0 && !hsaAccount && (
              <StyledAlertWrapper>
                <div>{t('NO_PAYMENT_METHOD_SELECTED')} </div>
              </StyledAlertWrapper>
            )}
            <SubTotalDiv>
              <>
                <SubTotalSpan>
                  {selectedClaims?.length === 1
                    ? t('TOTAL_SELECTED_CLAIM', { totalSelected: selectedClaims?.length })
                    : t('TOTAL_SELECTED_CLAIMS', { totalSelected: selectedClaims?.length })}
                </SubTotalSpan>
                <SubTotalSpan>${parseFloat(String(totalAmountToPay)).toFixed(2)}</SubTotalSpan>
              </>
            </SubTotalDiv>
            {showACHAgreement && (
              <ACHAgreement
                isACHAgreementAccepted={isACHAgreementAccepted}
                onToggleCheckbox={onToggleCheckbox}
                showACHAgreementError={showACHAgreementError}
              />
            )}
            <TermsAndConditions></TermsAndConditions>
            <StyledButtons>
              {isPaymentButtonDisabled}
              <SubmitPayment
                data-ui-element-name="submit-payment"
                onClick={() => ValidationCheck()}
                isDisabled={isPaymentButtonDisabled}
                trackingName={t('SUBMIT_PAYMENT')}
                trackingSection={'pay claims'}
                isLoading={paymentSubmissionLoader}
                ariaLoadingLabel="Submitting Payment"
              >
                {t('SUBMIT_PAYMENT')}
              </SubmitPayment>
              <AbyssButtonWithClickTracking
                data-testid="cancel-payment"
                trackingName={t('CANCEL_BUTTON')}
                trackingSection={'pay claims'}
                variant="outline"
                data-ui-element-name="cancel-payment"
                onClick={() => setIsOpen(true)}
                isDisabled={paymentSubmissionLoader || isPaymentButtonDisabled}
              >
                {t('CANCEL_BUTTON')}
              </AbyssButtonWithClickTracking>
            </StyledButtons>
          </Card.Section>
        </div>
      </StyledCardReviewSubmit>
      <Modal title={t('CANCEL_TITLE')} size={'full'} isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <Modal.Section>{t('CANCEL_CONTENT')}</Modal.Section>
        <Modal.Section>
          <StyledPopUpButtons>
            <CancelButton variant="outline" data-ui-element-name="submit-payment" onClick={handleCancelPayment}>
              {t('YES_CANCEL')}
            </CancelButton>
            <AbyssButtonWithClickTracking
              data-testid="continue-payment"
              onClick={() => setIsOpen(false)}
              trackingName={t('CONTINUE_PAYMENT')}
              trackingSection={'pay claims'}
            >
              {t('CONTINUE_PAYMENT')}
            </AbyssButtonWithClickTracking>
          </StyledPopUpButtons>
        </Modal.Section>
      </Modal>
    </>
  );
};

const StyledAlertWrapper = styled('div', {
  margin: '0 $xs $lg $xs',
  border: '1px solid #DADBDC',
  padding: '$md',
  fontSize: '$sm',
  fontWeight: '$bold',
});

const StyledText = styled(Text, {
  verticalAlign: 'middle',
});

const CcgLogoWrapper = styled('div', {
  padding: '$xs',
  margin: '0px $xs',
});

const StyledPaymentCard = styled(Card, {
  display: 'flex',
  padding: '$md',
  '&.abyss-card-root': {
    marginBottom: '$md',
    marginTop: '0',
    backgroundColor: '$primary2',
    boxShadow: 'none',
    alignItems: 'center',
  },
});

const StyledCardReviewSubmit = styled(Card, {
  '&.abyss-card-title': {
    backgroundColor: '$white',
    fontSize: '22px',
  },
  '&.abyss-button-root': {
    border: 'none',
  },
});

const StyledButtons = styled(Flex, {
  '&.abyss-flex-root': {
    marginTop: '$md',
  },
  '@screen < $md': {
    justifyContent: 'unset',
    flexDirection: 'column',
    width: '100%',
  },
});

const SubmitPayment = styled(AbyssButtonWithClickTracking, {
  '&.abyss-button-root': {
    marginRight: '$md',
    '@screen < $md': {
      marginRight: 'unset',
      marginBottom: '$md',
    },
  },
});

const StyledPopUpButtons = styled(Flex, {
  justifyContent: 'flex-end',
  '@screen < $md': {
    justifyContent: 'unset',
    flexDirection: 'column',
  },
});

const CancelButton = styled(Button, {
  '&.abyss-button-root': {
    marginRight: '$sm',
    '@screen < $md': {
      marginRight: 'unset',
      marginBottom: '$md',
    },
  },
});

const SubTotalDiv = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: '$sm 0 $md 0',
  backgroundColor: '#EDF3FB',
  height: '64px',
});

const SubTotalSpan = styled('span', {
  fontSize: '$xl',
  fontWeight: '$bold',
  color: '$gray8',
  padding: '$sm $md $sm $md',
});

export const StyledIconBox = styled(Box, {
  '&.abyss-box-root': {
    height: 'auto',
    width: 'auto',
    backgroundColor: 'white',
    padding: '0px $sm',
    margin: '0px $xs',
  },
});
