import { ICurrencyAmount } from '@rally/rallypay-commons';
import { IPaymentMethods, IPaymentMethodsItem } from '@rally/rallypay-commons';
import { IAnyClaim, IClaim, IFinancialClaim } from 'scripts/interface/multipay.interfaces';
import { AccountType, ClaimType } from 'scripts/interface/multipay.interfaces';

export function isHealthcareClaim(claim: IAnyClaim): claim is IClaim {
  return getByClaim(claim, true, false);
}

export function isFinancialClaim(claim: IAnyClaim): claim is IFinancialClaim {
  return getByClaim(claim, false, true);
}

export function getYouMayOweAmount(claim: IAnyClaim): number {
  if (isHealthcareClaim(claim)) {
    return getAmount(claim.balance.youMayOweAmount);
  } else if (isFinancialClaim(claim)) {
    return getAmount(claim.balance.amountPending);
  }
  return 0;
}

export function geClaimId(claim: IAnyClaim): string {
  if (isHealthcareClaim(claim)) {
    return claim.claimId;
  }
  return '';
}

export const getDefaultCard = (paymentMethodsData: IPaymentMethods): IPaymentMethodsItem | null => {
  const defaultCard =
    paymentMethodsData && paymentMethodsData?.paymentMethods && paymentMethodsData?.paymentMethods.length > 0
      ? paymentMethodsData.paymentMethods?.find(paymentInfo => paymentInfo.default)
      : null;
  return defaultCard ? defaultCard : null;
};

export const formatCurrency = (
  currency?: ICurrencyAmount,
  options: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
): string => {
  return currency ? `$${Number(currency.value).toLocaleString('en', options)}` : '$0.00';
};

export const getAmount = (amount?: ICurrencyAmount): number => {
  return amount && amount.value ? parseFloat(amount.value) : 0;
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const currencyFormatterWithoutCents = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export function getMoneyValue(amount?: number | string, decimal: boolean = true, nastring: string = 'N/A'): string {
  if (typeof amount === 'string') {
    amount = parseFloat(amount);
  }
  if (amount === undefined || isNaN(amount) || typeof amount !== 'number') {
    return nastring;
  }
  return decimal ? currencyFormatter.format(amount) : currencyFormatterWithoutCents.format(amount);
}

function isFunction<T>(t: T | (() => T)): t is () => T {
  return typeof t === 'function';
}

export type AnyClaimType = ClaimType | AccountType;

export function getByType<T, U>(
  type: AnyClaimType,
  healthRelated: (() => T) | T,
  financialRelated: (() => U) | U,
): T | U {
  const healthRelatedCb = isFunction(healthRelated) ? healthRelated : () => healthRelated;
  const financialRelatedCb = isFunction(financialRelated) ? financialRelated : () => financialRelated;
  switch (type) {
    case ClaimType.Medical:
    case ClaimType.Dental:
    case ClaimType.Rx:
    case ClaimType.Vision:
    case ClaimType.All:
      return healthRelatedCb();
    case AccountType.DCSA:
    case AccountType.FSADC:
    case AccountType.FSAHC:
    case AccountType.FSALP:
    case AccountType.HCSA:
    case AccountType.HRA:
    case AccountType.HRAAP:
    case AccountType.HRAPD:
    case AccountType.HRASD:
    case AccountType.HSA:
    case AccountType.MRA:
      return financialRelatedCb();
    default:
      console.error('an unsupported type of claim was provided:', type);
      return healthRelatedCb();
  }
}

export function getByClaim<T, U>(
  claim: IAnyClaim,
  healthRelated: (() => T) | T,
  financialRelated: (() => U) | U,
): T | U {
  const type = (claim as IFinancialClaim).accountType || (claim as IClaim).claimType;
  return getByType(type, healthRelated, financialRelated);
}
