import React, { FunctionComponent } from 'react';
import { RdsLink } from '@rally/energon-react';
import { LinkProps } from '@rally/energon-react/dist/esm/Link/Link.interface';
import withClickTracking from 'scripts/util/analytics/withClickTracking';

export enum LinkTarget {
  Blank = '_blank',
  Self = '_self',
}

export interface IAnchorProps extends LinkProps {
  newWindowAccessibilityLabel?: string;
  noRel?: boolean;
}

const Anchor: FunctionComponent<IAnchorProps> = props => {
  const { children, newWindowAccessibilityLabel, noRel, target, variant, ...rest } = props;
  const isBlank = target === LinkTarget.Blank;
  return (
    <RdsLink rel={!noRel && isBlank ? 'noopener noreferrer' : undefined} target={target} variant={variant} {...rest}>
      {children}
      {newWindowAccessibilityLabel && (
        <span className="sr-only" data-testid="external-link-accessibility">
          {
            // ARC-8887 in Google Chrome, adjacent text fields are combined with no spacing when read by screen readers
            // Since this warning always follows link text, manually add separation
          }
          {`. ${newWindowAccessibilityLabel}`}
        </span>
      )}
    </RdsLink>
  );
};

export default Anchor;

export const AnchorWithClickTracking = withClickTracking(Anchor);
