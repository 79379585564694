import { ClaimType, IClaimsInfoPacket } from '@rally/rallypay-commons';

export const getClaimNumberTranslationKey = (claimType: ClaimType | string): string => {
  switch (claimType) {
    case ClaimType.MEDICAL:
      return 'MEDICAL_CLAIM_NUMBER';
    case ClaimType.DENTAL:
      return 'DENTAL_CLAIM_NUMBER';
    case ClaimType.RX:
      return 'RX_CLAIM_NUMBER';
    default:
      return 'GENERIC_CLAIM_NUMBER';
  }
};

export const getClaimKey = (claimsInfoPacket?: IClaimsInfoPacket): string | undefined => {
  const claimKey = claimsInfoPacket?.displayInfo?.claimInfo?.claimKey;
  return claimKey || undefined;
};
