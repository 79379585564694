import React from 'react';
import { AppContext } from 'scripts/contexts/app-context/app-context';
import { IAppContext } from 'scripts/contexts/app-context/app-context.interfaces';

export function useAppState(): IAppContext {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within an AppProvider');
  }
  return context;
}
