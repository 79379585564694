/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import loadingDotsStyles from 'scripts/styles/loading-dots';

interface ILoadingDotsProps {
  accessibilityLabelKey?: string;
  size?: 'small' | 'medium';
}

const loadingDotStyleMap = {
  small: { height: '8px', width: '8px' },
  medium: { height: '16px', width: '16px' },
};

const LoadingDots: FunctionComponent<ILoadingDotsProps> = ({ accessibilityLabelKey = 'LOADING', size = 'medium' }) => {
  const { t } = useTranslation();
  const loadingDotStyle = loadingDotStyleMap[size];
  return (
    <div css={loadingDotsStyles} data-testid="loading-dots">
      <span style={loadingDotStyle} className="loading-dot" />
      <span style={loadingDotStyle} className="loading-dot" />
      <span style={loadingDotStyle} className="loading-dot" />
      <span className="sr-only">{t(accessibilityLabelKey)}</span>
    </div>
  );
};

export default LoadingDots;
