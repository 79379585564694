/** @jsx jsx */
import { jsx } from '@emotion/react';
import { FunctionComponent, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHasReachedBottom } from 'scripts/hooks/use-has-reached-bottom/use-has-reached-bottom';
import agreementBoxStyles from 'scripts/styles/agreement-box';
import { AnchorWithClickTracking, LinkTarget } from '../shared/anchor/anchor';

const RALLYPAY_REVOKE_CONSENT_FAQ_URL = 'https://member.uhc.com/claims-and-accounts/claims/modal/claims-faq';
const MYUHC_PORTAL_URL = 'http://myuhc.com/';
const ADOBE_DOWNLOAD_URL = 'https://get.adobe.com/reader';

//eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AgreementBox: FunctionComponent<{ setHasReachedBottom: (x: boolean) => void }> = ({
  setHasReachedBottom,
}) => {
  const { t } = useTranslation();
  const [componentRef, hasReachedBottom] = useHasReachedBottom();

  useEffect(() => {
    if (hasReachedBottom) {
      setHasReachedBottom(true);
    }
  }, [hasReachedBottom]);

  const agreements: {
    title?: string | null;
    body?: string | null;
    element?: JSX.Element;
    points?: (string | JSX.Element | null)[];
  }[] = [
    { body: t('LEGAL_AGREEMENT.PLEASE_READ.BODY') },
    { title: t('LEGAL_AGREEMENT.AGREEMENT_SET_ONE.TITLE') },
    {
      element: (
        <Trans key={'LEGAL_AGREEMENT.I_AM_ENROLLED.BODY'} i18nKey={'LEGAL_AGREEMENT.I_AM_ENROLLED.BODY'}>
          <AnchorWithClickTracking
            href={MYUHC_PORTAL_URL}
            trackingName="myuhc-portal"
            trackingSection="payment-authorization"
            variant="inline"
            target={LinkTarget.Blank}
          />
        </Trans>
      ),
    },
    {
      title: t('LEGAL_AGREEMENT.AGREEMENT_SET_ONE.TITLE'),
      points: [
        t('LEGAL_AGREEMENT.AGREEMENT_SET_ONE.POINTS.ONE'),
        <Trans
          key={'LEGAL_AGREEMENT.AGREEMENT_SET_ONE.POINTS.TWO'}
          i18nKey={'LEGAL_AGREEMENT.AGREEMENT_SET_ONE.POINTS.TWO'}
        >
          <AnchorWithClickTracking
            href={MYUHC_PORTAL_URL}
            trackingName="myuhc-portal"
            trackingSection="payment-authorization"
            variant="inline"
            target={LinkTarget.Blank}
          />
        </Trans>,
      ],
    },
    {
      title: t('LEGAL_AGREEMENT.AGREEMENT_SET_TWO.TITLE'),
      points: [
        t('LEGAL_AGREEMENT.AGREEMENT_SET_TWO.POINTS.ONE'),
        t('LEGAL_AGREEMENT.AGREEMENT_SET_TWO.POINTS.TWO'),
        t('LEGAL_AGREEMENT.AGREEMENT_SET_TWO.POINTS.THREE'),
        t('LEGAL_AGREEMENT.AGREEMENT_SET_TWO.POINTS.FOUR'),
        t('LEGAL_AGREEMENT.AGREEMENT_SET_TWO.POINTS.FIVE'),
        <Trans
          key={'LEGAL_AGREEMENT.AGREEMENT_SET_TWO.POINTS.SIX'}
          i18nKey={'LEGAL_AGREEMENT.AGREEMENT_SET_TWO.POINTS.SIX'}
        >
          <AnchorWithClickTracking
            href={RALLYPAY_REVOKE_CONSENT_FAQ_URL}
            trackingName="consent-revoke-link"
            trackingSection="payment-authorization"
            variant="inline"
            target={LinkTarget.Blank}
          />
        </Trans>,
      ],
    },
    { title: t('LEGAL_AGREEMENT.WHO_MAY_DISCLOSE.TITLE'), body: t('LEGAL_AGREEMENT.WHO_MAY_DISCLOSE.BODY') },
    { title: t('LEGAL_AGREEMENT.WHO_MAY_RECEIVE.TITLE'), body: t('LEGAL_AGREEMENT.WHO_MAY_RECEIVE.BODY') },
    { title: t('LEGAL_AGREEMENT.TYPE_OF_INFORMATION.TITLE'), body: t('LEGAL_AGREEMENT.TYPE_OF_INFORMATION.BODY') },
    { title: t('LEGAL_AGREEMENT.PURPOSE_OF_DISCLOSURE.TITLE'), body: t('LEGAL_AGREEMENT.PURPOSE_OF_DISCLOSURE.BODY') },
    { body: t('LEGAL_AGREEMENT.ACKNOWLEDGE.BODY') },
    { body: t('LEGAL_AGREEMENT.I_UNDERSTAND.BODY') },
    { title: t('LEGAL_AGREEMENT.PLEASE_MAINTAIN.TITLE') },
    { title: t('LEGAL_AGREEMENT.SOFTWARE_HARDWARE_REQUIRMENTS.TITLE') },
    {
      element: (
        <Trans
          key={'LEGAL_AGREEMENT.SOFTWARE_HARDWARE_REQUIRMENTS.BODY'}
          i18nKey={'LEGAL_AGREEMENT.SOFTWARE_HARDWARE_REQUIRMENTS.BODY'}
        >
          <AnchorWithClickTracking
            href={ADOBE_DOWNLOAD_URL}
            trackingName="download-adobe"
            trackingSection="payment-authorization"
            variant="inline"
            target={LinkTarget.Blank}
          />
        </Trans>
      ),
    },
  ];

  return (
    <div css={agreementBoxStyles}>
      <div className="agreement-box-container" ref={componentRef}>
        {agreements.map((a, i) => {
          return (
            <div key={i}>
              {a.title && <div className="bold-segment">{a.title}</div>}
              {a.points?.length && (
                <ul className="segment">
                  {a.points.map((point, i) => (
                    <li key={i} className="point-text">
                      {point}
                    </li>
                  ))}
                </ul>
              )}
              {a.body && <div className="segment">{a.body}</div>}
              {a.element && <div className="segment">{a.element}</div>}
            </div>
          );
        })}
      </div>
    </div>
  );
};
