import { ICreatePayment } from '@rally/rallypay-commons';

export enum ACHPaymentActionTypes {
  SUBMIT_ACH_PAYMENT = 'SUBMIT_ACH_PAYMENT',
  SUBMIT_ACH_PAYMENT_SUCCESS = 'SUBMIT_ACH_PAYMENT_SUCCESS',
  SUBMIT_ACH_PAYMENT_FAILURE = 'SUBMIT_ACH_PAYMENT_FAILURE',
}

export interface ISubmitACHPayment {
  type: ACHPaymentActionTypes.SUBMIT_ACH_PAYMENT;
}

export interface ISubmitACHPaymentSuccess {
  payload: ICreatePayment;
  type: ACHPaymentActionTypes.SUBMIT_ACH_PAYMENT_SUCCESS;
}

export interface ISubmitACHPaymentFailure {
  payload: string | { statusCode: number };
  type: ACHPaymentActionTypes.SUBMIT_ACH_PAYMENT_FAILURE;
}

export function submitACHPayment(): ISubmitACHPayment {
  return {
    type: ACHPaymentActionTypes.SUBMIT_ACH_PAYMENT,
  };
}

export function submitACHPaymentSuccess(payload: ICreatePayment): ISubmitACHPaymentSuccess {
  return {
    payload,
    type: ACHPaymentActionTypes.SUBMIT_ACH_PAYMENT_SUCCESS,
  };
}

export function submitACHPaymentFailure(payload: string | { statusCode: number }): ISubmitACHPaymentFailure {
  return {
    payload,
    type: ACHPaymentActionTypes.SUBMIT_ACH_PAYMENT_FAILURE,
  };
}

export type ACHPaymentAction = ISubmitACHPayment | ISubmitACHPaymentSuccess | ISubmitACHPaymentFailure;
