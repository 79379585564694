import React, { FunctionComponent } from 'react';
import { Card } from '@abyss/web/ui/Card';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Box } from '@abyss/web/ui/Box';
import { Text } from '@abyss/web/ui/Text';
import { Label } from '@abyss/web/ui/Label';
import { styled } from '@abyss/web/tools/styled';
import { Logo } from '../ccg-logo/ccg-logo';
import { AccountSystemCode, AccountType, IAccountInfoItem, ICurrencyAmount, MoneyUtils } from '@rally/rallypay-commons';
import { useTranslation } from 'react-i18next';
import { HSA_METHOD } from 'scripts/interface/multipay.interfaces';

interface IHsaPaymentMethodCard {
  account: IAccountInfoItem;
  accountSystemCode: AccountSystemCode | undefined;
  accountType: AccountType | undefined;
  availableBalance: ICurrencyAmount | undefined;
  selectedAccount: IAccountInfoItem | null;
}

const { formatCurrency } = MoneyUtils;

export const HsaPaymentMethodCard: FunctionComponent<IHsaPaymentMethodCard> = props => {
  const { accountSystemCode, accountType, availableBalance } = props;
  const { t } = useTranslation();

  return (
    <StyledCard>
      <StyledRadio value={HSA_METHOD} aria-describedby="radio-hsa" />
      <Logo accountSystemCode={accountSystemCode} />
      <Box padding={'$xs'} color="$white">
        <Label>
          {t('FORM_BALANCE_LABEL')} {accountType}
        </Label>
        <Text>{formatCurrency(availableBalance)}</Text>
      </Box>
    </StyledCard>
  );
};

export const StyledCard = styled(Card, {
  display: 'flex',
  padding: '8px 0 0 8px',
  width: '320px',
  height: '60px',
});

export const StyledRadio = styled(RadioGroup.Radio, {
  '&.abyss-radio-root': {
    width: '28px',
  },
});

export const StyledLabel = styled(Label, {
  paddingBottom: '4px',
});
