import React, { FunctionComponent, useState, useEffect } from 'react';
import { Card } from '@abyss/web/ui/Card';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { PaymentMethodCard } from './payment-method-card';
import { HsaPaymentMethodCard } from './hsa-payment-method-card';
import { useTranslation } from 'react-i18next';
import { Text } from '@abyss/web/ui/Text';
import { styled } from '@abyss/web/tools/styled';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Flex } from '@abyss/web/ui/Flex';
import { IPaymentMethodsItem } from '@rally/rallypay-commons';
import { Provider } from 'react-redux';
import { PayAnywayStore } from 'scripts/state';
import { trapFocus } from 'scripts/util/dom/dom';
import AddCardWidgetMultipay from 'scripts/components/multipay/payment-method/add-card/add-card-multipay';
import { ErrorCallbackArgs } from '@optum-ccg/convenient-checkout-ui/dist/cjs/capabilities/checkout-v2/types';
import { IMultipayDefaultProps, ILedgerAccount, HSA_METHOD } from 'scripts/interface/multipay.interfaces';
import { Alert } from '@abyss/web/ui/Alert';

interface ISelectPaymentMethodProps {
  handleCallBack: (paymentMethodId: string) => void;
  onclickEventForHSAPaymentMethod: () => void;
  isHsaDefaultPayment: boolean;
  insufficientHsaBalance: boolean;
  data: IMultipayDefaultProps;
  fetchedPaymentMethodsData: IPaymentMethodsItem[];
  hsaAccount?: ILedgerAccount;
  setPaymentMethodAdditionError(paymentMethodAdditionError: ErrorCallbackArgs): void;
  postPaymentMethodsLoading: boolean;
  defaultPayment: IPaymentMethodsItem | string;
  isDefaultCardPresent: boolean;
}
export const SelectPaymentMethod: FunctionComponent<ISelectPaymentMethodProps> = props => {
  const {
    data,
    isHsaDefaultPayment,
    insufficientHsaBalance,
    fetchedPaymentMethodsData,
    handleCallBack,
    onclickEventForHSAPaymentMethod,
    setPaymentMethodAdditionError,
    postPaymentMethodsLoading,
    defaultPayment,
    isDefaultCardPresent,
  } = props;
  const { t } = useTranslation();
  const [radioValue, setRadioValue] = useState('');
  const [isAddCardWidgetMultipay, setIsAddCardWidgetMultipay] = useState(false);
  const { rallyId, hsaAccount, minimumAmountAllowedForOnlinePayment } = data.initialData;
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const handleExpiredCardIconClick = (): void => {
    setIsAlertVisible(true);
  };

  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setRadioValue(event.target.value);
    handleCallBack(event.target.value);
  };

  useEffect(() => {
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        document.body.classList.remove('hide-outlines');
        trapFocus(event);
      }
    });
  }, []);

  useEffect(() => {
    isDefaultCardPresent && typeof defaultPayment === 'object'
      ? defaultPayment?.paymentMethodId && setRadioValue(defaultPayment.paymentMethodId)
      : defaultPayment === HSA_METHOD
      ? setRadioValue(HSA_METHOD)
      : typeof defaultPayment === 'object' &&
        defaultPayment?.paymentMethodId &&
        setRadioValue(defaultPayment.paymentMethodId);
  }, [isDefaultCardPresent, defaultPayment]);

  return (
    <Provider store={PayAnywayStore}>
      <React.Fragment>
        {isAddCardWidgetMultipay && (
          <AddCardWidgetMultipay
            data={data}
            setIsAddCardWidgetMultipay={setIsAddCardWidgetMultipay}
            isAddCardWidgetMultipay={isAddCardWidgetMultipay}
            setPaymentMethodAdditionError={setPaymentMethodAdditionError}
          />
        )}

        <StyledCardSection>
          {isAlertVisible && (
            <AlertContainer>
              <Alert title="Payment method has expired" isVisible={isAlertVisible} variant="error">
                {t('ERROR_MESSAGE_SELECT_ANOTHER_METHOD')}
              </Alert>
            </AlertContainer>
          )}
          <Text size="$sm" transform="capitalize" data-testid="bank-label" fontWeight="bold">
            {t('SAVED_METHODS')}
          </Text>
          <RadioGroupWrapper></RadioGroupWrapper>
          <RadioGroup onChange={handlePaymentMethodChange} value={radioValue} size="sm" label="">
            {hsaAccount &&
              [hsaAccount].map((account, index) => {
                const { accountType, accountSystemCode, balance } = account;
                {
                  return (
                    <HsaPaymentMethodCard
                      account={account}
                      accountSystemCode={accountSystemCode}
                      accountType={accountType}
                      availableBalance={balance?.availableAmount}
                      onHSACardClick={onclickEventForHSAPaymentMethod}
                      selectedAccount={null}
                      key={index}
                      default={isHsaDefaultPayment}
                      insufficientHsaBalance={insufficientHsaBalance}
                      showRadio={true}
                      minimumAmountAllowedForOnlinePayment={minimumAmountAllowedForOnlinePayment}
                      isDefaultCardPresent={isDefaultCardPresent}
                    />
                  );
                }
              })}
            {fetchedPaymentMethodsData.length > 0
              ? fetchedPaymentMethodsData?.map((paymentMethod: IPaymentMethodsItem, index: number) => (
                  <PaymentMethodCard
                    cardBrand={paymentMethod?.card?.cardBrand}
                    lastFourCard={paymentMethod?.card?.last4}
                    expiryYear={paymentMethod?.card?.expiryYear}
                    paymentMethodId={paymentMethod?.paymentMethodId}
                    expiryMonth={paymentMethod?.card?.expiryMonth}
                    cardStatus={paymentMethod?.card?.status}
                    bankName={paymentMethod?.bankAccount?.bankName}
                    lastFourBank={paymentMethod?.bankAccount?.last4}
                    nickname={paymentMethod?.nickname}
                    key={index}
                    default={paymentMethod?.default}
                    rallyId={rallyId}
                    onIconClick={handleExpiredCardIconClick}
                    showRadiobtn={true}
                  />
                ))
              : postPaymentMethodsLoading && (
                  <StyledLoadingSpinnerDiv>
                    <LoadingSpinner
                      ariaLoadingLabel={t('LOADING_PAYMENT_METHODS')}
                      isLoading={fetchedPaymentMethodsData === undefined || fetchedPaymentMethodsData.length === 0}
                    />
                  </StyledLoadingSpinnerDiv>
                )}
          </RadioGroup>
          <AddPaymentMethod justify="center" alignItems="center" onClick={() => setIsAddCardWidgetMultipay(true)}>
            <IconMaterial size={16} color="$primary1" icon="add" />
            {t('ADD_PAYMENT_METHOD')}
          </AddPaymentMethod>
        </StyledCardSection>
      </React.Fragment>
    </Provider>
  );
};

const StyledCardSection = styled(Card.Section, {
  paddingTop: '0 !important',
});

const AddPaymentMethod = styled(Flex, {
  border: '1px dashed $primary1',
  fontWeight: 'bold',
  borderRadius: '4px',
  padding: '$lg 0',
  marginBottom: '$md',
  color: '$primary1',
  fontSize: '$md',
  cursor: 'pointer',
});

const RadioGroupWrapper = styled('div', {
  paddingBottom: '$sm',
});

const StyledLoadingSpinnerDiv = styled('div', {
  textAlign: 'center',
});

const AlertContainer = styled('div', {
  marginBottom: '$md',
});
