import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { styled } from '@abyss/web/tools/styled';
import { Text } from '@abyss/web/ui/Text';
import { Link } from '@abyss/web/ui/Link';
import { AbyssThemed } from 'scripts/ui/abyss-themed/abyss-themed';

export const TermsAndConditions: FunctionComponent = () => {
  const { t } = useTranslation();
  const PAYMENT_SERVICE_TERMS_AND_CONDITIONS_URL = 'https://wallet.healthsafepay.com/payment-terms-and-conditions';
  const PRIVACY_POLICY_URL = 'https://www.optum.com/privacy-policy.html';

  return (
    <AbyssThemed>
      <Container>
        <StyledP>
          <Trans
            key={'TERMS_AND_CONDITIONS_PAYMENT_METHOD_ACKNOWLEDGE'}
            i18nKey={'TERMS_AND_CONDITIONS_PAYMENT_METHOD_ACKNOWLEDGE'}
          >
            <Link fontWeight="bold" href={PAYMENT_SERVICE_TERMS_AND_CONDITIONS_URL} size="xs" variant="native" />
            <Link fontWeight="bold" href={PRIVACY_POLICY_URL} size="xs" variant="native" />
          </Trans>
        </StyledP>
        <Text size="xs" fontWeight="bold">
          {t('TERMS_AND_CONDITIONS')}
        </Text>
        <p>
          <Text size="xs">{t('TERMS_AND_CONDITIONS_ALLOW_UHC_INSURANCE_COMPANY')}</Text>
        </p>
      </Container>
    </AbyssThemed>
  );
};

const Container = styled('div', {
  width: '320px',
  marginTop: '24px',
});

const StyledP = styled('p', {
  fontSize: '12px',
});
