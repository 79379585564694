import { css, Theme } from '@emotion/react';
import { Style } from 'scripts/styles/themes/themes.interfaces';

const globalStyles: Style = (theme: Theme) => css`
  body {
    color: ${theme.global.color};
    font-family: ${theme.global.fonts.fontFamily};
    font-size: ${theme.global.fonts.fontSize};
    line-height: ${theme.global.lineHeight};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }
  .hide-outlines {
    *,
    *:focus {
      outline: 0 !important;
    }
  }
  *:focus {
    outline: none;
  }
  [class*=' icon-'],
  [class^='icon-'] {
    vertical-align: middle;
  }
  .container {
    display: block;
    float: none;
    margin-left: auto;
    margin-right: auto;
    max-width: 1224px;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    width: auto;
  }
  .sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .global-error-message-container {
    min-height: 53px;
  }
  .global-error-message {
    align-items: center;
    background-color: ${theme.colors.redLight};
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    padding: 4px;
    .icon-alert {
      background-size: 1.5em;
      display: inline-block;
      flex: 0 0 2em;
      height: 32px;
      margin-right: 10px;
      .triangle {
        fill: ${theme.colors.redDark};
      }
    }
    p.optum-sso-link {
      margin: 0;
      .icon-external-link {
        fill: ${theme.colors.primary};
        height: 0.9em;
        margin-left: 0.3em;
        width: 0.9em;
      }
    }
    strong {
      margin-right: 0.5em;
    }
  }
  .global-error-message-warning {
    background-color: ${theme.colors.yellowLighter};
    .icon-alert {
      .triangle {
        fill: ${theme.colors.yellowLight};
      }
    }
  }
`;

export default globalStyles;
