import { configureStore } from '@reduxjs/toolkit';
import { checkoutSessionSlice, ICheckoutSessionState } from './checkoutSession';

export interface IPayAnywayState {
  checkoutSession: ICheckoutSessionState;
}

export const PayAnywayStore = configureStore({
  reducer: {
    checkoutSession: checkoutSessionSlice.reducer,
  },
});

export type PayAnywayDispatch = typeof PayAnywayStore.dispatch;
