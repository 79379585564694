import { IPaymentMethods } from '@rally/rallypay-commons';
import {
  PaymentMethodsAction,
  POST_PAYMENT_METHODS_ERROR,
  POST_PAYMENT_METHODS_LOADING,
  POST_PAYMENT_METHODS_SUCCESS,
  DELETE_PAYMENT_METHODS_ERROR,
  DELETE_PAYMENT_METHODS_LOADING,
  DELETE_PAYMENT_METHODS_SUCCESS,
} from 'scripts/actions/payment-methods-actions';

export interface IPaymentMethodsState {
  paymentMethodsData?: IPaymentMethods;
  postPaymentMethodsLoading: boolean;
  postPaymentMethodsError: boolean;
  deletePaymentMethodsLoading: boolean;
  deletePaymentMethodsError: boolean;
}
export const initialState: IPaymentMethodsState = {
  paymentMethodsData: undefined,
  postPaymentMethodsLoading: false,
  postPaymentMethodsError: false,
  deletePaymentMethodsLoading: false,
  deletePaymentMethodsError: false,
};

const paymentMethodsReducer = (state: IPaymentMethodsState, action: PaymentMethodsAction): IPaymentMethodsState => {
  switch (action.type) {
    case DELETE_PAYMENT_METHODS_LOADING: {
      return {
        ...state,
        deletePaymentMethodsLoading: true,
        deletePaymentMethodsError: false,
      };
    }
    case DELETE_PAYMENT_METHODS_ERROR: {
      return {
        ...state,
        deletePaymentMethodsLoading: false,
        deletePaymentMethodsError: true,
      };
    }
    case DELETE_PAYMENT_METHODS_SUCCESS: {
      const newPaymentMethods = state.paymentMethodsData?.paymentMethods?.filter(
        pm => pm.paymentMethodId != action.payload.paymentMethodId,
      );

      return {
        ...state,
        paymentMethodsData: { paymentMethods: newPaymentMethods },
        deletePaymentMethodsLoading: false,
        deletePaymentMethodsError: false,
      };
    }
    case POST_PAYMENT_METHODS_LOADING:
      return {
        ...state,
        postPaymentMethodsLoading: true,
        postPaymentMethodsError: false,
        paymentMethodsData: undefined,
      };
    case POST_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        postPaymentMethodsLoading: false,
        postPaymentMethodsError: true,
        paymentMethodsData: undefined,
      };
    case POST_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        postPaymentMethodsLoading: false,
        postPaymentMethodsError: false,
        paymentMethodsData: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default paymentMethodsReducer;
