import React from 'react';
import { CustomTrackingEventType, MessageType } from '@rally/rallypay-commons';

export interface IClickTrackingProps<C extends HTMLElement> {
  onClick?: (e: React.MouseEvent<C>) => void;
  trackingName: string;
  trackingSection: string;
  href?: string;
}

export default function logCustomEvent(eventName: string, payload: Record<string, unknown>): void {
  if (!window.parent) return;

  const event: CustomTrackingEventType = {
    type: MessageType.CustomTracking,
    payload: { eventName, payload },
  };

  window.parent.postMessage(event, '*');
}
